<template>
  <div class="membership-content-wrapper">
    <h5 class="hero-head" v-if="details.start_date != null">
      Membership duration
    </h5>
    <div class="__block-card" v-if="details.start_date != null">
      <h3>Member since {{ details.start_date }}</h3>
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-0">Your membership expires on {{ details.end_date }}</p>
        <router-link class="mebership-cta" :to="{ name: 'Membership' }"
          >Renew <img src="../../assets/send-arrow.svg" alt=""
        /></router-link>
      </div>
    </div>
    <div class="__block-card" v-else>
      <h3>You are not a member</h3>
      <div class="d-flex justify-content-between align-items-center">
        <router-link class="mebership-cta" :to="{ name: 'Membership' }">
          Subscribe <img src="../../assets/send-arrow.svg" alt="" />
        </router-link>
      </div>
    </div>
    <h5 class="hero-head">Your time with Ology</h5>
    <div class="micro-block-wrap row">
      <div class="col-md-6">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <div class="label-block">
              <i class="fas fa-lock-open"></i>
            </div>
            <div class="content-block">
              <p>Courses Purchased</p>
              <h3>{{ details.purchased_course }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span v-if="details.purchased_course == 0"
              >You haven't subscribed for any course as yet</span
            >
            <span v-else>Learn more with us</span>
            <a
              class="explore-cta"
              href="#"
              @click.prevent="updateActiveTab('pills-courses-tab')"
            >
              Explore
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6" v-if="isInstructor == 1">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <div class="label-block">
              <i class="fas fa-upload"></i>
            </div>
            <div class="content-block">
              <p>Courses Hosted</p>
              <h3>{{ details.hosted }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span v-if="details.hosted == 0"
              >You haven't hosted any course</span
            >
            <span v-else>Learn more with us</span>
            <a
              class="explore-cta"
              href="#"
              @click.prevent="updateActiveTab('pills-mycourse-tab')"
            >
              Explore
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <div class="label-block">
              <i class="fas fa-file-video"></i>
            </div>
            <div class="content-block">
              <p>Completed Courses</p>
              <h3>{{ details.course_completed }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span v-if="details.course_completed == 0">
              You haven't completed any course yet
            </span>
            <span v-else>
              Learn more with us
            </span>
            <a
              class="explore-cta"
              href="#"
              @click.prevent="updateActiveTab('pills-courses-tab')"
            >
              Explore
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <div class="label-block">
              <i class="fab fa-blogger-b"></i>
            </div>
            <div class="content-block">
              <p>Blog Posts</p>
              <h3>{{ details.blogs }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span>Keep writing</span>
            <router-link class="explore-cta" :to="{ name: 'createBlog' }">
              Compose
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <div class="label-block">
              <i class="fas fa-comment-alt"></i>
            </div>
            <div class="content-block">
              <p>Blog Comments/Interaction</p>
              <h3>{{ details.blog_comments }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span v-if="details.blog_comments == 0">
              You haven't shared your opinions yet
            </span>
            <span v-else>
              Continue to share your opinions
            </span>
            <router-link class="explore-cta" :to="{ name: 'BlogListing' }">
              Share Now
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <div class="label-block">
              <i class="fas fa-comment-alt"></i>
            </div>
            <div class="content-block">
              <p>Reviews</p>
              <h3>{{ details.reviews }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span v-if="details.reviews == 0">
              You haven't reviewed a course yet
            </span>
            <span v-else>
              Continue writing reviews
            </span>
            <a
              class="explore-cta"
              href="#"
              @click.prevent="updateActiveTab('pills-courses-tab')"
            >
              Explore
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <div class="label-block">
              <i class="fas fa-pen-square"></i>
            </div>
            <div class="content-block">
              <p>Discussions</p>
              <h3>{{ details.discussions }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span v-if="details.discussions == 0">
              You are yet to be part of a discussion
            </span>
            <span v-else>
              Continue participating discussions
            </span>
            <a
              class="explore-cta"
              href="#"
              @click.prevent="updateActiveTab('pills-courses-tab')"
            >
              Explore
            </a>
          </div>
        </div>
      </div>
    </div>
    <h5 class="hero-head" v-if="isInstructor != 1">Become an instructor</h5>
    <div class="micro-block-wrap row" v-if="isInstructor != 1">
      <div class="col-md-6">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <!-- <div class="col-md-2 lable-block">
              <img src="../../assets/certificate-label.svg" alt="" />
            </div> -->
            <div class="col-md-12 content-block">
              <p>Points</p>
              <h3>{{ memberShipDetails.point }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span
              v-if="
                details.point_need_to_instructor - memberShipDetails.point > 0
              "
              >Need
              {{ details.point_need_to_instructor - memberShipDetails.point }}
              points to become an Instructor
            </span>
            <router-link class="btn explore-cta" :to="{ name: 'courseList' }">
              Explore
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="micro-block-card">
          <div class="inner-wrap">
            <!-- <div class="col-md-2 lable-block">
              <img src="../../assets/certificate-label.svg" alt="" />
            </div> -->
            <div class="col-md-12 content-block">
              <p>Days</p>
              <h3>{{ memberShipDetails.membership_duration }}</h3>
            </div>
          </div>
          <div class="action-div">
            <span
              v-if="
                details.days_need_to_instructor -
                  memberShipDetails.membership_duration >
                  0
              "
              >Need
              {{
                details.days_need_to_instructor -
                  memberShipDetails.membership_duration
              }}
              Day to become an Instructor
            </span>
            <router-link class="btn explore-cta" :to="{ name: 'courseList' }">
              Explore
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MembershipService from "@/services/MembershipService";
export default {
  name: "MembershipSection",
  props: ["memberShipDetails"],
  data: function() {
    return {
      details: [],
      isInstructor: ""
    };
  },
  mounted() {
    let user = JSON.parse(this.$store.getters["auth/user"]);
    this.isInstructor = user.is_instructor;
  },
  created() {
    this.getMembershipDetails();
  },
  methods: {
    async getMembershipDetails() {
      this.loader = true;
      await MembershipService.getMembershipDetails()
        .then(result => {
          this.details = result.data;
        })
        .catch(error => {
          this.loader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    updateActiveTab: function(id) {
      this.$store.dispatch("dashboard/updateActiveTab", id);
    }
  }
};
</script>

<style scoped></style>
