<template>
  <div class="container event-booking-success">
    <div class=" success">
      <img src="../assets/success-circle-outline.svg" alt="Success-icon" />

      <h2>
        Thank You
      </h2>
      <p>Your booking is successful and the details send on your mail.</p>

      <div class="home-btn">
        <router-link :to="'/'">
          <a class="btn cta-primary">Back to Home</a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventBookingSuccess"
};
</script>
