<template>
  <div class="dashboard">
    <div class="container-fluid primary-infowrap">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="primary-info__card">
              <div class="image-wrap">
                <img :src="user.image" class="img-fluid" alt="" />
              </div>
              <div class="primary-card__content">
                <h4>{{ user.name | capitalize }}</h4>
                <span class="member-badge" v-if="user.user_type == 1">
                  Admin
                </span>
                <span class="member-badge" v-else-if="user.user_type == 2">
                  Recruiter
                </span>
                <span class="member-badge" v-else-if="user.user_type == 3">
                  Member
                </span>
                <span class="member-badge" v-else>
                  User
                </span>
              </div>
              <ul class="list-unstyled social-list d-flex m-auto">
                <li data-v-40ab164b="">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li data-v-40ab164b="">
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li data-v-40ab164b="">
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                </li>
              </ul>
            </div>
            <hr />
            <div class="secondary-info">
              <div>
                <span class="right-info points-badge">{{
                  courses.length
                }}</span>
                Courses
              </div>
              <div>
                <span class="right-info points-badge">{{ blogs.length }}</span>
                Blogs
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="container user-about-wrap">
              <h3>Bio</h3>
              <p class="user-about__content">
                {{ user.description }}
              </p>
            </div>
            <div class="container category-wrapper">
              <h3>Courses</h3>
              <div class="category-tab-wrapper">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-watchlist"
                    role="tabpanel"
                    aria-labelledby="pills-watchlist-tab"
                  >
                    <div class="all-category-wrapper">
                      <div class="row" v-if="loader == true">
                        <Loader></Loader>
                      </div>
                      <div class="row" v-else>
                        <div
                          class="col-md-4"
                          v-for="(course, purchaseIndex) in courses"
                          :key="purchaseIndex"
                        >
                          <CourseCard :course="course" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container category-wrapper">
              <h3>Blogs</h3>
              <div class="category-tab-wrapper">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-watchlist"
                    role="tabpanel"
                    aria-labelledby="pills-watchlist-tab"
                  >
                    <div class="all-category-wrapper">
                      <div class="row" v-if="loader == true">
                        <Loader></Loader>
                      </div>
                      <div class="row" v-else-if="blogs.length == 0">
                        <div class="text-center col-md-12">
                          <p>No blogs found</p>
                          <router-link
                            class="btn cta-primary"
                            :to="{ name: 'createBlog' }"
                          >
                            Create Blog
                          </router-link>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div
                          class="col-md-4"
                          v-for="(blog, blogIndex) in blogs"
                          :key="blogIndex"
                        >
                          <div class="blog-card">
                            <div class="blog-image-wrapper">
                              <img :src="blog.image" class="img-fluid" alt="" />
                            </div>
                            <div class="blog-body">
                              <h5>{{ blog.title }}</h5>
                              <!-- <p>{{ blog.meta_description }}</p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container user-comments-wrap">
              <h3>Discussions</h3>
              <div class="user-comments">
                <div class="row">
                  <div
                    class="col-md-4"
                    v-for="(discussion, discussionIndex) in latestDiscussions"
                    :key="discussionIndex"
                  >
                    <a href="#">
                      <div class="comment-card card">
                        <h5>{{ discussion.title }}</h5>
                        <p>
                          {{ discussion.discussion }}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CourseCard from "@/components/CourseCard.vue";
import AuthService from "@/services/AuthService";
import Loader from "@/components/Loader.vue";
export default {
  name: "PublicProfile",
  components: {
    CourseCard,
    Loader
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  data: function() {
    return {
      name: this.$route.params.name,
      id: this.$route.params.id,
      user: [],
      courses: [],
      blogs: [],
      loader: true,
      latestDiscussions: []
    };
  },
  created() {
    this.getProfileDetails();
    this.getLatestDiscussions();
  },
  methods: {
    async getProfileDetails() {
      await AuthService.getProfileDetails({
        name: this.name,
        id: this.id
      })
        .then(result => {
          this.loader = false;
          if (result.data.status == "success") {
            this.user = result.data.user;
            this.courses = result.data.courses;
            this.blogs = result.data.blogs;
          } else {
            this.$router.push({ name: "PageNotFound" });
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
          this.loader = false;
        });
    },
    async getLatestDiscussions() {
      await AuthService.getLatestDiscussions({
        id: this.id
      })
        .then(result => {
          this.loader = false;
          this.latestDiscussions = result.data.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
          this.loader = false;
        });
    }
  }
};
</script>
