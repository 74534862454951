import Vue from "vue";
import Vuex from "vuex";
import course from "./modules/course";
import testimonial from "./modules/testimonial";
import blog from "./modules/blog";
import auth from "./modules/auth";
import upload from "./modules/upload";
import dashboard from "./modules/dashboard";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

Vue.use(Vuex);

const persistedStateOptions = {
  paths: ["auth"],
  storage: {
    getItem: key => Cookies.get(key),
    setItem: (key, value) =>
      Cookies.set(key, value, { expires: 1000000, secure: false }),
    removeItem: key => Cookies.remove(key)
  }
};
export default new Vuex.Store({
  modules: {
    course,
    testimonial: testimonial,
    blog: blog,
    auth: auth,
    upload: upload,
    dashboard: dashboard
  },
  plugins: [createPersistedState(persistedStateOptions)]
});
