<template>
  <div class="featured-course-sect">
    <h4 class="sect-head">Featured Courses</h4>
    <div class="row course-list-row" v-if="isLoadingFeaturedCourse == false">
      <div
        class="col-md-4"
        v-for="(course, courseIndex) in featuredCourses"
        :key="courseIndex"
      >
        <transition name="appear">
          <CourseCard :course="course"></CourseCard>
        </transition>
      </div>
    </div>
    <div class="row" v-else>
      <Loader></Loader>
    </div>
  </div>
</template>
<script>
import CourseCard from "@/components/CourseCard.vue";
import Loader from "@/components/Loader.vue";
export default {
  name: "FeaturedCourses",
  props: ["featuredCourses", "isLoadingFeaturedCourse"],
  components: { CourseCard, Loader }
};
</script>
<style scoped>
.empty-course {
  text-align: center !important;
  width: 100%;
  color: #644fa1;
  font-size: 17px;
}
.row.featured-loader {
  height: 160px;
}
</style>
