import Http from "@/services/Http";
export default {
  getFeaturedBlogs() {
    return Http().post("blogs/featured");
  },
  getMyBlogs() {
    return Http().post("my-blogs");
  },
  saveBlog(data) {
    return Http().post("blog/save", data);
  },
  getBlog(data) {
    return Http().post("blog/get", data);
  },
  getComments(data) {
    return Http().post("blog/comments", data);
  },
  saveComment(data) {
    return Http().post("blog/comment/save", data);
  },
  recentBlogs(data) {
    return Http().post("blog/recent", data);
  },
  getBlogs(page) {
    return Http().post("blogs/get?page=" + page);
  },
  latestBlog() {
    return Http().post("blog/latest");
  },
  getCms(data) {
    return Http().post(data);
  }
};
