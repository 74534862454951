const upload = {
  namespaced: true,
  state: {
    isUploading: false
  },
  mutations: {
    UPDATE_UPLOADING_STATUS: (state, loading) => {
      state.isUploading = loading;
    }
  },
  actions: {
    uploadingStatus: ({ commit }, status) => {
      commit("UPDATE_UPLOADING_STATUS", status);
    }
  }
};
export default upload;
