<template>
  <div class="blog-listing container search-listing webinar-listing">
    <div v-if="loader == true">
      <loader></loader>
    </div>
    <div class="container" v-else-if="this.paymentStatus == false && loader == false">
      <div class="row">
        <div class="col-md-8">
          <div class="checkout">
            <div class="checkout-box">
              <div class="title">
                <h5>Login<i class="fas fa-check"></i></h5>
              </div>
              <h5>
                {{ this.user.woman_name }}
              </h5>
              <p>
                {{ this.user.email }}
              </p>
            </div>
            <div class="checkout-box checkout-box-address">
              <div class="address-group">
                <div class="checkout-box__content">
                  <div class="title">
                    <h5>Address<i class="fas fa-check"></i></h5>
                  </div>
                  <h5>
                    {{ this.user.woman_name }}
                  </h5>
                </div>
                <div class="checkout-box__edit">
                  <button
                    class="btn cta-primary"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="true"
                    aria-controls="collapseExample"
                  >
                    change Address
                  </button>
                </div>
              </div>
              <div class="collapse show" id="collapseExample">
                <div class="change-address">
                  <form>
                    <div class="form-group col-md-12">
                      <label for="exampleInputAddress">Address</label>
                      <textarea
                        name="address"
                        v-model="user.address"
                        rows="3"
                        class="form-control"
                        id="address"
                      ></textarea>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="exampleInputPincode">ZIP Code</label>
                      <input
                        type="number"
                        v-model="user.zip_code"
                        class="form-control"
                        id="zip_code"
                      />
                    </div>
                    <div class="mb-3 col-md-12">
                      <label for="validationDefault04">State</label>
                      <input
                        type="text"
                        v-model="user.state"
                        class="form-control"
                        id="state"
                      />
                    </div>
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        ref="theCheckbox"
                      />
                      <label class="form-check-label" for="exampleCheck1"
                        >Make default address</label
                      >
                    </div>
                    <div class="btns">
                      <button
                        type="button"
                        class="btn cta-primary"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="checkout-box">
              <div class="title">
                <h5>Booking Summary<i class="fas fa-check"></i></h5>
              </div>
              <div class="summary">
                <div class="webinar-image-wrapper">
                  <img :src="course.image" class="img-fluid" alt="" />
                </div>
                <div class="summary-content">
                  <h5>{{ course.title }}</h5>
                  <p>{{ course.date }}</p>
                  <p>
                    Price
                    <span>
                      ₹
                    </span>
                    {{ course.price }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="pay">
            <h5>Price Details</h5>
            <div class="price-detail">
              <p>
                Pay Now
              </p>
              <p><span> ₹ </span>{{ course.price }}</p>
            </div>
            <div class="cta-btn">
              <button
                @click="addressValidations()"
                class="btn cta-primary"
                :disabled="isPaymentProgressing == true"
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="not-found-wrapper" v-else-if="loader == false && this.paymentStatus == true">
      <img src="@/assets/not-found.svg" alt="not-found" />
      <h4>Course has already booked!</h4>
    </div>
  </div>
</template>

<script>
  import Loader from "../components/Loader.vue";
  import OfflineCourseService from "@/services/OfflineCourseService";
  import EventCheckoutService from "@/services/EventCheckoutService";

  export default {
    name: 'OfflineCourseCheckout',
    components: {
      Loader
    },
    data() {
      return {
        loader: true,
        course: [],
        user: [],
        isPaymentProgressing: false,
        paymentStatus: false,
        isChecked: false
      };
    },
    created() {
      this.getCoursePayment();
      this.getUser();
      this.getCourse();
    },
    methods: {
      async getCoursePayment() {
        let params = {
          course_id: this.$route.params.id
        };
        await OfflineCourseService.getCoursePayment(params)
          .then((output) => {
            this.loader = false;
            if (output.data.status == 'success') {
              this.paymentStatus = output.data.payment;
            }
          });
      },
      async getUser() {
        await OfflineCourseService.getUser()
        .then((output) => {
          this.loader = false;
          if (output.data.status == "success") {
            this.user = output.data.user;
          }
        });
      },
      async getCourse() {
        await OfflineCourseService.getOfflineCourse({ id: this.$route.params.id })
        .then((output) => {
          if (output.data.status == "success") {
            this.course = output.data.course;
          } else {
            this.$router.push({ name: "PageNotFound" });
          }
        });
      },
      async addressValidations() {
        let params = {
          address: this.user.address,
          state: this.user.state,
          zip_code: this.user.zip_code
        };
        await EventCheckoutService.addressValidations(params).then(output => {
          if (output.data.status == "success") {
            this.payment();
          } else if (output.data.status == "validation_error") {
            let errorsObject = output.data.errors;
            let errorValuesArray = Object.values(errorsObject);
            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toastr.e(errorValuesArray[index]);
              }
            }
          }
        });
      },
      payment() {
        this.isPaymentProgressing = false;
        let total = (parseFloat(this.course.price) * 100).toFixed(2);
        var rzp1 = new window.Razorpay({
          key: process.env.VUE_APP_RAZOR_PAY_KEY,
          amount: total,
          name: "Ology Women",
          currency: "INR",
          description: "test description",
          image: "https://ologywomen.com/img/Ologylogo-color.5ca56780.png",
          handler: response => {
            this.isPaymentProgressing = true;
            this.saveCourseBooking(response);
          },
          prefill: {
            name: this.user.name,
            email: this.user.email,
            contact: this.user.phone
          },
          notes: {
            address: ""
          },
          theme: {
            color: "#00ffff"
          }
        });
        rzp1.open();
        rzp1.on("payment.failed", function(response) {
          alert(response.error.description);
        });
      },
      async saveCourseBooking(response) {
        this.$isLoading(true);
        if (this.$refs.theCheckbox.checked) {
          this.isChecked = true;
        } else {
          this.isChecked = false;
        }
        let params = {
          razorpay_payment_id: response.razorpay_payment_id,
          course_id: this.course.id,
          total: this.course.price,
          address: this.user.address,
          state: this.user.state,
          zip_code: this.user.zip_code,
          isChecked: this.isChecked
        };
        await OfflineCourseService.saveCourseBooking(params)
          .then(output => {
          this.$isLoading(false);
          if (output.data.status == "success") {
            this.$router.push({
              name: "EventBookingSuccess"
            });
          } else if (output.data.status == "validation_error") {
            this.$toastr.e(output.data.message);
          }
        });
      },
    },
  };

</script>
