import axios from "axios";

export default () => {
  const token = process.env.VUE_APP_VIMEO_ACCESS_TOKEN;
  return axios.create({
    baseURL: "https://api.vimeo.com/",
    withCredentials: false,
    headers: {
      Authorization: `bearer ${token}`,
      Accept: "application/vnd.vimeo.*+json;version=3.4",
      "Content-Type": "application/json"
    }
  });
};
