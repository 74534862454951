<template>
  <div
    class="blog-listing container search-listing webinar-listing event-calander"
  >
    <div v-if="loader == true">
      <loader></loader>
    </div>
    <div v-else class="container">
      <div class="d-flex bd-highlight">
        <div class="custom-head flex-grow-1">
          <h2>Event Calendar</h2>
        </div>
        <div class="back-btn">
          <router-link :to="{ name: 'webinars' }" class="btn cta-primary"
            >Back to Events</router-link
          >
        </div>
      </div>
      <vue-cal
        :time="true"
        :selected-date="this.currentDate"
        :disable-views="['years', 'year']"
        active-view="month"
        events-on-month-view="short"
        :events="events"
        style="height: 600px"
      >
      </vue-cal>
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import WebinarService from "@/services/WebinarService";
import Loader from "../components/Loader.vue";

export default {
  name: "Calendar",
  components: {
    Loader,
    VueCal
  },
  data() {
    return {
      loader: true,
      currentDate: null,
      events: []
    };
  },
  created() {
    this.getEventDates();
  },
  methods: {
    async getEventDates() {
      await WebinarService.getEventDates()
        .then(output => {
          this.loader = false;
          if (output.data.status == "success") {
            this.currentDate = output.data.currentDate;
            this.events = output.data.items;
          }
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>
