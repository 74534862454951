<template>
  <div class="hosted-course-section">
    <h5 class="hero-head">Revenue</h5>
    <table class="table" v-if="isInstructor == 1">
      <thead>
        <tr>
          <th colspan="2" scope="col">
            <span style="padding-left: 50px">Course name</span>
          </th>
          <th scope="col">Revenue</th>
        </tr>
      </thead>
      <tbody v-if="loader == false">
        <tr v-for="(revenue, revenueIndex) in revenues" :key="revenueIndex">
          <td colspan="2">
            <div class="course-info">
              <div class="img-wrap">
                <img :src="revenue.image" alt="" />
              </div>
              <div class="title-wrap">
                <h5>{{ revenue.title }}</h5>
                <p>{{ revenue.created_on }}</p>
              </div>
            </div>
          </td>
          <td>
            <h5 class="price" v-if="revenue.revenue != null">
              <span>₹</span> {{ revenue.revenue.toFixed(2) }}
            </h5>
            <h5 class="price" v-else><span>₹</span> 0</h5>
          </td>
        </tr>
        <tr v-if="revenues.length == 0">
          <td class="align-middle" colspan="3">
            <div class="not-found-wrapper">
              <img src="@/assets/not-found.svg" alt="not-found" />
              <h4>No course hosted</h4>
              <router-link
                :to="{ name: 'createCourse' }"
                class="btn cta-primary"
              >
                Host a course
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="align-middle" colspan="3">
            <loader></loader>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="2" scope="price">
            <span style="padding-left: 50px">Total</span>
          </th>
          <th scope="price">
            <h5 class="price"><span>₹</span> {{ totalAmount }}</h5>
          </th>
        </tr>
      </tfoot>
    </table>
    <div class="not-found-wrapper" v-if="user_type != 3">
      <img src="@/assets/not-found.svg" alt="not-found" />
      <h4>You are not a member</h4>
      <router-link :to="{ name: 'Membership' }" class="btn cta-primary">
        Subscribe
      </router-link>
    </div>
    <div class="not-found-wrapper" v-if="isInstructor != 1 && user_type == 3">
      <img src="@/assets/not-found.svg" alt="not-found" />
      <h4>You are not a instructor</h4>
      <router-link :to="{ name: 'courseList' }" class="btn cta-primary">
        Explore More
      </router-link>
    </div>
  </div>
</template>

<script>
import CourseService from "@/services/CourseService";
import Loader from "../Loader.vue";
export default {
  components: { Loader },
  name: "HostedCourseSection",
  data: function() {
    return {
      revenues: [],
      error: [],
      loader: true,
      isInstructor: "",
      user_type: ""
    };
  },
  mounted() {
    let user = JSON.parse(this.$store.getters["auth/user"]);
    this.isInstructor = user.is_instructor;
    this.user_type = user.user_type;
  },
  created: function() {
    this.getRevenues();
  },
  computed: {
    totalAmount: function() {
      var sum = 0;
      this.revenues.forEach(revenue => {
        if (revenue.revenue != null) {
          sum += revenue.revenue;
        }
      });
      return sum.toFixed(2);
    }
  },
  methods: {
    async getRevenues() {
      this.loader = true;
      await CourseService.getRevenues()
        .then(result => {
          this.revenues = result.data;
          this.loader = false;
        })
        .catch(error => {
          this.loader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>

<style scoped></style>
