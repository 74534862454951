import Http from "@/services/Http";

export default {
  getUser() {
    return Http().post("webinar/get/user");
  },
  getEventPayment(data) {
    return Http().post("webinar/get/payment", data);
  },
  addressValidations(data) {
    return Http().post("webinar/address/validations", data);
  },
  saveEventBooking(data) {
    return Http().post("webinar/booking/save", data);
  },
  getEvent(data) {
    return Http().post("event/details", data);
  },
};
