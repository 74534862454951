<template>
  <div class="search-listing">
    <div class="container-fluid">
      <div class="container">
        <div class="row result-wrapper">
          <!-- //sidebar -->
          <div class="col-md-3">
            <div class="filter-wrap">
              <div class="mobile-nav">
                <button
                  class="btn btn-block mobile-nav-button"
                  @click="toggleButton"
                >
                  Categories
                </button>
              </div>
              <div class="filter-sidebar" :class="{ active: isFilterActive }">
                <ul class="list-unstyled">
                  <li><h6>All Categories</h6></li>
                  <div
                    v-for="(category, categoryIndex) in categories"
                    :key="categoryIndex"
                  >
                    <button
                      class="btn cta-filter"
                      data-toggle="collapse"
                      :href="'#multiCollapseExample' + category.id"
                      role="button"
                      aria-expanded="false"
                      :aria-controls="'multiCollapseExample' + categoryIndex"
                      :class="getMyClasses(category.id)"
                      @click="mainCategoryFilter(category.id)"
                    >
                      {{ category.title }}
                    </button>
                    <li
                      class="collapse multi-collapse sub-list"
                      :class="{
                        collapse: searchCategory == category.id,
                        show: searchCategory == category.id
                      }"
                      :id="'multiCollapseExample' + category.id"
                      v-for="(subCategory,
                      subCategoryIndex) in category.subcategories"
                      :key="subCategoryIndex"
                    >
                      <div class="container-checkbox">
                        <input
                          type="checkbox"
                          v-model="categoryFilter"
                          :value="subCategory.id"
                          @change="resetPage"
                        />
                        <label>{{ subCategory.title }}</label>
                      </div>
                    </li>
                  </div>
                </ul>
                <hr class="mr-4" />
                <!-- <ul class="list-unstyled">
                <li><h6>Plan</h6></li>
                <li v-for="(price, priceIndex) in prices" :key="priceIndex">
                  <div class="container-checkbox">
                    <input
                      type="checkbox"
                      v-model="priceFilter"
                      :value="price.id"
                      @change="resetPage"
                    />
                    <label>{{ price.title }}</label>
                  </div>
                </li>
              </ul> -->
                <ul class="list-unstyled">
                  <button
                    class="btn cta-filter active font-weight-bold"
                    data-toggle="collapse"
                    href="#languageMultiCollapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="languageMultiCollapse"
                  >
                    Languages
                  </button>
                  <li
                    class="collapse multi-collapse sub-list"
                    id="languageMultiCollapse"
                    v-for="(language, languageIndex) in languages"
                    :key="languageIndex"
                  >
                    <div class="container-checkbox">
                      <input
                        type="checkbox"
                        v-model="languageFilter"
                        :value="language.id"
                        @change="resetPage"
                      />
                      <label>{{ language.language }}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- //list-box -->
          <div class="col-md-9">
            <div
              class="row sort-wrap no-gutters align-items-center justify-content-between"
            >
              <div class="col-md-6">
                <h5 class="mb-0" v-if="search !== '' && search !== undefined">
                  {{ totalResult }} Results found for "{{ search }}"
                </h5>
                <h5 class="mb-0" v-else>
                  All courses
                </h5>
              </div>
              <div class="col-md-5 text-right">
                <div class="sort-div d-flex">
                  <!-- <label for="filterSelect">Sort by</label> -->
                  <multiselect
                    v-model="sortValue"
                    :options="sortOptions"
                    :searchable="true"
                    :close-on-select="true"
                    label="text"
                    track-by="text"
                    :show-labels="false"
                    placeholder="Sort by"
                    @select="onSelectSort"
                    @remove="onRemoveSort"
                  ></multiselect>
                </div>
              </div>
            </div>
            <HeroCourse :courses="coursesOfTheDay"></HeroCourse>
            <FeaturedCourses
              :featuredCourses="featuredCourses"
              :isLoadingFeaturedCourse="isLoadingFeaturedCourse"
              v-if="featuredCourses.length > 0"
            ></FeaturedCourses>

            <TrendingCourses
              :trendingCourses="trendingCourses"
              :isLoadingTrendingCourse="isLoadingTrendingCourse"
              v-if="trendingCourses.length > 0"
            ></TrendingCourses>
            <h4 class="sect-head" v-if="courses.length > 0 && totalResult != 0">
              Explore Courses
            </h4>
            <div class="row course-list-row" v-if="isLoadingCourse == false">
              <div
                class="col-md-4"
                v-for="(course, courseIndex) in courses"
                :key="courseIndex"
              >
                <transition name="appear">
                  <CourseCard :course="course"></CourseCard>
                </transition>
              </div>
              <div
                class="col-md-12 course-not-found"
                v-if="courses.length == 0 && isLoadingCourse == false"
              >
                <img src="@/assets/not-found.svg" alt="not-found" />
                <h4>Coming Soon ...</h4>
              </div>
              <div class="col-md-12">
                <div
                  class="d-flex flex-row-reverse bd-highlight"
                  v-if="isLoadingCourse == false && totalPages > 1"
                >
                  <p>
                    Showing {{ resultFrom }} to {{ resultTo }} of
                    {{ totalResult }} courses
                  </p>
                </div>
                <div
                  class="d-flex flex-row-reverse bd-highlight"
                  v-if="isLoadingCourse == false && totalPages > 1"
                >
                  <SlidingPagination
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  >
                  </SlidingPagination>
                </div>
              </div>
            </div>
            <div class="row course-list-row" v-else>
              <Loader></Loader>
            </div>
            <h4
              class="sect-head"
              v-if="totalResult == 0 && otherCourses.length > 0"
            >
              Explore Other Courses
            </h4>
            <div class="row course-list-row" v-if="totalResult == 0">
              <div
                class="col-md-4"
                v-for="(course, courseIndex) in otherCourses"
                :key="courseIndex"
              >
                <CourseCard :course="course"></CourseCard>
              </div>

              <div class="col-md-12">
                <div
                  class="d-flex flex-row-reverse bd-highlight"
                  v-if="isLoadingCourse == false && totalPages > 1"
                >
                  <p>
                    Showing {{ resultFrom }} to {{ resultTo }} of
                    {{ totalResult }} courses
                  </p>
                </div>
                <div
                  class="d-flex flex-row-reverse bd-highlight"
                  v-if="isLoadingCourse == false && totalPages > 1"
                >
                  <SlidingPagination
                    :current="currentPage"
                    :total="totalPages"
                    @page-change="pageChangeHandler"
                  >
                  </SlidingPagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //popular-courses -->
    <PopularCourses></PopularCourses>
  </div>
</template>

<script>
// import CourseSearch from "@/components/CourseSearch.vue";
import CourseCard from "@/components/CourseCard.vue";
import PopularCourses from "@/components/PopularCourses.vue";
import FeaturedCourses from "@/components/FeaturedCourses.vue";
import HeroCourse from "@/components/HeroCourse.vue";
import TrendingCourses from "@/components/TrendingCourses.vue";
import Multiselect from "vue-multiselect";
import CourseService from "@/services/CourseService";
import LanguageService from "@/services/LanguageService";
import SlidingPagination from "vue-sliding-pagination";
import CategoryService from "@/services/CategoryService";
import PriceService from "@/services/PriceService";
import $ from "jquery";

import Loader from "../components/Loader.vue";
export default {
  name: "CourseList",
  components: {
    CourseCard,
    PopularCourses,
    Multiselect,
    SlidingPagination,
    HeroCourse,
    FeaturedCourses,
    TrendingCourses,
    Loader
  },
  watch: {
    "$route.query.search": function() {
      this.search = this.$route.query.search;
      this.listCourses();
      this.getFeaturedCourses();
      this.getTrendingCourses();
      this.getCourseOfTheDay();
    },
    "$route.query.category": function() {
      this.mainCategories = [];
      $(".multi-collapse").removeClass("show");
      let category = this.$route.query.category;
      if (typeof category != "undefined") {
        this.mainCategories.push(this.$route.query.category);
      }
      // this.getPrices();
      this.listCourses();
      this.getFeaturedCourses();
      this.getTrendingCourses();
      this.getCourseOfTheDay();
    }
  },
  data() {
    return {
      sortValue: "",
      sortOptions: [
        { value: "courses.created_at", text: "Latest Course", sort: "desc" },
        { value: "courses.created_at", text: "Old Course", sort: "asc" },
        { value: "courses.title", text: "Name A-Z", sort: "asc" },
        { value: "courses.title", text: "Name Z-A", sort: "desc" }
      ],
      search: this.$route.query.search,
      courses: [],
      currentPage: 0,
      totalPages: 0,
      isLoadingCourse: true,
      languages: [],
      categories: [],
      prices: [],
      categoryFilter: [],
      priceFilter: [],
      languageFilter: [],
      totalResult: 0,
      resultFrom: 0,
      resultTo: 0,
      sort: {},
      featuredCourses: [],
      isLoadingFeaturedCourse: true,
      trendingCourses: [],
      isLoadingTrendingCourse: true,
      mainCategories: [],
      isFilterActive: false,
      otherCourses: [],
      coursesOfTheDay: [],
      searchCategory: this.$route.query.category
    };
  },
  created() {
    let category = this.$route.query.category;
    if (typeof category != "undefined") {
      this.mainCategories.push(this.$route.query.category);
    }
    this.listCourses();
    this.getFeaturedCourses();
    this.getTrendingCourses();
    this.getLanguages();
    this.getCategories();
    this.getCourseOfTheDay();
    // this.getPrices();
  },
  methods: {
    async listCourses() {
      const data = {
        search: this.search,
        categories: this.categoryFilter,
        prices: this.priceFilter,
        languages: this.languageFilter,
        sort: this.sort["sort"],
        column: this.sort["column"],
        mainCategories: this.mainCategories
      };
      await CourseService.listCourses(data, this.currentPage)
        .then(result => {
          this.isLoadingCourse = false;
          this.courses = result.data.data;
          this.totalPages = result.data.last_page;
          this.currentPage = result.data.current_page;
          this.totalResult = result.data.total;
          this.resultFrom = result.data.from;
          this.resultTo = result.data.to;
          if (this.totalResult == 0) {
            this.listAllCourses();
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async listAllCourses() {
      const data = {
        search: null,
        categories: [],
        prices: [],
        languages: [],
        sort: this.sort["sort"],
        column: this.sort["column"],
        mainCategories: []
      };
      await CourseService.listCourses(data, this.currentPage)
        .then(result => {
          this.isLoadingCourse = false;
          this.otherCourses = result.data.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },

    async getFeaturedCourses() {
      const data = {
        search: this.search,
        categories: this.categoryFilter,
        prices: this.priceFilter,
        languages: this.languageFilter,
        sort: this.sort["sort"],
        column: this.sort["column"],
        mainCategories: this.mainCategories
      };
      await CourseService.getFeaturedCourseList(data, this.currentPage)
        .then(result => {
          this.isLoadingFeaturedCourse = false;
          this.featuredCourses = result.data.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getTrendingCourses() {
      const data = {
        search: this.search,
        categories: this.categoryFilter,
        prices: this.priceFilter,
        languages: this.languageFilter,
        sort: this.sort["sort"],
        column: this.sort["column"],
        mainCategories: this.mainCategories
      };
      await CourseService.getTrendingCourseList(data, this.currentPage)
        .then(result => {
          this.isLoadingTrendingCourse = false;
          this.trendingCourses = result.data.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getLanguages() {
      await LanguageService.getLanguages({ course_list: true })
        .then(result => {
          this.languages = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getCategories() {
      await CategoryService.getCategories({
        requestFor: "courseAvailableCategory"
      })
        .then(result => {
          this.categories = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    mainCategoryFilter(id) {
      let isFind = false;
      for (var i = this.mainCategories.length - 1; i >= 0; i--) {
        if (this.mainCategories[i] == id) {
          this.mainCategories.splice(i, 1);
          isFind = true;
        }
      }
      if (isFind == false) {
        this.mainCategories.push(id);
      }
      this.resetPage();
    },
    async getPrices() {
      await PriceService.getPrice()
        .then(result => {
          this.prices = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      this.listCourses();
    },
    resetPage: function() {
      this.currentPage = 1;
      this.isLoadingCourse = true;
      this.isLoadingFeaturedCourse = true;
      this.isLoadingTrendingCourse = true;
      this.listCourses();
      this.getFeaturedCourses();
      this.getTrendingCourses();
      this.getCourseOfTheDay();
    },
    changeKeyword: function(keyword) {
      this.$router
        .push({ name: "courseList", query: { search: keyword } })
        .catch(() => {});
      this.search = keyword;
      this.resetPage();
    },
    onSelectSort: function(option) {
      this.sort["column"] = option.value;
      this.sort["sort"] = option.sort;
      this.resetPage();
    },
    onRemoveSort: function() {
      this.sort["column"] = "";
      this.sort["sort"] = "";
      this.resetPage();
    },
    getMyClasses: function(id) {
      let isFind = false;
      for (var i = this.mainCategories.length - 1; i >= 0; i--) {
        if (this.mainCategories[i] == id) {
          isFind = true;
        }
      }
      if (isFind == true) {
        return "active";
      } else {
        return "";
      }
    },
    toggleButton: function() {
      if (this.isFilterActive == false) {
        this.isFilterActive = true;
      } else {
        this.isFilterActive = false;
      }
    },
    async getCourseOfTheDay() {
      const data = {
        search: this.search,
        categories: this.categoryFilter,
        prices: this.priceFilter,
        languages: this.languageFilter,
        sort: this.sort["sort"],
        column: this.sort["column"],
        mainCategories: this.mainCategories
      };
      await CourseService.getCourseOfTheDay(data)
        .then(result => {
          this.coursesOfTheDay = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>
