import Http from "@/services/Http";
export default {
  getFeaturedCourse(data) {
    return Http().post("courses/featured", data);
  },
  getPopularCourse() {
    return Http().post("courses/popular");
  },
  getHostedCourse(page, data) {
    return Http().post("course/hosted?page=" + page, data);
  },
  saveCourse(data) {
    return Http().post("course/add", data);
  },
  getCourse(data) {
    return Http().post("course/get", data);
  },
  listCourses(data, page) {
    return Http().post("courses?page=" + page, data);
  },
  watchCourse(data) {
    return Http().post("course/watch", data);
  },
  publishCourse(data) {
    return Http().post("course/publish", data);
  },
  saveReview(data) {
    return Http().post("review/save", data);
  },
  getReviews(data) {
    return Http().post("reviews/get", data);
  },
  getDiscussions(data) {
    return Http().post("discussions/get", data);
  },
  promoCode(data) {
    return Http().post("promo-code/apply", data);
  },
  getCheckoutSettings(data) {
    return Http().post("checkout/settings", data);
  },
  getBillingAddress() {
    return Http().post("billing-address/get");
  },
  saveBillingAddress(data) {
    return Http().post("billing-address/save", data);
  },
  saveOrder(data) {
    return Http().post("order/save", data);
  },
  saveFreeOrder(data) {
    return Http().post("order/free/save", data);
  },
  saveDiscussion(data) {
    return Http().post("discussions/save", data);
  },
  getPurchasedCourse() {
    return Http().post("course/purchased");
  },
  saveProject(data) {
    return Http().post("project/save", data);
  },
  previewCourse(data) {
    return Http().post("course/preview", data);
  },
  getResourceCount(data) {
    return Http().post("resource/count", data);
  },
  getCertificate(data) {
    return Http().post("certificate/get", data);
  },
  getRevenues() {
    return Http().post("course/revenue");
  },
  getCourseOfTheDay(data) {
    return Http().post("course/hero", data);
  },
  getFeaturedCourseList(data) {
    return Http().post("featured/course/list", data);
  },
  getTrendingCourseList(data) {
    return Http().post("trending/course/list", data);
  },
  getProjects(data) {
    return Http().post("projects/get", data);
  },
  addWishList(data) {
    return Http().post("wishlist/add", data);
  },
  getWishlists() {
    return Http().post("wishlist/get");
  },
  uploadResource(data) {
    return Http().post("course/resource/upload", data);
  },
  getResources(data) {
    return Http().post("course/resource/get", data);
  },
  getCompletedSections(data) {
    return Http().post("completed/sections", data);
  },
  getLatestCourse() {
    return Http().post("course/latest");
  }
};
