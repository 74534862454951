import Http from "@/services/Http";
export default {
  login(data) {
    return Http().post("login", data);
  },
  register(data) {
    return Http().post("register", data);
  },
  logout() {
    return Http().post("logout");
  },
  getUser() {
    return Http().post("user/get");
  },
  recruiterRegister(data) {
    return Http().post("recruiter/register", data);
  },
  referFriend(data) {
    return Http().post("referral/send", data);
  },
  sendRestLink(data) {
    return Http().post("reset/link", data);
  },
  updatePassword(data) {
    return Http().post("password/update", data);
  },
  sendActivationLink(data) {
    return Http().post("reset/activation/link", data);
  },
  verifyEmail(data) {
    return Http().post("verify/email", data);
  },
  updateUser(data) {
    return Http().post("user/update", data);
  },
  updateUserPassword(data) {
    return Http().post("update/user/password", data);
  },
  getProfileDetails(data) {
    return Http().post("profile/public", data);
  },
  getLatestDiscussions(data) {
    return Http().post("profile/last-discussion", data);
  }
};
