<template>
  <div class="login">
    <div class="container-fluid register-wrap">
      <div class="container">
        <div
          class="row register-row justify-content-between align-items-center"
        >
          <div class="col-md-5">
            <h2>Welcome back</h2>
            <p>Please login to your account</p>
            <div class="register-form-wrap">
              <form @submit.prevent="login">
                <div class="form-group">
                  <label for="mail">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="user_name"
                    id="mail"
                    aria-describedby=""
                    placeholder="ex: charles.lawson@mail.com"
                  />
                  <div class="error-message">
                    {{ validation.firstError("user_name") }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="InputPassword">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="InputPassword"
                    v-model="password"
                    placeholder="Password"
                  />
                  <div class="error-message">
                    {{ validation.firstError("password") }}
                  </div>
                </div>
                <div class="action-wrap">
                  <p class="forget-action">
                    <router-link
                      class="btn-clear"
                      :to="{ name: 'forgotPassword' }"
                    >
                      Forgot password?
                    </router-link>
                  </p>
                  <button
                    type="submit"
                    class="btn cta-primary"
                    :disabled="this.$store.state.auth.isAuthenticating == true"
                  >
                    <span
                      v-if="this.$store.state.auth.isAuthenticating == false"
                    >
                      Login
                    </span>
                    <span v-else>
                      <ButtonLoader></ButtonLoader>
                    </span>
                  </button>
                  <p>
                    New to OlogyWomen?
                    <router-link class="btn-clear" to="/register">
                      Sign Up
                    </router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import SimpleVueValidation from "simple-vue-validator";
import ButtonLoader from "@/components/ButtonLoader.vue";
const Validator = SimpleVueValidation.Validator;
export default {
  name: "Login",
  components: {
    ButtonLoader
  },
  data() {
    return {
      user_name: "",
      password: "",
      authStatus: "",
      token: "",
      user: ""
    };
  },
  validators: {
    user_name: function(value) {
      return Validator.value(value).required("Please enter your email/phone");
    },
    password: function(value) {
      return Validator.value(value).required("Please enter your password");
    }
  },
  methods: {
    login: function() {
      this.$validate().then(success => {
        if (success) {
          this.$store.dispatch("auth/authRequest", true);
          let user_name = this.user_name;
          let password = this.password;
          AuthService.login({ user_name, password })
            .then(result => {
              this.authStatus = result.data.status;
              if (this.authStatus == "error") {
                this.$toastr.e(result.data.message);
                this.$store.dispatch("auth/authError");
                this.$store.dispatch("auth/authRequest", false);
              } else {
                this.$toastr.s(result.data.message);
                const userData = {
                  token: result.data.token,
                  user: result.data.user
                };
                this.$store.dispatch("auth/authSuccess", userData);
                this.$store.dispatch("auth/authRequest", false);
                if (this.$store.state.auth.nextUrl == "") {
                  this.$router.push({ name: "Home" });
                } else {
                  this.$router.push(this.$store.state.auth.nextUrl);
                  this.$store.dispatch("auth/updateNextUrl", "");
                }
              }
            })
            .catch(error => {
              this.error = error;
              // this.$toastr.e("Something went wrong");
              this.$store.dispatch("auth/authError");
              this.$store.dispatch("auth/authRequest", false);
            });
        }
      });
    }
  }
};
</script>
