<template>
  <div class="login">
    <div class="container-fluid register-wrap">
      <div class="container">
        <div
          class="row register-row justify-content-between align-items-center"
        >
          <div class="col-md-5">
            <h2>Please verify your Email</h2>
            <p>
              Please check your registered email address for activation link
            </p>
            <div class="register-form-wrap">
              Not yet received email?
              <button class="btn cta-primary" @click="resend">
                <span v-if="isLoading == false">
                  Resend
                </span>
                <span v-else>
                  <ButtonLoader></ButtonLoader>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import ButtonLoader from "@/components/ButtonLoader.vue";
export default {
  name: "EmailVerification",
  components: {
    ButtonLoader
  },
  data() {
    return {
      isLoading: false,
      showSuccessMessage: false
    };
  },
  created: function() {
    let user = JSON.parse(this.$store.getters["auth/user"]);
    this.email = user.email;
    if (user.email_verified_at != null) {
      this.$router.push({ name: "profilePage" });
    }
  },
  methods: {
    resend: function() {
      this.isLoading = true;
      AuthService.sendActivationLink()
        .then(result => {
          this.isLoading = false;
          if (result.data.status == "success") {
            this.$toastr.s(result.data.message);
            this.showSuccessMessage = true;
          }
          if (result.data.status == "error") {
            this.$toastr.e(result.data.message);
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
        });
    }
  }
};
</script>
