<template>
  <div class="profile-page">
    <div class="container">
      <div class="row profile-content-row">
        <div class="col-md-3 navs-col">
          <div class="primary-info__card">
            <div class="image-wrap">
              <img :src="image" alt="" class="img-fluid" />
            </div>
            <div class="primary-card__content">
              <h4>{{ name | capitalize }}</h4>
              <span class="member-badge" v-if="userType == 1">
                Admin
              </span>
              <span class="member-badge" v-else-if="userType == 2">
                Recruiter
              </span>
              <span class="member-badge" v-else-if="userType == 3">
                Member
              </span>
              <span class="member-badge" v-else>
                User
              </span>
            </div>
          </div>
          <div class="nav-holder">
            <div class="navigation-box" :class="{ active: isNavActive }">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{
                      active:
                        this.$store.state.dashboard.activeTab ==
                        'pills-membership-tab'
                    }"
                    id="pills-membership-tab"
                    data-toggle="pill"
                    href="#pills-membership"
                    role="tab"
                    aria-controls="pills-membership"
                    aria-selected="true"
                    @click="updateActiveTab('pills-membership-tab')"
                    >Dashboard</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-courses-tab"
                    @click="updateActiveTab('pills-courses-tab')"
                    :class="{
                      active:
                        this.$store.state.dashboard.activeTab ==
                        'pills-courses-tab'
                    }"
                    ref="courseTab"
                    data-toggle="pill"
                    href="#pills-courses"
                    role="tab"
                    aria-controls="pills-courses"
                    aria-selected="true"
                    >courses</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-blogs-tab"
                    @click="updateActiveTab('pills-blogs-tab')"
                    :class="{
                      active:
                        this.$store.state.dashboard.activeTab ==
                        'pills-blogs-tab'
                    }"
                    data-toggle="pill"
                    href="#pills-blogs"
                    role="tab"
                    aria-controls="pills-blogs"
                    aria-selected="true"
                    >blogs</a
                  >
                </li>
                <li class="nav-item" v-if="isInstructor == 1">
                  <a
                    class="nav-link"
                    id="pills-mycourse-tab"
                    @click="updateActiveTab('pills-mycourse-tab')"
                    :class="{
                      active:
                        this.$store.state.dashboard.activeTab ==
                        'pills-mycourse-tab'
                    }"
                    data-toggle="pill"
                    href="#pills-mycourse"
                    role="tab"
                    aria-controls="pills-mycourse"
                    aria-selected="true"
                  >
                    Hosted Courses</a
                  >
                </li>
                <li class="nav-item" v-if="isInstructor == 1">
                  <a
                    class="nav-link"
                    id="pills-added-tab"
                    @click="updateActiveTab('pills-added-tab')"
                    :class="{
                      active:
                        this.$store.state.dashboard.activeTab ==
                        'pills-added-tab'
                    }"
                    data-toggle="pill"
                    href="#pills-added"
                    role="tab"
                    aria-controls="pills-added"
                    aria-selected="true"
                  >
                    Course Revenue</a
                  >
                </li>
                <li class="nav-item" v-if="isInstructor == 1">
                  <a
                    class="nav-link"
                    id="pills-wallet-tab"
                    @click="updateActiveTab('pills-wallet-tab')"
                    :class="{
                      active:
                        this.$store.state.dashboard.activeTab ==
                        'pills-wallet-tab'
                    }"
                    data-toggle="pill"
                    href="#pills-wallet"
                    role="tab"
                    aria-controls="pills-wallet"
                    aria-selected="true"
                    >Wallet</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-about-tab"
                    @click="updateActiveTab('pills-about-tab')"
                    :class="{
                      active:
                        this.$store.state.dashboard.activeTab ==
                        'pills-about-tab'
                    }"
                    data-toggle="pill"
                    href="#pills-about"
                    role="tab"
                    aria-controls="pills-about"
                    aria-selected="true"
                    >About</a
                  >
                </li>
              </ul>
            </div>
            <div class="mobile-nav">
              <button
                class="btn btn-block mobile-nav-button"
                @click="toggleButton"
              >
                Membership
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-9 content-col">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show "
              :class="{
                show:
                  this.$store.state.dashboard.activeTab ==
                  'pills-membership-tab',
                active:
                  this.$store.state.dashboard.activeTab ==
                  'pills-membership-tab'
              }"
              id="pills-membership"
              role="tabpanel"
              aria-labelledby="pills-membership-tab"
            >
              <transition name="appear">
                <MembershipSection
                  :memberShipDetails="userDetails"
                ></MembershipSection>
              </transition>
            </div>
            <div
              class="tab-pane fade"
              id="pills-courses"
              role="tabpanel"
              :class="{
                show:
                  this.$store.state.dashboard.activeTab == 'pills-courses-tab',
                active:
                  this.$store.state.dashboard.activeTab == 'pills-courses-tab'
              }"
              aria-labelledby="pills-courses-tab"
            >
              <CourseSection></CourseSection>
            </div>
            <div
              class="tab-pane fade"
              id="pills-blogs"
              role="blogs"
              :class="{
                show:
                  this.$store.state.dashboard.activeTab == 'pills-blogs-tab',
                active:
                  this.$store.state.dashboard.activeTab == 'pills-blogs-tab'
              }"
              aria-labelledby="pills-blogs-tab"
            >
              <BlogSection></BlogSection>
            </div>
            <div
              class="tab-pane fade"
              id="pills-mycourse"
              :class="{
                show:
                  this.$store.state.dashboard.activeTab == 'pills-mycourse-tab',
                active:
                  this.$store.state.dashboard.activeTab == 'pills-mycourse-tab'
              }"
              role="tabpanel"
              aria-labelledby="pills-mycourse-tab"
            >
              <MyCourseSection></MyCourseSection>
            </div>
            <div
              class="tab-pane fade"
              id="pills-added"
              role="tabpanel"
              :class="{
                show:
                  this.$store.state.dashboard.activeTab == 'pills-added-tab',
                active:
                  this.$store.state.dashboard.activeTab == 'pills-added-tab'
              }"
              aria-labelledby="pills-added-tab"
            >
              <HostedCourseSection></HostedCourseSection>
            </div>
            <div
              class="tab-pane fade"
              id="pills-wallet"
              role="tabpanel"
              :class="{
                show:
                  this.$store.state.dashboard.activeTab == 'pills-wallet-tab',
                active:
                  this.$store.state.dashboard.activeTab == 'pills-wallet-tab'
              }"
              aria-labelledby="pills-wallet-tab"
            >
              <WalletSection :userDetails="userDetails"></WalletSection>
            </div>
            <div
              class="tab-pane fade "
              id="pills-about"
              role="tabpanel"
              :class="{
                show:
                  this.$store.state.dashboard.activeTab == 'pills-about-tab',
                active:
                  this.$store.state.dashboard.activeTab == 'pills-about-tab'
              }"
              aria-labelledby="pills-about-tab"
            >
              <AboutSection :profileDetails="userDetails"></AboutSection>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MembershipSection from "@/components/Profile/MembershipSection.vue";
import CourseSection from "@/components/Profile/CourseSection.vue";
import HostedCourseSection from "@/components/Profile/HostedCourseSection.vue";
import WalletSection from "@/components/Profile/WalletSection.vue";
import AboutSection from "@/components/Profile/AboutSection.vue";
import BlogSection from "@/components/Profile/BlogSection.vue";
import AuthService from "@/services/AuthService";
import MyCourseSection from "@/components/Profile/MyCourseSection.vue";
export default {
  name: "ProfilePage",
  components: {
    CourseSection,
    MembershipSection,
    HostedCourseSection,
    WalletSection,
    AboutSection,
    BlogSection,
    MyCourseSection
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  mounted() {
    let user = JSON.parse(this.$store.getters["auth/user"]);
    this.image = user.image;
    this.name = user.name;
    this.userType = user.user_type;
    this.isInstructor = user.is_instructor;
  },
  data: function() {
    return {
      image: "",
      name: "",
      userType: "",
      isInstructor: "",
      userDetails: [],
      isNavActive: false
    };
  },
  created: function() {
    this.getUserDetails();
  },
  methods: {
    async getUserDetails() {
      await AuthService.getUser()
        .then(result => {
          let userData = result.data.user;
          this.userDetails = result.data;
          this.image = userData.image;
          this.name = userData.name;
          this.userType = userData.user_type;
          this.isInstructor = userData.is_instructor;
          this.$store.dispatch("auth/updateUser", JSON.stringify(userData));
          localStorage.setItem("user", JSON.stringify(userData));
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    toggleButton: function() {
      if (this.isNavActive == false) {
        this.isNavActive = true;
      } else {
        this.isNavActive = false;
      }
    },
    updateActiveTab: function(id) {
      this.$store.dispatch("dashboard/updateActiveTab", id);
    }
  }
};
</script>
