<template>
  <div class="row course-list-row" v-if="isLoadingCourseOfTheDay == false">
    <transition name="appear">
      <div
        class=" col-md-12"
        v-for="(course, courseIndex) in courseOfTheDay"
        :key="courseIndex"
      >
        <div class="hero-course-card" @click="courseDetails(course.slug)">
          <h4 class="sect-head">Course of the day</h4>
          <div class="hero-course course-card row no-gutters">
            <div class="image-wrap col-md-6">
              <img
                class="card-img-top"
                :src="course.image"
                alt="Card image cap"
              />
            </div>
            <div class="card-body col-md-6">
              <h6 class="card-title">
                {{ course.title }}
              </h6>
              <div class="author-info">
                <p class="author-name">
                  Created By
                  <router-link
                    :to="{
                      name: 'publicProfile',
                      params: { name: course.name, id: course.user_id }
                    }"
                    >{{ course.name }}</router-link
                  >
                </p>
                <div class="rating" v-if="getRating(course.rating) > 0">
                  <div class="d-flex">
                    <b class="rating-number">{{ getRating(course.rating) }}</b>
                    <div>
                      <i
                        class="fa fa-star fill"
                        v-for="filledIndex in getFloorRating(course.rating)"
                        :key="filledIndex"
                      ></i>
                    </div>
                    <div>
                      <i
                        class="fa fa-star plain"
                        v-for="emptyIndex in getRemainingRating(course.rating)"
                        :key="emptyIndex"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <a href="#" class="btn play-btn">
                <img src="../assets/play-icon.svg" class="svg" alt="" />
              </a>
              <div class="additional-info">
                <div class="d-flex">
                  <p class="watch_stat mr-4">
                    <i class="far fa-eye"></i>
                    {{ totalPurchase }} views
                  </p>
                  <p class="time_stat">
                    <i class="far fa-clock"></i>
                    {{ course.duration }}
                  </p>
                </div>
                <div class="thread-keywords keyword-wrapper">
                  <ul class="list-unstyled">
                    <li>
                      <span class="thread-label">Course Available In</span>
                    </li>
                    <li
                      v-for="(language, languageIndex) in course.languages"
                      :key="languageIndex"
                    >
                      <button class="btn keywordTag">
                        {{ language.language }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center price-box"
              >
                <h5 class="price-tag">
                  <span class="rupee">₹</span>
                  {{
                    course.price -
                      course.price * (course.offer / 100).toFixed(2)
                  }}
                  <span v-if="course.offer > 0">{{ course.offer }} off</span>
                  <del v-if="course.offer > 0"
                    ><span class="rupee">₹</span>120</del
                  >
                </h5>
                <i
                  class="fas fa-bookmark wishlist"
                  @click="addWishList(course.id)"
                  v-if="getWishlistStatus(course.wishlist_status) == 1"
                ></i>
                <i
                  class="far fa-bookmark"
                  v-else
                  @click="addWishList(course.id)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div class="row" v-else>
    <Loader></Loader>
  </div>
</template>
<script>
import Loader from "../components/Loader.vue";
import CourseService from "@/services/CourseService";
export default {
  name: "HeroCourse",
  props: ["courses"],
  components: {
    Loader
  },
  watch: {
    courses(newValue) {
      this.courseOfTheDay = newValue.course.data;
      this.totalPurchase = newValue.total_purchases;
    }
  },
  data: function() {
    return {
      isLoadingCourseOfTheDay: false,
      wishlistStatus: 0,
      courseOfTheDay: [],
      totalPurchase: 0
    };
  },
  methods: {
    courseDetails: function(slug) {
      this.$router
        .push({ name: "courseDetails", params: { slug: slug } })
        .catch(() => {});
    },
    getFloorRating: function(rating) {
      return Math.floor(rating);
    },
    getRemainingRating: function(rating) {
      let currentRating = Math.floor(rating);
      let remainingRating = 5 - currentRating;
      return remainingRating;
    },
    getRating: function(rating) {
      return parseFloat(rating, 2).toFixed(1);
    },
    addWishList: function(id) {
      if (this.wishlistStatus == 1) {
        this.wishlistStatus = 0;
      } else {
        this.wishlistStatus = 1;
      }
      CourseService.addWishList({
        course_id: id
      })
        .then(result => {
          console.log(result);
        })
        .catch(error => {
          console.log(error);
        });
    },
    getWishlistStatus: function(id) {
      if (id == 1) {
        this.wishlistStatus = 1;
        return 1;
      } else {
        this.wishlistStatus = 0;
        return 0;
      }
    }
  }
};
</script>
<style scoped>
.empty-course {
  text-align: center !important;
  width: 100%;
  color: #644fa1;
  font-size: 17px;
}
.row.featured-loader {
  height: 160px;
}
i.fas.fa-bookmark.wishlist {
  color: #644fa1;
}
</style>
