<template>
  <router-link
    :to="{ name: 'questions', params: { moduleId: exam.section_id } }"
    class="cardblock-link"
  >
    <div class="card-block row no-gutters">
      <div class="col-md-7 d-flex">
        <div class="card-thumbnail-block">
          <img src="../assets/question-thumb.png" alt="" class="img-fluid" />
          <!-- <img src="../assets/video-thumb.jpg" alt="" class="img-fluid"/> -->
        </div>
        <div class="card-block__body">
          <h4>{{ exam.title }}</h4>
          <p>Language: {{ exam.language }}</p>
        </div>
      </div>
      <div
        class="col-md-5 justify-content-end align-items-center d-flex card-block__icon-col"
      >
        <button to="" class=" btn delete-btn" v-if="isPublished == false">
          <img src="../assets/delete-icon.svg" alt="" />
        </button>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ExamCard",
  props: ["exam", "isPublished"]
};
</script>

<style scoped></style>
