<template>
  <div class="col-md-4">
    <router-link
      style="text-decoration:none;"
      :to="{ name: 'webinarDetails', params: { slug: webinar.slug } }"
    >
      <div class="webinar-card">
        <div class="webinar-image-wrapper">
          <img :src="webinar.image" class="img-fluid" alt="" />
        </div>
        <div class="webinar-body">
          <h5>{{ webinar.title }}</h5>
          <!-- <p>{{ webinar.meta_description }}</p> -->
          <p class="info">
            <i class="fas fa-calendar-week"></i> {{ webinar.date }}
          </p>
          <p class="info">
            <i class="fas fa-clock"></i>
            {{ webinar.from }} - {{ webinar.to }}
          </p>
          <p class="info edit" v-if="webinar.location != null">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            {{ webinar.location }}
          </p>
          <p class="info" v-else>
            <i class="fa fa-laptop" aria-hidden="true"></i>
            Online Event
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "WebinarCard",
  props: ["webinar"]
};
</script>

<style scoped></style>
