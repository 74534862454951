<template>
  <div class="questionnaire">
    <div class="container text-right mb-5">
      <a @click="$router.go(-1)" class="btn cta-clear">
        Back to modules
      </a>
    </div>
    <div class="container questionnaire-wrap">
      <div class="questionnaire__header">
        <h5 class="color-1">Article</h5>
      </div>
      <div class="questionnaire__form">
        <form @submit.prevent="uploadFiles">
          <div class="form-row">
            <div class="col-md-12 form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Title"
                v-model="title"
              />
            </div>
            <div class="col-md-12 form-group">
              <ckeditor
                :editor="editor"
                v-model="article"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="col-md-12 form-group">
              <div class="upload-hijack">
                <button for="" class="btn Upload-btn">
                  Choose File
                  <img src="../assets/upload-cloud.svg" alt="" />
                  <input type="file" multiple @change="selectFile" />
                </button>
                <p v-if="selectedFiles != undefined && selectedFiles != 0">
                  {{ selectedFiles.length }} files selected
                </p>
                <div v-if="error.fileTypeError" class="error-message">
                  {{ error.fileTypeError }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div v-if="progressInfos">
              <div
                class="mb-2"
                v-for="(progressInfo, index) in progressInfos"
                :key="index"
              >
                <span>{{ progressInfo.fileName }}</span>
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-info"
                    role="progressbar"
                    :aria-valuenow="progressInfo.percentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progressInfo.percentage + '%' }"
                  >
                    {{ progressInfo.percentage }}%
                  </div>
                </div>
              </div>
            </div>
            <div v-if="message" role="alert">
              <ul></ul>
            </div>
          </div>
          <div class="text-right mb-4">
            <button
              type="submit"
              :disabled="this.isDisabledButton == true"
              class="btn cta-primary"
            >
              Save
            </button>
          </div>
        </form>
        <div class="row">
          <div
            class="col-md-2 mt-2"
            v-for="(file, fileIndex) in files"
            :key="fileIndex"
          >
            <img src="../assets/pdf.png" alt="" width="50" class="img-fluid" />
            {{ file.original_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ModuleService from "@/services/ModuleService";
import UploadAdapter from "@/adaptors/UploadAdapter";
export default {
  name: "Article",
  components: {
    ckeditor: CKEditor.component
  },
  created() {
    this.getArticle();
  },
  mounted() {
    this.getArticle();
  },
  data() {
    return {
      editor: ClassicEditor,
      article: "",
      editorConfig: {
        height: 1500,
        extraPlugins: [this.uploader]
      },
      isDisabledButton: false,
      selectedFiles: undefined,
      progressInfos: [],
      message: "",
      files: [],
      title: "",
      fileUploadCounter: 0,
      error: {
        fileTypeError: ""
      }
    };
  },
  methods: {
    selectFile() {
      this.progressInfos = [];
      this.selectedFiles = event.target.files;
      this.error.fileTypeError = "";
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i]["type"] !== "application/pdf") {
          this.error.fileTypeError = "Please upload pdf files only";
        }
      }
    },
    uploadFiles() {
      this.message = "";
      const data = {
        title: this.title,
        article: this.article,
        id: this.$route.params.articleId
      };
      if (this.error.fileTypeError == "") {
        this.createArticle(data);
        for (let i = 0; i < this.selectedFiles.length; i++) {
          this.upload(i, this.selectedFiles[i]);
        }
      }
    },
    upload(idx, file) {
      this.progressInfos[idx] = { percentage: 0, fileName: file.name };
      let articleId = this.$route.params.articleId;
      ModuleService.upload(file, articleId, event => {
        this.progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        if (this.progressInfos[idx].percentage == 100) {
          this.fileUploadCounter++;
        }
        if (this.selectedFiles.length == this.fileUploadCounter) {
          this.uploadComplete();
        } else {
          this.isDisabledButton = true;
        }
      })
        .then(response => {
          this.message = idx + response.data.message;
        })
        .catch(() => {
          this.progressInfos[idx].percentage = 0;
          this.message = "Could not upload the file:" + file.name;
        });
    },
    async createArticle(data) {
      if (this.language == "") {
        this.errors = { language: "Please select article language" };
      } else {
        this.isDisabledButton = true;
        await ModuleService.createArticle(data)
          .then(result => {
            this.isDisabledButton = false;
            if (result.data.status == "success") {
              this.$toastr.s("Article updated successfully");
            }
          })
          .catch(error => {
            this.isDisabledButton = false;
            this.error = error;
            // this.$toastr.e("Something went wrong");
          });
      }
    },
    async getArticle() {
      await ModuleService.getArticle({
        article_id: this.$route.params.articleId
      })
        .then(result => {
          this.title = result.data.title;
          this.article = result.data.article;
          this.files = result.data.files;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    uploadComplete: function() {
      this.isDisabledButton = false;
      this.selectedFiles = "";
      this.$toastr.s("File upload completed");
      this.progressInfos = [];
      this.fileUploadCounter = 0;
      setTimeout(() => {
        this.getArticle();
      }, 1000);
    },
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new UploadAdapter(loader);
      };
    }
  }
};
</script>
<style scoped>
.ck-editor .ck-editor__main .ck-content {
  min-height: 1500px;
}
</style>
