<template>
  <div class="blog-wrapper">
    <div class="d-flex align-items-center mb-3">
      <div>
        <h5 class="hero-head">Blogs</h5>
      </div>
      <p class="ml-auto mr-3">
        Add new
        <router-link :to="{ name: 'createBlog' }" class="btn cta-add">
          <img src="../../assets/plus-light.svg" width="15px" alt="" />
        </router-link>
      </p>
    </div>
    <div class="row" v-if="blogs.length > 0">
      <div class="col-md-4" v-for="(blog, blogIndex) in blogs" :key="blogIndex">
        <HostedBlogCard :blog="blog"></HostedBlogCard>
      </div>
    </div>
    <div v-else>
      <div class="not-found-wrapper" v-if="blogs.length == 0">
        <img src="@/assets/not-found.svg" alt="not-found" />
        <h4>Not yet created a blog!</h4>
        <router-link :to="{ name: 'createBlog' }" class="btn cta-primary">
          Create a blog
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HostedBlogCard from "@/components/Profile/HostedBlogCard.vue";
import BlogService from "@/services/BlogService";
export default {
  name: "BlogSection",
  components: {
    HostedBlogCard
  },
  created: function() {
    this.getMyBlogs();
  },
  data: function() {
    return {
      blogs: [],
      blogLoader: true
    };
  },
  methods: {
    async getMyBlogs() {
      await BlogService.getMyBlogs()
        .then(result => {
          this.blogs = result.data;
          this.blogLoader = false;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>
