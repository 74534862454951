<template>
  <div class="category-tab-wrapper">
    <div class="d-flex mb-3 align-items-center">
      <h5 class="hero-head">Hosted Courses</h5>
      <p class="ml-auto mr-3" v-if="hostedCourse.length > 0">
        Host a course
        <router-link :to="{ name: 'createCourse' }" class="btn cta-add">
          <img src="../../assets/plus-light.svg" width="15px" alt="" />
        </router-link>
      </p>
    </div>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-saved"
        role="tabpanel"
        aria-labelledby="pills-saved-tab"
      >
        <div class="all-category-wrapper">
          <div class="row" v-if="hostedCourseLoader == true">
            <Loader></Loader>
          </div>
          <div class="row">
            <div
              class="col-md-4"
              v-for="(course, courseIndex) in hostedCourse"
              :key="courseIndex"
            >
              <HostedCourseCard :course="course"></HostedCourseCard>
            </div>
          </div>
          <div
            class="not-found-wrapper"
            v-if="hostedCourse.length == 0 && hostedCourseLoader == false"
          >
            <img src="@/assets/not-found.svg" alt="not-found" />
            <h4>Not yet created a course!</h4>
            <router-link :to="{ name: 'createCourse' }" class="btn cta-primary">
              Create a Course
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseService from "@/services/CourseService";
import Loader from "@/components/Loader.vue";
import HostedCourseCard from "@/components/HostedCourseCard.vue";
export default {
  name: "MyCourseSection",
  components: {
    Loader,
    HostedCourseCard
  },
  data: function() {
    return {
      hostedCourse: [],
      hostedCourseLoader: true
    };
  },
  created() {
    this.getHostedCourse();
  },
  methods: {
    async getHostedCourse() {
      await CourseService.getHostedCourse()
        .then(result => {
          this.hostedCourse = result.data.data;
          this.hostedCourseLoader = false;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>

<style scoped></style>
