<template>
  <div>
    <div class="module-card">
      <div class="module-card-header d-flex justify-content-between">
        <h5 class="module-number">{{ module.title }}</h5>
        <h6><span>Type : </span> {{ module.type }}</h6>
      </div>
      <div class="module-card-body">
        <div
          class="placeholder video"
          v-if="
            module.section_type_id == 1 && availableVideoLanguages.length > 0
          "
        >
          <h6>UPLOAD VIDEOS</h6>
          <p>
            You can upload {{ availableVideoLanguages.length }} more videos in
            {{ availableVideoLanguages.length }} languages
          </p>
        </div>
        <div v-if="module.section_type_id == 1">
          <VideoCard
            v-for="(video, videoIndex) in module.videos"
            :key="videoIndex"
            :video="video"
            :progress="progress"
            :videoId="videoId"
            :isPublished="isPublished"
            @reloadModules="reloadModules"
          ></VideoCard>
        </div>
        <div v-if="module.section_type_id == 2">
          <ExamCard
            v-for="(exam, examIndex) in module.exams"
            :key="examIndex"
            :exam="exam"
            :isPublished="isPublished"
          ></ExamCard>
        </div>
        <div v-if="module.section_type_id == 3">
          <ArticleCard
            v-for="(article, articleIndex) in module.articles"
            :key="articleIndex"
            :article="article"
            :isPublished="isPublished"
            @reloadModules="reloadModules"
          ></ArticleCard>
          <p v-if="module.articles == 0">
            Select a language and click Create Article Button
          </p>
        </div>
      </div>
      <div
        class="module-card-footer row no-gutters justify-content-between align-items-center"
        v-if="isPublished == false"
      >
        <div class="col-md-6">
          <div
            class="upload-hijack"
            v-if="
              module.section_type_id == 1 && availableVideoLanguages.length > 0
            "
          >
            <button for="" class="btn Upload-btn">
              Choose File
              <img src="../assets/upload-cloud.svg" alt="" />
              <input
                type="file"
                id="fileUpload"
                ref="videoFile"
                :disabled="this.$store.state.upload.isUploading == true"
                @change="selectVideoFile"
              />
            </button>
          </div>
          <div v-if="errors.video && errors.video" class="error-message">
            {{ errors.video }}
          </div>
        </div>
        <div class="col-md-3">
          <div class="language-select d-flex align-items-center">
            <multiselect
              v-if="
                module.section_type_id == 1 &&
                  availableVideoLanguages.length > 0
              "
              v-model="language"
              :options="availableVideoLanguages"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              :id="module.id"
              label="language"
              placeholder="language"
              @open="loadAvailableVideoLanguage"
            >
              <span slot="noOptions">Loading ...</span>
            </multiselect>
            <multiselect
              v-if="
                module.section_type_id == 2 &&
                  availableExamLanguages.length > module.exams.length
              "
              v-model="language"
              :options="availableExamLanguages"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              :id="module.id"
              label="language"
              placeholder="language"
              @open="loadAvailableExamLanguage"
            >
              <span slot="noOptions">Loading ...</span>
            </multiselect>
            <multiselect
              v-if="
                module.section_type_id == 3 &&
                  availableArticleLanguages.length > 0
              "
              v-model="language"
              :options="availableArticleLanguages"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              :id="module.id"
              label="language"
              placeholder="language"
              @open="getAvailableArticleLanguages(module.id)"
            >
              <span slot="noOptions">Loading ...</span>
            </multiselect>
          </div>
          <div v-if="errors.language && errors.language" class="error-message">
            {{ errors.language }}
          </div>
        </div>
        <div class="col-md-3 text-right">
          <div class="upload-hijack">
            <button
              v-if="
                module.section_type_id == 1 &&
                  availableVideoLanguages.length > 0
              "
              class="btn cta-primary"
              :disabled="this.$store.state.upload.isUploading == true"
              @click="startUpload"
            >
              Upload
            </button>
            <button
              v-if="
                module.section_type_id == 2 &&
                  availableExamLanguages.length > module.exams.length
              "
              class="btn cta-primary"
              :disabled="this.$store.state.upload.isUploading == true"
              @click="createExam"
            >
              Create exam
            </button>
            <button
              v-if="
                module.section_type_id == 3 &&
                  availableArticleLanguages.length > 0
              "
              class="btn cta-primary"
              :disabled="this.$store.state.upload.isUploading == true"
              @click="createArticle"
            >
              Create Article
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoCard from "@/components/VideoCard.vue";
import ExamCard from "@/components/ExamCard.vue";
import Multiselect from "vue-multiselect";
import ModuleService from "@/services/ModuleService";
import VimeoService from "@/services/VimeoService";
import ArticleCard from "@/components/ArticleCard.vue";

var tus = require("tus-js-client");

export default {
  name: "ModuleCard",
  components: {
    VideoCard,
    Multiselect,
    ExamCard,
    ArticleCard
  },
  props: ["module", "isPublished"],
  data() {
    return {
      language: "",
      availableVideoLanguages: [],
      videoFile: "",
      uploadUrl: "",
      errors: [],
      progress: 0,
      vimeoVideoId: "",
      videoId: "",
      availableExamLanguages: [],
      availableArticleLanguages: []
    };
  },
  mounted() {
    if (this.module.section_type_id == 1) {
      this.getAvailableVideoLanguages(this.module.id);
    } else if (this.module.section_type_id == 2) {
      this.getAvailableExamLanguages(this.module.id);
    } else if (this.module.section_type_id == 3) {
      this.getAvailableArticleLanguages(this.module.id);
    }
  },
  methods: {
    async getAvailableVideoLanguages(moduleId) {
      await ModuleService.getAvailableVideoLanguages({ section_id: moduleId })
        .then(result => {
          this.availableVideoLanguages = result.data.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    selectVideoFile: function() {
      this.videoFile = this.$refs.videoFile.files[0];
    },
    startUpload: function() {
      this.errors = "";
      if (this.videoFile == "") {
        this.errors = { video: "Please select a video" };
      } else if (this.language == "") {
        this.errors = { language: "Please select video language" };
      } else {
        this.$toastr.s("Please wait, Getting ready");
        this.$store.dispatch("upload/uploadingStatus", true);
        this.getUploadUrl();
      }
    },
    getUploadUrl: function() {
      let body = {
        upload: {
          approach: "tus",
          size: this.videoFile.size
        },
        name: this.videoFile.name,
        privacy: {
          view: "disable",
          embed: "public",
          download: false
        },
        embed: {
          buttons: {
            like: false,
            share: false,
            embed: false,
            watchlater: false
          },
          color: "#644fa1",
          logos: {
            vimeo: false,
            custom: {
              sticky: false
            }
          },
          title: {
            name: "hide",
            owner: "hide",
            portrait: "hide"
          }
        }
      };
      VimeoService.getUploadUrl(body)
        .then(result => {
          this.uploadUrl = result.data.upload.upload_link;
          this.vimeoVideoId = this.getIdFromVimeoURL(result.data.link);
          this.createVideo();
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
          this.$store.dispatch("upload/uploadingStatus", false);
        });
    },
    getIdFromVimeoURL(url) {
      return /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3];
    },
    async createVideo() {
      let data = {
        title: this.videoFile.name,
        language_id: this.language.id,
        section_id: this.module.id,
        video: this.vimeoVideoId,
        uploading_status: "uploading"
      };
      await ModuleService.saveVideo(data)
        .then(result => {
          if (result.data.status == "success") {
            this.$parent.getModules();
            this.getAvailableVideoLanguages(this.module.id);
            this.language = "";
            this.videoId = result.data.videoId;
            this.uploadVideo();
          } else {
            this.deleteVimeoVideo();
            this.$toastr.e("Something went wrong");
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
          this.$store.dispatch("upload/uploadingStatus", false);
        });
    },
    uploadVideo: function() {
      var upload = new tus.Upload(this.videoFile, {
        uploadUrl: this.uploadUrl,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: this.videoFile.name,
          filetype: this.videoFile.type
        },
        onError: () => {
          this.updateVideo("error");
          this.$toastr.e("failed to upload file please try again");
          this.$store.dispatch("upload/uploadingStatus", false);
          document.querySelector("#fileUpload").value = "";
          this.videoFile = "";
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          this.progress = percentage;
        },
        onSuccess: () => {
          this.$toastr.s("File uploaded successfully");
          this.updateVideo("success");
          this.$store.dispatch("upload/uploadingStatus", false);
          document.querySelector("#fileUpload").value = "";
          this.videoFile = "";
          // this.getThumbnail();
        }
      });
      upload.start();
    },
    async updateVideo(status) {
      let data = {
        title: this.videoFile.name,
        section_id: this.module.id,
        video: this.vimeoVideoId,
        uploading_status: status,
        id: this.videoId
      };
      await ModuleService.saveVideo(data)
        .then(result => {
          console.log(result);
          this.$parent.getModules();
          this.videoId = "";
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    loadAvailableVideoLanguage() {
      this.getAvailableVideoLanguages(this.module.id);
    },
    loadAvailableExamLanguage() {
      this.getAvailableExamLanguages(this.module.id);
    },
    reloadModules() {
      this.getAvailableVideoLanguages(this.module.id);
      this.getAvailableArticleLanguages(this.module.id);
      this.$parent.getModules();
    },
    deleteVimeoVideo: function() {
      VimeoService.deleteVideo(this.vimeoVideoId);
    },
    async createExam() {
      if (this.language == "") {
        this.errors = { language: "Please select exam language" };
      } else {
        const data = {
          section_id: this.module.id,
          language_id: this.language.id
        };
        await ModuleService.createExam(data)
          .then(result => {
            if (result.data.status == "success") {
              this.$parent.getModules();
              this.$toastr.s("Exam created successfully");
              this.language = "";
              this.getAvailableExamLanguages(this.module.id);
              this.$router.push({
                name: "questions",
                params: { moduleId: this.module.id }
              });
            }
          })
          .catch(error => {
            this.error = error;
            // this.$toastr.e("Something went wrong");
          });
      }
    },
    async getAvailableExamLanguages(moduleId) {
      await ModuleService.getAvailableExamLanguages({ section_id: moduleId })
        .then(result => {
          this.availableExamLanguages = result.data.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getAvailableArticleLanguages(moduleId) {
      await ModuleService.getAvailableArticleLanguages({ section_id: moduleId })
        .then(result => {
          this.availableArticleLanguages = result.data.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async createArticle() {
      if (this.language == "") {
        this.errors = { language: "Please select article language" };
      } else {
        const data = {
          section_id: this.module.id,
          language_id: this.language.id
        };
        await ModuleService.createArticle(data)
          .then(result => {
            if (result.data.status == "success") {
              this.$parent.getModules();
              this.$toastr.s("Article created successfully");
              this.language = "";
              this.getAvailableArticleLanguages(this.module.id);
              this.$router.push({
                name: "createArticle",
                params: { articleId: result.data.id }
              });
            }
          })
          .catch(error => {
            this.error = error;
            // this.$toastr.e("Something went wrong");
          });
      }
    }
  }
};
</script>

<style scoped></style>
