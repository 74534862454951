<template>
  <div class="story">
    <div class="block-content">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-md-6">
            <div class="hero-img-wrap">
              <img class="w-100" src="../assets/founder-img.png" alt="" />
            </div>
            <div class="solid-block">
              <h5>
                ‘Despite where we come from, we are all connected; our stories
                (of women) are similar and universal in appeal—of wanting to
                chase our dreams, and explore the world but bound by societal
                conditioning, fear, doubts, and yet hopeful…’ she remarks.
              </h5>
            </div>
            <div class="vision-block">
              <div class="custom-head">
                <h2>
                  Vision
                </h2>
              </div>
              <h4>
                To create a world where every women is empowered, thereby making
                it an infinitely better place.
              </h4>
            </div>
          </div>
          <div class="col-md-6">
            <div class="hero-text-content">
              <h2>
                Meet<br />
                Mrs.Geethanjali Sasikumar, <br />
                our story begins with her.
              </h2>
            </div>
            <div class="clear-block">
              <div class="custom-head">
                <h2>
                  Our Founder
                </h2>
              </div>
              <p>
                Perception is not reality - it's a lesson Mrs.Geethanjlai
                Sasikumar learnt early on.
              </p>
              <p>
                Born into an affluent family, it was assumed that she always had
                an easy life—devoid of turbulent times. ’While money can make
                life comfortable, it doesn’t guarantee happiness, or the freedom
                to live life on one’s own terms,’ she states.
              </p>
              <p>
                Despite the fervent curiosity to explore her untapped potential
                and all the privileges her background afforded, she realised her
                cherished dreams would remain just that. And only because she
                was a girl. It was frustrating that a system entrenched in
                patriarchal values could do that.
              </p>
              <p>
                Marriage was a game changer. And How!
              </p>
              <p>
                Encouraged by a supportive husband, she embarked on the most
                adventurous phase of her life yet. Managing the Velammal Group
                of Schools was only the start. Exploring the world through
                travel and interactions with people from all over blew her mind
                and lit her up! This experience was what she had dreamed of as a
                little girl.
              </p>
            </div>
            <div class="pink-block">
              <p>
                Driven by a passionate sense of purpose, she envisioned the idea
                of a safe community where women (and girls) across diverse
                backgrounds can connect, learn, and support one another through
                their shared experiences. <br />
                <br />

                In 2019, OlogyWomen was launched with the vision of creating a
                world where every woman is empowered. Empowering one another by
                celebrating sisterhood and fostering learning, connections and
                growth is the core philosophy of OlogyWomen (and OlogyGirls).
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="team-wrap">
        <div class="custom-head">
          <h2>
            Our Team
          </h2>
        </div>
        <p>
          Meet the dynamic and vibrant team that work towards making this
          community a fun, warm and engaging space.
        </p>
        <div class="card-row">
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/geethanjali.jpg" alt="" />
              <h4>Geethanjali Sasikumar</h4>
            </div>
            <div class="_card-body">
              <p>
                The brain behind OlogyWomen, Geethanjali envisions this as a
                space to empower women by fostering connections, learning and
                growth. Quiet, unassuming and kind, she leads by example on what
                it means to nurture your dreams and being committed to pursuing
                them.
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-2.jpg" alt="" />
              <h4>Indu Divya</h4>
            </div>
            <div class="_card-body">
              <p>
                Leading the operations of this community that includes
                establishing its presence and curating events and programs among
                others, Indu is a voice of reason and encouragement. A natural
                storyteller with a keen eye for detail, she always manages to
                find the right words to bring out the best in every team member.
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-3.jpg" alt="" />
              <h4>Stephanie Tsiros</h4>
            </div>
            <div class="_card-body">
              <p>
                Spearheading our global communications, Stephanie effortlessly
                straddles timezones (she’s currently based in Melbourne) to
                ensure we have a global audience engaging with the OlogyWomen
                community. Her mixed lineage and Australian accent helps in
                bringing a fair share of cultural diversity and foreign flavour
                to the team!
              </p>
            </div>
          </div>

           <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-4.jpg" alt="" />
              <h4>Suparna Thantry</h4>
            </div>
            <div class="_card-body">
              <p>
                Want a full blown update on the latest trends in fashion? Or
                tips on navigating the Gram world? Ask Suparna. Besides taking
                care of our social media, she wows us with her energy, smoothly
                & creatively balancing the myriad roles she takes on —
                professional and personal.
              </p>
            </div>
          </div> 

          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-5.jpg" alt="" />
              <h4>Rajeswari Manickaraj</h4>
            </div>
            <div class="_card-body">
              <p>
                Raji, an integral part of our content team, has a methodical
                approach to work. Her keen curiosity reflects in the way she
                explores topics and ideas - be it at team meetings, her blogs or
                on the Insta LIVE sessions she hosts!
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-6.jpg" alt="" />
              <h4>Aparna Padmanabhan</h4>
            </div>
            <div class="_card-body">
              <p>
                Besides writing personal and engaging blogs, Aparna enjoys
                picking the brains of the Guests on our Insta LIVE programs. And
                her straight faced, sharp sense of humour and warm
                acknowledgements makes her delightful company to be in.
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-7.jpg" alt="" />
              <h4>Shanmugavadivu Thiyagarajan</h4>
            </div>
            <div class="_card-body">
              <p>
                Vadivu, also on our content team, is mostly low-profile,
                god-fearing and always up for a challenge. But come
                brainstorming sessions and we get to see her light up with a
                childlike enthusiasm in exploring ideas!
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-8.jpg" alt="" />
              <h4>Deepika Ashokkumar</h4>
            </div>
            <div class="_card-body">
              <p>
                The youngest in the team, Deepika is Coordinator of OlogyGirls.
                She’s quite the eager beaver—excited to share ideas and enthused
                to work on them with a sense of urgency. Luckily, she also has a
                balanced head on her shoulders:)!
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-9.jpg" alt="" />
              <h4>Vanitha C</h4>
            </div>
            <div class="_card-body">
              <p>
                Yet another Coordinator of OlogyGirls, Vanitha is a pro at
                making her job look effortless. Her knack for connecting with
                adults and young girls apart, she’s quick to help, keen to learn
                and always wears a smile.
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-10.jpg" alt="" />
              <h4>Sanjana Keerthi Janakiraman</h4>
            </div>
            <div class="_card-body">
              <p>
                A passionate blogger, this smart, young lady oversees our Blog
                section. Apart from churning interesting blogs, reviewing blog
                submissions and ensuring our Blog section is thriving, Sanjana
                displays a certain maturity that is hard to miss when you engage
                with her.
              </p>
            </div>
          </div>
          <div class="team-card">
            <div class="_card-img">
              <img class="w-100" src="../assets/team-11.jpg" alt="" />
              <h4>Srinath MS</h4>
            </div>
            <div class="_card-body">
              <p>
                Technical support aside, Srinath is our in-house go-to-person
                for a male perspective on topics, when required. Family,
                photography and entrepreneurship—things that get him excited,
                though maybe not in the said order.
              </p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Story"
};
</script>
