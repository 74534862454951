const testimonial = {
  namespaced: true,
  state: {
    isLoadingTestimonial: true
  },
  mutations: {
    UPDATE_TESTIMONIAL_LOADING: (state, loading) => {
      state.isLoadingTestimonial = loading;
    }
  },
  actions: {
    updateTestimonialLoader: ({ commit }, status) => {
      commit("UPDATE_TESTIMONIAL_LOADING", status);
    }
  }
};
export default testimonial;
