<template>
  <div class="blog-listing container webinar-listing event-gallery-list">
    <div class="row result-wrapper">
      <div class="col-md-12">
        <div class="blog-list-wrap mt-0">
          <div
            class="head-title d-flex justify-content-between align-items-center"
          >
            <h5 class="color-1">Gallery</h5>
          </div>
          <div class="blog-list-row row" v-if="loader == true">
            <loader></loader>
          </div>
          <div class="row" v-else>
            <div
              class="col-md-4 col-xl-3"
              v-for="(event, eventIndex) in events"
              :key="eventIndex"
            >
              <router-link
                style="text-decoration:none;"
                :to="{ name: 'EventAlbumDetails', params: { id: event.id } }"
              >
                <div class="webinar-card">
                  <div class="webinar-image-wrapper">
                    <img :src="event.image" class="img-fluid" alt="" />
                  </div>
                  <div class="webinar-body">
                    <h5>{{ event.title }}</h5>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
          <div
            class="not-found-wrapper"
            v-if="events.length == 0 && loader == false"
          >
            <img src="@/assets/not-found.svg" alt="not-found" />
            <h4>No Event Found !</h4>
          </div>
          <div class="pagination-wrap">
            <SlidingPagination
              :current="currentPage"
              :total="totalPages"
              @page-change="pageChangeHandler"
              v-if="loader == false && totalPages > 1"
            >
            </SlidingPagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import EventAlbumService from "@/services/EventAlbumService";
import SlidingPagination from "vue-sliding-pagination";
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
export default {
  name: "EventAlbums",
  components: {
    Loader,
    SlidingPagination
  },
  data() {
    return {
      loader: true,
      events: [],
      totalResult: 0,
      resultFrom: 0,
      resultTo: 0,
      currentPage: 0,
      totalPages: 0
    };
  },
  created() {
    this.getEventAlbums(0);
  },
  methods: {
    async getEventAlbums(page) {
      await EventAlbumService.getEventAlbums(page).then(output => {
        this.loader = false;
        if (output.data.status == "success") {
          this.events = output.data.events.data;
          this.totalPages = output.data.events.last_page;
          this.currentPage = output.data.events.current_page;
          this.totalResult = output.data.events.total;
          this.resultFrom = output.data.events.from;
          this.resultTo = output.data.events.to;
        }
      });
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      this.getEventAlbums(this.currentPage);
      window.scrollTo(0, 0);
    }
  }
};
</script>
