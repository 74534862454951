<template>
  <div>
    <div class="card hosted-course__card">
      <div class="image-wrap">
        <img class="card-img-top" :src="course.image" alt="Card image cap" />
      </div>
      <div v-html="status"></div>
      <div class="course-stat d-flex justify-content-between">
        <p>{{ course.duration }}</p>
      </div>
      <div class="card-body d-flex justify-content-between">
        <div>
          <h6 class="card-title">{{ course.title }}</h6>
          <div class="price-tag d-flex">
            <span class="rupee">₹</span>
            <h5>{{ course.price }}</h5>
          </div>
        </div>
        <div class="card-action-div dropleft dropdown">
          <button
            class="btn cta-clear micro-cta"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="../assets/dots-icon.svg" alt="" />
          </button>
          <div class="dropdown-menu">
            <router-link
              class="dropdown-item"
              :to="{ name: 'createCourse', params: { id: course.id } }"
            >
              Edit
            </router-link>
            <router-link
              class="dropdown-item"
              :to="{ name: 'coursePreview', params: { slug: course.slug } }"
            >
              Preview
            </router-link>
            <!-- <a class="dropdown-item" href="#">Delete</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HostedCourseCard",
  props: ["course"],
  computed: {
    status() {
      if (this.course.status == 1)
        return "<span class='status-badge green'>Active</span>";
      else if (this.course.status == 2)
        return "<span class='status-badge yellow'>Draft</span>";
      else if (this.course.status == 3)
        return "<span class='status-badge green'>Published</span>";
      else if (this.course.status == 4)
        return "<span class='status-badge green'>Approved</span>";
      else if (this.course.status == 5)
        return "<span class='status-badge red'>Rejected</span>";
      else if (this.course.status == 6)
        return "<span class='status-badge red'>Blocked</span>";
      else return "<span class='status-badge red'>Blocked</span>";
    }
  }
};
</script>
