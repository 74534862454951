<template>
  <div class="PaymentStatus">
    <loader v-if="loader == true"></loader>
    <div class="container status-wrapper" v-else>
      <div class="image-wrap success-img" v-if="status == 'success'">
        <img src="../assets/success-clipimg.svg" alt="" />
      </div>
      <div class="image-wrap failed-img" v-else>
        <img src="../assets/failed-clipimg.svg" alt="" />
      </div>
      <div class="message-box text-center py-4" v-if="status == 'success'">
        <h6>Transaction Successful.</h6>
        <p v-if="source == 'course'">
          Your order of<a
            href="#"
            @click.self="
              $router.push({
                name: 'courseDetails',
                params: { slug: payment.slug }
              })
            "
            >{{ payment.course_name }}</a
          >
          Transaction Id: <a href="#"> {{ payment.transaction_id }}</a> was
          successfully processed , A copy of invoice has been sent to registered
          mail
        </p>
        <p v-if="source == 'membership'">
          Your membership request with Transaction Id:
          <a href="#"> {{ payment.transaction_id }}</a> was successfully
          processed , A copy of invoice has been sent to registered mail
        </p>
      </div>
      <div class="message-box text-center py-4" v-else>
        <h6>Page Not found</h6>
      </div>
      <div class="text-center" v-if="status == 'success'">
        <button
          class="btn cta-clear"
          v-if="source == 'course'"
          @click="
            $router.push({
              name: 'courseDetails',
              params: { slug: payment.slug }
            })
          "
        >
          View course
        </button>
        <button class="btn cta-primary">Invoice</button>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
import Loader from "../components/Loader.vue";

export default {
  components: { Loader },
  name: "PaymentStatus",
  created: function() {
    this.source = this.$route.query.source;
    this.id = this.$route.params.id;
    this.getData();
  },
  data: function() {
    return {
      source: "",
      id: "",
      payment: [],
      status: "success",
      loader: true
    };
  },
  methods: {
    async getData() {
      const data = {
        source: this.source,
        id: this.id
      };
      await PaymentService.getPaymentDetails(data)
        .then(result => {
          this.loader = false;
          if (result.data.status == "success") {
            this.payment = result.data.data;
            this.status = "success";
          } else {
            this.status = "error";
          }
        })
        .catch(error => {
          this.loader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>
