const dashboard = {
  namespaced: true,
  state: {
    activeTab: "pills-membership-tab"
  },
  mutations: {
    UPDATE_ACTIVE_TAB: (state, id) => {
      state.activeTab = id;
    }
  },
  actions: {
    updateActiveTab: ({ commit }, id) => {
      commit("UPDATE_ACTIVE_TAB", id);
    }
  }
};
export default dashboard;
