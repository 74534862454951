<template>
  <div class="membership-outer">
    <div class="container-fluid membership-header">
      <div class="container">
        <div class="__headline">
          <h5 class="color-1">Membership Plans</h5>
        </div>
        <div
          class="membership-card row no-gutters align-items-center"
          v-for="(plan, planIndex) in plans"
          :key="planIndex"
        >
          <div class="col-md-6 align-items-center">
            <ul class="list-unstyled benefit-list">
              <li><span>01</span>Unlimited access to premium courses</li>
              <li><span>02</span>Expert Instructors</li>
              <li><span>03</span>Offline watching</li>
            </ul>
          </div>
          <div class="col-md-6">
            <div class="price-wrap">
              <div class="cta-col">
                <h3 class="price-tag">
                  <span class="rupee">₹</span>{{ plan.price }}
                </h3>
                <p>{{ plan.duration }} Days</p>
                <button
                  class="btn cta-primary"
                  :disabled="isPaymentProgressing == true"
                  @click="payment(plan.price, plan.id)"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid hidden-charge-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h5>No hidden charges</h5>
            <p>
              Aenean sed lorem est. Sed quis neque ut nibh suscipit imperdiet ac
              non augue. Aenean ornare sit amet lectus non tristique. Nunc ut
              volutpat lectus. Nulla velit augue, pulvinar sed nisi sit amet,
              eleifend fermentum est. Quisque nibh justo, congue ut erat at,
              aliquet efficitur purus.
            </p>
          </div>
          <div class="col-md-6 text-right">
            <img src="../assets/hidden-ch-img.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="faq-wrapper">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <h5>Frequently Asked Questions</h5>
            <p>
              Integer ac interdum lacus. Nunc porta semper lacus a varius.
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas. Nunc
            </p>
            <div class="faq__img">
              <img src="../assets/faq illlusttration.png" alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="accordion" id="faqAccordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Is there any money back guarentee?
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                    Leggings occaecat craft beer farm-to-table, raw denim
                    aesthetic synth nesciunt you probably haven't heard of them
                    accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How are my referrals tracked?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                    Leggings occaecat craft beer farm-to-table, raw denim
                    aesthetic synth nesciunt you probably haven't heard of them
                    accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Velit quis lobortis ?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#faqAccordion"
                >
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                    Leggings occaecat craft beer farm-to-table, raw denim
                    aesthetic synth nesciunt you probably haven't heard of them
                    accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
            </div>
            <a href="#" class="link-btn">Contact us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import MembershipService from "@/services/MembershipService";
export default {
  name: "Membership",
  data: function() {
    return {
      isPaymentProgressing: false,
      name: "",
      email: "",
      phone: "",
      plans: [],
      slectedPlan: ""
    };
  },
  created: function() {
    this.getUserDetails();
    this.getMembershipPlans();
  },
  methods: {
    payment(amount, id) {
      this.isPaymentProgressing = false;
      this.slectedPlan = id;
      var rzp1 = new window.Razorpay({
        key: process.env.VUE_APP_RAZOR_PAY_KEY,
        amount: amount * 100,
        name: "Ology Women",
        currency: "INR",
        description: "test description",
        image: "../assets/Ologylogo-color.png",
        handler: response => {
          this.isPaymentProgressing = true;
          this.saveMembership(response);
        },
        prefill: {
          name: this.name,
          email: this.email,
          contact: this.phone
        },
        notes: {
          address: ""
        },
        theme: {
          color: "#00ffff"
        }
      });
      rzp1.open();
      rzp1.on("payment.failed", function(response) {
        alert(response.error.description);
      });
    },
    async getUserDetails() {
      await AuthService.getUser()
        .then(result => {
          let userData = result.data.user;
          this.name = userData.name;
          this.email = userData.email;
          this.phone = userData.phone;
          this.$store.dispatch("auth/updateUser", JSON.stringify(userData));
          localStorage.setItem("user", JSON.stringify(userData));
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async saveMembership(response) {
      const data = {
        response: response.razorpay_payment_id,
        plan_id: this.slectedPlan
      };
      await MembershipService.saveMembership(data)
        .then(result => {
          if (result.data.status == "success") {
            this.getUserDetails();
            this.$router
              .push({
                name: "paymentSuccess",
                params: { id: result.data.payment_id },
                query: { source: "membership" }
              })
              .catch(() => {});
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getMembershipPlans() {
      await MembershipService.getMembershipPlans()
        .then(result => {
          if (result.data.status == "success") {
            this.plans = result.data.plans;
          } else {
            this.$toastr.e(result.data.message);
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>
