<template>
  <div class="login">
    <div class="container-fluid register-wrap">
      <div class="container">
        <div
          class="row register-row justify-content-between align-items-center"
        >
          <div class="col-md-5" v-if="showSuccessMessage == false">
            <h2>Forgot Password?</h2>
            <p>Please enter your registered email address</p>
            <div class="register-form-wrap">
              <form @submit.prevent="sendRestLink">
                <div class="form-group">
                  <label for="mail">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="user_name"
                    id="mail"
                    aria-describedby=""
                    placeholder="ex: charles.lawson@mail.com"
                  />
                  <div class="error-message">
                    {{ validation.firstError("user_name") }}
                  </div>
                </div>
                <div class="action-wrap">
                  <button
                    type="submit"
                    class="btn cta-primary"
                    :disabled="isLoading == true"
                  >
                    <span v-if="isLoading == false">
                      Submit
                    </span>
                    <span v-else>
                      <ButtonLoader></ButtonLoader>
                    </span>
                  </button>
                  <p>
                    New to Ology Women?
                    <router-link class="btn-clear" to="/register">
                      Sign Up
                    </router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-5" v-else>
            <h2>Reset link sent</h2>
            <p>Please check your registered email address for reset link</p>
            <div class="register-form-wrap">
              Not yet received
              <button class="btn cta-primary" @click="resend">
                <span v-if="isLoading == false">
                  Resend
                </span>
                <span v-else>
                  <ButtonLoader></ButtonLoader>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import SimpleVueValidation from "simple-vue-validator";
import ButtonLoader from "@/components/ButtonLoader.vue";
const Validator = SimpleVueValidation.Validator;
export default {
  name: "ForgotPassword",
  components: {
    ButtonLoader
  },
  data() {
    return {
      user_name: "",
      isLoading: false,
      showSuccessMessage: false
    };
  },
  validators: {
    user_name: function(value) {
      return Validator.value(value)
        .required("Please enter your email")
        .email("Please enter a valid email");
    }
  },
  methods: {
    sendRestLink: function() {
      this.$validate().then(success => {
        if (success) {
          this.isLoading = true;
          AuthService.sendRestLink({ email: this.user_name })
            .then(result => {
              this.isLoading = false;
              if (result.data.status == "success") {
                this.$toastr.s(result.data.message);
                this.showSuccessMessage = true;
              }
              if (result.data.status == "error") {
                this.$toastr.e(result.data.message);
              }
              console.log(result);
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
            });
        }
      });
    },
    resend: function() {
      this.isLoading = true;
      AuthService.sendRestLink({ email: this.user_name })
        .then(result => {
          this.isLoading = false;
          if (result.data.status == "success") {
            this.$toastr.s(result.data.message);
            this.showSuccessMessage = true;
          }
          if (result.data.status == "error") {
            this.$toastr.e(result.data.message);
          }
          console.log(result);
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
        });
    }
  }
};
</script>
