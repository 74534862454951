<template>
  <div class="form-group">
    <textarea
      v-model="discussion"
      class="form-control"
      id="discussion"
      rows="2"
      placeholder="start discussion..."
    ></textarea>
    <div class="error-message" v-if="error != ''">
      {{ error }}
    </div>
    <div class="text-right mt-3">
      <button
        @click.prevent="saveDiscussion()"
        :disabled="isDisabledButton == true"
        class="btn cta-primary"
      >
        post
      </button>
    </div>
  </div>
</template>

<script>
import CourseService from "@/services/CourseService";
export default {
  name: "DiscussionForm",
  props: ["courseId", "discussionId"],
  data: function() {
    return {
      discussion: "",
      error: "",
      isDisabledButton: false
    };
  },
  methods: {
    saveDiscussion: function() {
      if (this.discussion == "") {
        this.error = "Please enter your comment";
      } else {
        this.isDisabledButton = true;
        this.error = "";
        CourseService.saveDiscussion({
          discussion_id: this.discussionId,
          course_id: this.courseId,
          discussion: this.discussion
        })
          .then(result => {
            this.isDisabledButton = false;
            if (result.data.status == "success") {
              this.$toastr.s(result.data.message);
              this.$parent.getDiscussions();
              this.discussion = "";
            } else if (result.data.status == "validation_error") {
              this.$toastr.e("Maximum 2400 character is allowed");
            } else {
              this.$toastr.e("Failed to save comment");
            }
          })
          .catch(error => {
            this.isDisabledButton = false;
            this.error = error;
            this.$toastr.e("Failed to save comment");
          });
      }
    }
  }
};
</script>

<style scoped></style>
