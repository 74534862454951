<template>
  <div class="form-group">
    <textarea
      v-model="comment"
      class="form-control"
      id="discussion"
      rows="2"
      placeholder="start discussion..."
    ></textarea>
    <div class="error-message" v-if="error != ''">
      {{ error }}
    </div>
    <div class="text-right mt-3">
      <button
        @click.prevent="saveComment()"
        :disabled="isDisabledButton == true"
        class="btn cta-primary"
      >
        post
      </button>
    </div>
  </div>
</template>

<script>
import BlogService from "@/services/BlogService";
export default {
  name: "CommentForm",
  props: ["blogId", "commentId"],
  data: function() {
    return {
      comment: "",
      error: "",
      isDisabledButton: false
    };
  },
  methods: {
    saveComment: function() {
      if (this.comment == "") {
        this.error = "Please enter your comment";
      } else {
        this.isDisabledButton = true;
        this.error = "";
        BlogService.saveComment({
          comment_id: this.commentId,
          blog_id: this.blogId,
          comment: this.comment
        })
          .then(result => {
            this.isDisabledButton = false;
            if (result.data.status == "success") {
              this.$toastr.s(result.data.message);
              this.$parent.getComments(this.blogId);
              this.comment = "";
            } else {
              this.$toastr.e("Failed to save comment");
            }
          })
          .catch(error => {
            this.isDisabledButton = false;
            this.error = error;
            this.$toastr.e("Failed to save comment");
          });
      }
    }
  }
};
</script>

<style scoped></style>
