<template>
  <div class="login">
    <div class="container-fluid register-wrap">
      <div class="container">
        <div
          class="row register-row justify-content-between align-items-center"
        >
          <div class="col-md-5">
            <h2>Please Wait</h2>
            <p>We are verifying your email address</p>
            <div class="register-form-wrap">
              <loader></loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import Loader from "../components/Loader.vue";
export default {
  name: "ForgotPassword",
  components: {
    Loader
  },
  data() {
    return {
      id: this.$route.query.id
    };
  },
  created: function() {
    this.verifyEmail();
  },
  methods: {
    verifyEmail: function() {
      AuthService.verifyEmail({ id: this.id })
        .then(result => {
          if (result.data.status == "success") {
            this.$toastr.s(result.data.message);
            this.$store.dispatch(
              "auth/updateUser",
              JSON.stringify(result.data.user)
            );
            this.$router.push({ name: "profilePage" });
          }
          if (result.data.status == "error") {
            this.$toastr.e(result.data.message);
            this.$router.push({ name: "emailVerification" });
          }
        })
        .catch(error => {
          console.log(error);
          let toUrl = this.$router.history.current.path + "?id=" + this.id;
          this.$store.dispatch("auth/updateNextUrl", toUrl);
          this.$router.push({ name: "Login" });
        });
    }
  }
};
</script>
