<template>
  <div>
    <div
      class="container-fluid popular-wrapper"
      v-if="popularCourses.length > 0"
    >
      <div class="container">
        <h2>Popular Courses</h2>
        <div
          class="row popular-loader course-list-row"
          v-if="this.$store.state.course.isLoadingPopularCourse == true"
        >
          <Loader></Loader>
        </div>
        <hooper
          :settings="hooperSettings"
          :hooperSettings="false"
          :wheelControl="false"
          v-else
        >
          <slide
            v-for="(course, popularIndex) in popularCourses"
            :key="popularIndex"
          >
            <CourseCard v-bind:course="course" />
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
import CourseService from "@/services/CourseService";
import Loader from "@/components/Loader.vue";

export default {
  name: "PopularCourses",
  components: {
    CourseCard,
    Hooper,
    Slide,
    HooperNavigation,
    Loader
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        breakpoints: {
          600: {
            itemsToShow: 1
          },
          800: {
            itemsToShow: 1
          },
          1000: {
            itemsToShow: 4
          }
        }
      },
      popularCourses: []
    };
  },
  created: function() {
    this.getPopularCouese();
  },
  methods: {
    async getPopularCouese() {
      await CourseService.getPopularCourse()
        .then(result => {
          this.popularCourses = result.data.data;
          this.$store.dispatch("updatePopularCourseLoader", false);
        })
        .catch(error => {
          this.error = error;
          this.$store.dispatch("updatePopularCourseLoader", false);
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>
<style scoped>
.row.popular-loader {
  height: 230px;
}
</style>
