import Http from "@/services/Http";
export default {
  getOfflineCourses(data, page) {
    return Http().post("offline/courses?page=" + page, data);
  },
  getOfflineCourse(data) {
    return Http().post("offline/course/details", data);
  },
  getCoursePayment(data) {
    return Http().post('course/get/payment', data);
  },
  getUser() {
    return Http().post("course/get/user");
  },
  saveCourseBooking(data) {
    return Http().post("course/booking/save", data);
  },

};
