<template>
  <div class="category-tab-wrapper">
    <ul class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="pills-watchlist-tab"
          data-toggle="pill"
          href="#pills-watchlist"
          role="tab"
          aria-controls="pills-watchlist"
          aria-selected="false"
          >Purchased</a
        >
      </li>
      <li class="nav-item" v-if="isInstructor == 1">
        <a
          class="nav-link"
          id="pills-saved-tab"
          data-toggle="pill"
          href="#pills-saved"
          role="tab"
          aria-controls="pills-saved"
          aria-selected="false"
          >Hosted</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-hosted-tab"
          data-toggle="pill"
          href="#pills-hosted"
          role="tab"
          aria-controls="pills-hosted"
          aria-selected="false"
          >Wish list</a
        >
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-watchlist"
        role="tabpanel"
        aria-labelledby="pills-watchlist-tab"
      >
        <div class="all-category-wrapper">
          <div class="row" v-if="purchasedCourseLoader == true">
            <Loader></Loader>
          </div>
          <div class="row" v-else>
            <div
              class="col-md-4"
              v-for="(course, purchaseIndex) in purchasedCourses"
              :key="purchaseIndex"
            >
              <CourseCard
                :course="course"
                @create-project="showModal"
                @create-review="showReviewModal"
              />
            </div>
          </div>
          <div
            class="not-found-wrapper"
            v-if="
              purchasedCourses.length == 0 && purchasedCourseLoader == false
            "
          >
            <img src="@/assets/not-found.svg" alt="not-found" />
            <h4>Your knowledge list is empty!</h4>
            <router-link :to="{ name: 'courseList' }" class="btn cta-primary">
              Watch Courses
            </router-link>
          </div>
          <div class="row mt-4" v-if="purchasedCourses.length > 0">
            <div class="col-md-12 text-right">
              <router-link :to="{ name: 'courseList' }" class="btn cta-primary">
                Watch Courses
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-saved"
        role="tabpanel"
        aria-labelledby="pills-saved-tab"
        v-if="isInstructor == 1"
      >
        <div class="all-category-wrapper">
          <div class="row">
            <div
              class="col-md-4"
              v-for="(course, courseIndex) in hostedCourse"
              :key="courseIndex"
            >
              <HostedCourseCard :course="course"></HostedCourseCard>
            </div>
          </div>
          <div class="not-found-wrapper" v-if="hostedCourse.length == 0">
            <img src="@/assets/not-found.svg" alt="not-found" />
            <h4>Not yet created a course!</h4>
            <router-link :to="{ name: 'createCourse' }" class="btn cta-primary">
              Create a Course
            </router-link>
          </div>
          <div class="row mt-4" v-if="hostedCourse.length > 0">
            <div class="col-md-12 text-right">
              <router-link
                :to="{ name: 'createCourse' }"
                class="btn cta-primary"
              >
                Host a course
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-hosted"
        role="tabpanel"
        aria-labelledby="pills-hosted-tab"
      >
        <div class="all-category-wrapper">
          <div class="row" v-if="wishlistLoader == true">
            <Loader></Loader>
          </div>
          <div class="row" v-else>
            <div
              class="col-md-4"
              v-for="(course, courseIndex) in wishlists"
              :key="courseIndex"
            >
              <CourseCard :course="course" />
            </div>
          </div>
          <div
            class="not-found-wrapper"
            v-if="wishlists.length == 0 && wishlistLoader == false"
          >
            <img src="@/assets/not-found.svg" alt="not-found" />
            <h4>Your wishlist is empty!</h4>
            <router-link :to="{ name: 'courseList' }" class="btn cta-primary">
              Courses
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade add-subtitle-wrap"
      id="projectModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="subtitleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="subtitleModalLabel">Create Project</h5>
          </div>
          <form @submit.prevent="createProject">
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Project Title"
                    v-model="projectTitle"
                  />
                  <div class="error-message">
                    {{ validation.firstError("projectTitle") }}
                  </div>
                  <div v-if="errors && errors.title" class="error-message">
                    {{ errors.title[0] }}
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <textarea
                    v-model="projectDescription"
                    class="form-control"
                    id="description"
                    rows="3"
                    placeholder="Project description"
                  >
                  </textarea>
                  <div class="error-message">
                    {{ validation.firstError("projectDescription") }}
                  </div>
                  <div
                    v-if="errors && errors.description"
                    class="error-message"
                  >
                    {{ errors.description[0] }}
                  </div>
                </div>
                <div class="col-md-12">
                  <input
                    type="file"
                    id="fileUpload"
                    ref="projectFile"
                    @change="selectFile"
                  />
                  <div class="error-message">
                    {{ validation.firstError("projectFile") }}
                  </div>
                  <div v-if="errors && errors.file" class="error-message">
                    {{ errors.file[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                ref="close"
                class="btn cta-clear"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                :disabled="disabledButton == true"
                class="btn cta-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-------- review -->
    <div
      class="modal fade add-review-wrap"
      id="reviewModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="add-reviewModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title color-1" id="add-reviewModalLabel">
              Add your review
            </h5>
          </div>
          <form @submit.prevent="submitReview">
            <div class="modal-body">
              <div class="form-row">
                <div class="col-md-8 form-group">
                  <label for="reviewHead">Rating </label>
                  <div class="rating">
                    <vue-stars
                      name="ratingInput"
                      :value="reviewRating"
                      :max="5"
                      :active-color="'#ffdd00'"
                      :inactive-color="'#999999'"
                      :shadow-color="'#ffff00'"
                      :hover-color="'#dddd00'"
                      @input="updateInputRating"
                    >
                      <span slot="activeLabel">
                        <i class="fa fa-star fill"></i>
                      </span>
                      <span slot="inactiveLabel">
                        <i class="fa fa-star plain"></i>
                      </span>
                    </vue-stars>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <label for="reviewContent">Review here</label>
                  <textarea
                    v-model="reviewDescription"
                    class="form-control"
                    id="review-description"
                    rows="2"
                    placeholder="Review..."
                  ></textarea>
                  <div class="error-message">
                    {{ validation.firstError("reviewDescription") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                ref="close"
                class="btn cta-clear"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                :disabled="disabledButton == true"
                class="btn cta-primary"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import CourseService from "@/services/CourseService";
import Loader from "@/components/Loader.vue";
import SimpleVueValidation from "simple-vue-validator";
import HostedCourseCard from "@/components/HostedCourseCard.vue";
import VueStars from "@/components/VueStars.vue";
const Validator = SimpleVueValidation.Validator;
import $ from "jquery";
export default {
  name: "CourseSection",
  components: {
    CourseCard,
    Loader,
    HostedCourseCard,
    VueStars
  },
  validators: {
    projectTitle: function(value) {
      return Validator.value(value).required("Please enter  title");
    },
    projectDescription: function(value) {
      return Validator.value(value).required("Please enter description");
    },
    projectFile: function(value) {
      return Validator.value(value).required("Please select a file");
    },
    reviewDescription: function(value) {
      return Validator.value(value)
        .required("Please enter your review")
        .minLength(2)
        .maxLength(450);
    }
  },
  data: function() {
    return {
      description: "",
      isInstructor: "",
      hostedCourse: [],
      wallet: 0,
      point: 0,
      purchasedCourses: [],
      projectTitle: "",
      projectDescription: "",
      projectCourseId: "",
      disabledButton: false,
      projectFile: "",
      errors: {},
      purchasedCourseLoader: true,
      hostedCourseLoader: true,
      wishlists: [],
      wishlistLoader: true,
      reviewRating: 5,
      reviewDescription: ""
    };
  },
  created() {
    this.getPurchasedCourse();
    this.getHostedCourse();
    this.getWishlists();
  },
  mounted() {
    let user = JSON.parse(this.$store.getters["auth/user"]);
    this.isInstructor = user.is_instructor;
  },
  methods: {
    async getHostedCourse() {
      await CourseService.getHostedCourse()
        .then(result => {
          this.hostedCourse = result.data.data;
          this.hostedCourseLoader = false;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getPurchasedCourse() {
      await CourseService.getPurchasedCourse()
        .then(result => {
          this.purchasedCourses = result.data;
          this.purchasedCourseLoader = false;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getWishlists() {
      await CourseService.getWishlists()
        .then(result => {
          this.wishlists = result.data;
          this.wishlistLoader = false;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    showModal: function(id) {
      this.projectCourseId = id;
      $("#projectModal").modal("show");
    },
    showReviewModal: function(id) {
      this.projectCourseId = id;
      $("#reviewModal").modal("show");
    },
    selectFile: function() {
      this.projectFile = this.$refs.projectFile.files[0];
    },
    createProject: function() {
      const fields = ["projectTitle", "projectDescription", "projectFile"];
      this.$validate(fields).then(success => {
        if (success) {
          this.disabledButton = true;
          const formData = new FormData();
          formData.append("title", this.projectTitle);
          formData.append("description", this.projectDescription);
          formData.append("file", this.projectFile);
          formData.append("course_id", this.projectCourseId);
          CourseService.saveProject(formData)
            .then(result => {
              this.disabledButton = false;
              if (result.data.status == "validation_error") {
                this.errors = result.data.message || {};
              } else if (result.data.status == "success") {
                this.errors = {};
                $("#projectModal").modal("hide");
                this.$toastr.s(result.data.message);
                this.projectTitle = "";
                this.projectDescription = "";
                this.projectFile = "";
                this.$refs.projectFile.value = null;
                this.projectCourseId = "";
              }
            })
            .catch(function() {
              this.disabledButton = false;
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    submitReview: function() {
      const fields = ["reviewDescription"];
      this.$validate(fields).then(success => {
        if (success) {
          this.disabledButton = true;
          const data = {
            rating: this.reviewRating,
            review: this.reviewDescription,
            course_id: this.projectCourseId
          };
          CourseService.saveReview(data)
            .then(result => {
              this.disabledButton = false;
              $("#reviewModal").modal("hide");
              this.reviewDescription = "";
              this.reviewRating = 5;
              if (result.data.status == "success") {
                this.$toastr.s(result.data.message);
              }
              if (result.data.status == "error") {
                this.$toastr.e(result.data.message);
              }
            })
            .catch(error => {
              this.disabledButton = false;
              this.error = error;
              this.$toastr.e(error);
            });
        }
      });
    },
    updateInputRating(event) {
      this.reviewRating = event;
    }
  }
};
</script>

<style scoped></style>
