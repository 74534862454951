<template>
  <div class="blog-listing">
    <div class="container blog-list-wrap">
      <div class="head-title d-flex justify-content-between align-items-center">
        <h5 class="color-1">Blog</h5>
      </div>
      <div class="hero-blog-wrap">
        <div class="row">
          <div
            class="col-md-8"
            v-if="latestBlogLoader == false && latestBlog != ''"
          >
            <div class="hero-blog">
              <div class="__title">
                <h3 class="Blog-title">
                  {{ latestBlog.title }}
                </h3>
                <p>
                  {{ latestBlog.created_date }} by
                  <span v-if="latestBlog.author != null"
                    >{{ latestBlog.author }}
                  </span>
                  <span v-else
                    >{{ latestBlog.name }} {{ latestBlog.last_name }}</span
                  >
                </p>
              </div>
              <div class="__image">
                <img :src="latestBlog.image" alt="blog title" />
              </div>
              <div class="text-right">
                <router-link
                  class="cta-clear"
                  :to="{ name: 'blog', params: { slug: latestBlog.slug } }"
                  >Read More</router-link
                >
              </div>
            </div>
          </div>
          <div
            class="col-md-8"
            v-else-if="latestBlogLoader == false && latestBlog == ''"
          >
            <img
              src="@/assets/not-found.svg"
              alt="not-found"
              style="margin-left: 22%"
            />
            <h4 style="margin-left: 22%">Blog list is empty!</h4>
          </div>
          <div class="col-md-8" v-else>
            <loader></loader>
          </div>
          <div class="col-md-4 action-div">
            <div class="recent-blogs">
              <h4>Recent blogs</h4>
              <div class="recent-blogs" v-if="recentBlogLoader == true">
                <loader></loader>
              </div>
              <ul class="list-unstyled" v-else>
                <li
                  v-for="(recent, recentIndex) in recentBlogs"
                  :key="recentIndex"
                >
                  <div class="image-box">
                    <img :src="recent.image" alt="" />
                  </div>
                  <div class="title">
                    <router-link
                      :to="{ name: 'blog', params: { slug: recent.slug } }"
                      >{{ recent.title }}</router-link
                    >
                    <span>{{ recent.created_date }}</span>
                    <span
                      to="#"
                      class="recent-author-name"
                      v-if="recent.author != null"
                    >
                      by {{ recent.author }}
                    </span>
                    <span
                      class="recent-author-name"
                      v-else
                      :to="{
                        name: 'publicProfile',
                        params: { name: recent.name, id: recent.user_id }
                      }"
                    >
                      by {{ recent.name }} {{ recent.last_name }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="thread-keywords keyword-wrapper">
              <h4 v-if="keywords.length > 0">Keywords</h4>
              <ul class="list-unstyled">
                <li v-for="(keyword, index) in keywords" :key="index">
                  <button class="btn keywordTag">{{ keyword }}</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-list-row row" v-if="loader == true">
        <loader></loader>
      </div>
      <div class="blog-list-row row" v-else>
        <BlogCard
          v-for="(blog, blogIndex) in blogs"
          :key="blogIndex"
          :blog="blog"
        ></BlogCard>
      </div>
      <SlidingPagination
        class="blog-list-pagination"
        :current="currentPage"
        :total="totalPages"
        @page-change="pageChangeHandler"
        v-if="loader == false && totalPages > 1"
      >
      </SlidingPagination>
    </div>
  </div>
</template>

<script>
import BlogCard from "@/components/BlogCard.vue";
import BlogService from "@/services/BlogService";
import SlidingPagination from "vue-sliding-pagination";
import Loader from "../components/Loader.vue";
export default {
  name: "BlogListing",
  components: {
    BlogCard,
    SlidingPagination,
    Loader
  },
  data: function() {
    return {
      blogs: [],
      totalResult: 0,
      resultFrom: 0,
      resultTo: 0,
      currentPage: 0,
      totalPages: 0,
      loader: true,
      latestBlog: [],
      recentBlogs: [],
      recentBlogLoader: true,
      latestBlogLoader: true,
      keywords: []
    };
  },
  created: function() {
    this.getBlogs();
    this.getLatestBlog();
    this.getRecentBlogs();
  },
  methods: {
    async getBlogs() {
      await BlogService.getBlogs(this.currentPage)
        .then(result => {
          this.loader = false;
          this.blogs = result.data.data;
          this.totalPages = result.data.last_page;
          this.currentPage = result.data.current_page;
          this.totalResult = result.data.total;
          this.resultFrom = result.data.from;
          this.resultTo = result.data.to;
        })
        .catch(error => {
          this.loader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getLatestBlog() {
      await BlogService.latestBlog()
        .then(result => {
          this.latestBlogLoader = false;
          this.latestBlog = result.data;
          this.keywords = this.latestBlog.keywords.split(",");
        })
        .catch(error => {
          this.latestBlogLoader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getRecentBlogs() {
      await BlogService.recentBlogs({ blog_id: null })
        .then(result => {
          this.recentBlogLoader = false;
          this.recentBlogs = result.data.data;
        })
        .catch(error => {
          this.recentBlogLoader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      this.getBlogs();
    }
  }
};
</script>
<style scoped>
.blog-list-pagination {
  float: right !important;
}
</style>
