<template>
  <div>
    <div class="container-fluid footer-wrapper">
      <div class="container">
        <div class="footer-contents row">
          <div class="col-md-2 brand-col">
            <a class="navbar-brand" href="#">
              <img src="../assets/logo-footer.svg" alt="logo" width="80px" />
            </a>
          </div>
          <div class="col-md-2 links-col">
            <ul class="list-unstyled">
              <li>
                <router-link :to="'/about-us'">About Us</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'BlogListing' }">Blogs</router-link>
              </li>
              <li>
                <router-link :to="'/enquiry'">Contact Us</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'courseList' }">Courses</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'webinars' }">Events</router-link>
              </li>
              <!-- <li>
                <router-link :to="{ name: 'EventGalleries' }"
                  >Event Galleries</router-link
                >
              </li> -->
              <li>
                <router-link :to="'/privacy-policy'">
                  Privacy Policy</router-link
                >
              </li>
              <li>
                <router-link :to="'/terms-and-conditions'">
                  Terms and Conditions</router-link
                >
              </li>
              <li>
                <router-link :to="'/refund-policy'">
                  Cancellation/Refund Policies.</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-md-3 subscribe-col">
            <!-- <h6>Subscribe to Newsletter</h6>
            <div class="subscribe-wrapper mb-3">
              <form class="form-inline">
                <input
                  class="form-control"
                  type="search"
                  placeholder="Email"
                  aria-label="Email"
                />
                <button class="btn" type="submit">
                  Subscribe
                </button>
              </form>
            </div> -->
          </div>
          <div class="col-md-3 subscribe-col">
            <!-- <h6>Refer a friend</h6>
            <div class="subscribe-wrapper">
              <form class="form-inline" @submit.prevent="referFriend">
                <input
                  v-model="referEmail"
                  class="form-control"
                  type="search"
                  placeholder="Friend's Email"
                  aria-label="Email"
                />
                <button class="btn cta-mini" type="submit">
                  <img class="svg" src="../assets/send-arrow.svg" alt="" />
                </button>
              </form>
              <div v-if="errors.referError" class="error-message">
                {{ errors.referError }}
              </div>
              <div v-if="errors.email" class="error-message">
                {{ errors.email[0] }}
              </div>
            </div> -->
          </div>
          <div class="col-md-2 subscribe-col">
            <div class="share-div">
              <br />
              <ul class="list-unstyled d-flex">
                <li>
                  <a
                    href="https://www.facebook.com/OlogyWomen-108228741845986"
                    target="_blank"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/ologywomen/"
                    target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid sub-footer">
      <div class="container">
        <span> © OlogyWomen, Inc. {{ new Date().getFullYear() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
export default {
  name: "Footer",
  components: {},
  data: function() {
    return {
      referEmail: "",
      errors: {
        referError: ""
      }
    };
  },
  methods: {
    referFriend: function() {
      if (this.referEmail == "") {
        this.errors.referError = "Please enter friend's email";
      } else {
        AuthService.referFriend({ email: this.referEmail })
          .then(result => {
            if (result.data.status == "success") {
              this.errors = {};
              this.$toastr.s(result.data.message);
              this.referEmail = "";
            } else if (result.data.status == "error") {
              this.errors.referError = result.data.message;
              this.errors.email = "";
            } else if (result.data.status == "validation_error") {
              this.errors.referError = "";
              this.errors = result.data.message || {};
            }
          })
          .catch(error => {
            this.errors = error;
          });
      }
    }
  }
};
</script>

<style scoped></style>
