<template>
  <div class="register">
    <div class="container-fluid register-wrap">
      <div class="container">
        <div class="row register-row justify-content-between">
          <div class="col-md-5">
            <h2>Welcome</h2>
            <p>Please create your recruiter account</p>
            <div class="register-form-wrap">
              <form @submit.prevent="register">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="firstname">First name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="firstname"
                      v-model="name"
                      aria-describedby=""
                      placeholder="ex: Jasmine"
                    />
                    <div class="error-message">
                      {{ validation.firstError("name") }}
                    </div>
                    <div v-if="errors && errors.name" class="error-message">
                      {{ errors.name[0] }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="lastname">Last name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastname"
                      v-model="last_name"
                      aria-describedby=""
                      placeholder="ex: Wells"
                    />
                    <div class="error-message">
                      {{ validation.firstError("last_name") }}
                    </div>
                    <div
                      v-if="errors && errors.last_name"
                      class="error-message"
                    >
                      {{ errors.last_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="mail">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="mail"
                    v-model="email"
                    aria-describedby=""
                    placeholder="ex: charles.lawson@mail.com"
                  />
                  <div class="error-message">
                    {{ validation.firstError("email") }}
                  </div>
                  <div v-if="errors && errors.email" class="error-message">
                    {{ errors.email[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="mail">Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    v-model="phone"
                    aria-describedby=""
                    placeholder="ex: 1234560987"
                  />
                  <div class="error-message">
                    {{ validation.firstError("phone") }}
                  </div>
                  <div v-if="errors && errors.phone" class="error-message">
                    {{ errors.phone[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="institution">Institution</label>
                  <input
                    type="text"
                    class="form-control"
                    id="institution"
                    v-model="institution"
                    aria-describedby=""
                    placeholder="Institution Name"
                  />
                  <div class="error-message">
                    {{ validation.firstError("institution") }}
                  </div>
                  <div
                    v-if="errors && errors.institution"
                    class="error-message"
                  >
                    {{ errors.institution[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="InputPassword">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="password"
                    id="InputPassword"
                    placeholder="Password"
                  />
                  <div class="error-message">
                    {{ validation.firstError("password") }}
                  </div>
                  <div v-if="errors && errors.password" class="error-message">
                    {{ errors.password[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="InputPassword">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="password_confirmation"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                  />
                  <div class="error-message">
                    {{ validation.firstError("password_confirmation") }}
                  </div>
                  <div
                    v-if="errors && errors.password_confirmation"
                    class="error-message"
                  >
                    {{ errors.password_confirmation[0] }}
                  </div>
                </div>
                <div class="action-wrap">
                  <button
                    type="submit"
                    class="btn cta-primary"
                    :disabled="this.$store.state.auth.isAuthenticating == true"
                  >
                    <span
                      v-if="this.$store.state.auth.isAuthenticating == false"
                    >
                      Join Now
                    </span>
                    <span v-else>
                      <ButtonLoader></ButtonLoader>
                    </span>
                  </button>
                  <p>
                    Have account?
                    <router-link class="btn-clear" to="/login">
                      Sign In
                    </router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import AuthService from "@/services/AuthService";
const Validator = SimpleVueValidation.Validator;
import ButtonLoader from "@/components/ButtonLoader.vue";
export default {
  name: "RecruiterRegistration",
  components: {
    ButtonLoader
  },
  data() {
    return {
      name: "",
      last_name: "",
      email: "",
      phone: "",
      institution: "",
      password: "",
      password_confirmation: "",
      errors: {}
    };
  },
  validators: {
    name: function(value) {
      return Validator.value(value).required("Please enter your first name");
    },
    last_name: function(value) {
      return Validator.value(value).required("Please enter your last name");
    },
    email: function(value) {
      return Validator.value(value)
        .required("Please enter your email")
        .email("Please enter a valid email");
    },
    phone: function(value) {
      return Validator.value(value)
        .required("Please enter your phone")
        .digit("Please enter a valid number")
        .minLength(10)
        .maxLength(15);
    },
    institution: function(value) {
      return Validator.value(value).required(
        "Please enter your institution name"
      );
    },
    password: function(value) {
      return Validator.value(value)
        .required("Please enter a password")
        .minLength(5);
    },
    "password_confirmation, password": function(
      password_confirmation,
      password
    ) {
      if (
        this.submitted ||
        this.validation.isTouched("password_confirmation")
      ) {
        return Validator.value(password_confirmation)
          .required()
          .match(password, "Password mismatch");
      }
    }
  },
  methods: {
    register: function() {
      this.$validate().then(success => {
        if (success) {
          this.$store.dispatch("auth/authRequest", true);
          const registerData = {
            name: this.name,
            last_name: this.last_name,
            email: this.email,
            phone: this.phone,
            institution: this.institution,
            password: this.password,
            password_confirmation: this.password_confirmation
          };
          AuthService.recruiterRegister(registerData)
            .then(result => {
              this.authStatus = result.data.status;
              if (this.authStatus == "error") {
                this.$toastr.e(result.data.message);
                this.$store.dispatch("auth/authRequest", false);
                this.$store.dispatch("auth/authError");
              } else if (this.authStatus == "validation_error") {
                this.errors = result.data.message || {};
                this.$store.dispatch("auth/authError");
                this.$store.dispatch("auth/authRequest", false);
              } else {
                this.$toastr.s(result.data.message);
                const userData = {
                  token: result.data.token,
                  user: result.data.user
                };
                this.$store.dispatch("auth/authSuccess", userData);
                this.$store.dispatch("auth/authRequest", false);
                this.$router.push({ name: "dashboard" });
              }
            })
            .catch(error => {
              this.error = error;
              this.$store.dispatch("auth/authRequest", false);
              // this.$toastr.e("Something went wrong");
              this.$store.dispatch("auth/authError");
            });
        }
      });
    }
  }
};
</script>
