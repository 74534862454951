<template>
  <div class="card-block row no-gutters">
    <div class="col-md-9 d-flex">
      <div class="card-thumbnail-block">
        <img
          v-if="video.id !== videoId"
          :src="thumbnailUrl"
          alt=""
          class="img-fluid"
        />
        <progress-bar
          v-if="video.id === videoId"
          :options="options"
          :value="parseInt(progress)"
        />
      </div>
      <div class="card-block__body">
        <h4>{{ video.title }}</h4>
        <p>Language: {{ video.language }}</p>
        <p v-if="video.id !== videoId && video.uploading_status == 'uploading'">
          Status: Error
        </p>
        <p v-else>Status: {{ video.uploading_status }}</p>
        <p>
          Subtitles:
          <span
            v-for="(subtitle, subtitleIndex) in video.subtitles"
            :key="subtitleIndex"
          >
            {{ subtitle.language }} ,
          </span>
        </p>
      </div>
    </div>
    <div
      class="col-md-3 justify-content-end align-items-center d-flex card-block__icon-col"
    >
      <button
        class=" btn Subtitle-btn"
        data-toggle="modal"
        :data-target="'#subtitleModal' + video.video"
        @click="addSubtitle(video.video, video.id)"
        v-if="isPublished == false"
      >
        Add subtitle
      </button>
      <button
        to=""
        class=" btn delete-btn"
        @click="deleteVideo"
        :data-id="[video.id]"
        :data-video="[video.video]"
        v-if="isPublished == false"
      >
        <img
          src="../assets/delete-icon.svg"
          alt=""
          :data-id="[video.id]"
          :data-video="[video.video]"
        />
      </button>
    </div>
    <div
      class="modal fade add-subtitle-wrap"
      :id="'subtitleModal' + video.video"
      tabindex="-1"
      role="dialog"
      aria-labelledby="subtitleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="subtitleModalLabel">Add subtitle</h5>
          </div>
          <form @submit.prevent="getVideoUrl">
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <textarea
                    v-model="subtitle"
                    class="form-control"
                    id="courseDescription"
                    rows="3"
                    placeholder="Copy and paste your content here"
                  >
                  </textarea>
                  <div class="error-message">
                    {{ validation.firstError("subtitle") }}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <multiselect
                    v-model="subtitleLanguage"
                    :options="subtitleLanguageOptions"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    track-by="id"
                    label="language"
                    placeholder="Subtitle language"
                  >
                  </multiselect>
                  <div class="error-message">
                    {{ validation.firstError("subtitleLanguage") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                ref="close"
                class="btn cta-clear"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                :disabled="disabledButton == true"
                class="btn cta-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "vuejs-progress-bar";
import ModuleService from "@/services/ModuleService";
import VimeoService from "@/services/VimeoService";
import Multiselect from "vue-multiselect";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
import axios from "axios";
export default {
  name: "VideoCard",
  props: ["video", "progress", "videoId", "isPublished"],
  components: {
    ProgressBar,
    Multiselect
  },
  created: function() {
    this.getSubtitleLanguage();
  },
  validators: {
    subtitle: function(value) {
      return Validator.value(value).required("Please enter subtitle");
    },
    subtitleLanguage: function(value) {
      return Validator.value(value).required("Please select language");
    }
  },
  mounted: function() {
    this.getThumbnail(this.video.video);
  },
  data: function() {
    return {
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: "#644fa1",
          backgroundColor: "#f5f8fb"
        },
        layout: {
          height: 20,
          width: 100,
          verticalTextAlign: 70,
          horizontalTextAlign: 35,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "line"
        }
      },
      vimeoVideoId: "",
      subtitleLanguageOptions: [],
      subtitleLanguage: "",
      subtitle: "",
      videoUrl: "",
      result: "",
      error: "",
      disabledButton: false,
      thumbnailUrl: "../assets/video-thumb.jpg"
    };
  },
  methods: {
    deleteVideo: function(event) {
      let videoId = event.target.getAttribute("data-id");
      let video = event.target.getAttribute("data-video");
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            video_id: videoId
          };
          this.deleteVimeoVideo(video);
          ModuleService.deleteVideo(data)
            .then(result => {
              if (result.data.status == "success") {
                this.$emit("reloadModules");
                this.$toastr.s(result.data.message);
              } else {
                this.$toastr.e(result.data.message);
              }
            })
            .catch(error => {
              this.error = error;
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    deleteVimeoVideo: function(video) {
      VimeoService.deleteVideo(video);
    },
    addSubtitle: function(videos, videoId) {
      this.vimeoVideoId = videos;
      this.currentVideo = videoId;
    },
    async getSubtitleLanguage() {
      await ModuleService.getSubtitleLanguage()
        .then(result => {
          this.subtitleLanguageOptions = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    getVideoUrl: function() {
      this.$validate().then(success => {
        if (success) {
          this.disabledButton = true;
          VimeoService.getVideoUrl(this.vimeoVideoId)
            .then(result => {
              let videoUrl = result.data.metadata.connections.texttracks.uri;
              this.videoUrl = videoUrl;
              this.getSubtitleUploadUrl();
            })
            .catch(error => {
              this.error = error;
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    getSubtitleUploadUrl: function() {
      let body = {
        type: "subtitles",
        language: this.subtitleLanguage.code,
        name: this.subtitleLanguage.language
      };
      VimeoService.getSubtitleUploadUrl(body, this.videoUrl)
        .then(result => {
          this.uploadSubtitle(result.data.link);
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    uploadSubtitle(url) {
      let subtitle = this.subtitle;
      let config = {
        headers: {
          Accept: "application/vnd.vimeo.*+json;version=3.4"
        }
      };
      axios
        .put(url, subtitle, config)
        .then(result => {
          this.result = result;
          this.saveSubtitleInDatabase();
          this.$toastr.s("Subtitle added successfully");
          this.disabledButton = false;
          this.$refs.close.click();
          this.subtitle = "";
          this.subtitleLanguage = "";
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
          this.disabledButton = false;
          this.$refs.close.click();
          this.subtitle = "";
          this.subtitleLanguage = "";
        });
    },
    async getThumbnail(videoId) {
      await VimeoService.getThumbnail(videoId)
        .then(result => {
          this.thumbnailUrl = result.data.data[0].sizes[0].link;
        })
        .catch(error => {
          this.error = error;
        });
    },
    async saveSubtitleInDatabase() {
      const data = {
        video_id: this.video.id,
        subtitle_language: this.subtitleLanguage.id,
        subtitle: this.subtitle
      };
      await ModuleService.saveSubtitle(data)
        .then(result => {
          this.result = result;
          this.$emit("reloadModules");
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>

<style scoped></style>
