<template>
  <div class="card-block row no-gutters">
    <div class="col-md-7 d-flex">
      <div class="card-thumbnail-block">
        <img src="../assets/pdf.png" alt="" class="img-fluid" />
        <!-- <img src="../assets/video-thumb.jpg" alt="" class="img-fluid"/> -->
      </div>
      <div class="card-block__body">
        <h4>{{ article.title }}</h4>
        <p>Language: {{ article.language }}</p>
      </div>
    </div>
    <div
      class="col-md-5 justify-content-end align-items-center d-flex card-block__icon-col"
    >
      <button
        class=" btn delete-btn"
        v-if="isPublished == false"
        @click="
          $router.push({
            name: 'createArticle',
            params: { articleId: article.id }
          })
        "
      >
        Edit
      </button>
      <button
        to=""
        class=" btn delete-btn"
        @click="deleteArticle(article.id)"
        v-if="isPublished == false"
      >
        <img src="../assets/delete-icon.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
import ModuleService from "@/services/ModuleService";
export default {
  name: "ArticleCard",
  props: ["article", "isPublished"],
  methods: {
    deleteArticle: function(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete"
      }).then(result => {
        if (result.isConfirmed) {
          ModuleService.deleteArticle({ article_id: id })
            .then(result => {
              if (result.data.status == "success") {
                this.$emit("reloadModules");
                this.$toastr.s(result.data.message);
              } else {
                this.$toastr.e(result.data.message);
              }
            })
            .catch(error => {
              this.error = error;
              this.$toastr.e(error);
            });
        }
      });
    }
  }
};
</script>

<style scoped></style>
