const course = {
  namespaced: true,
  state: {
    isLoadingFeaturedBlog: true
  },
  mutations: {
    UPDATE_FEATURE_BLOG_LOADING: (state, loading) => {
      state.isLoadingFeaturedBlog = loading;
    }
  },
  actions: {
    updateFeatureBlogsLoader: ({ commit }, status) => {
      commit("UPDATE_FEATURE_BLOG_LOADING", status);
    }
  }
};
export default course;
