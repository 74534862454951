<template>
  <div class="card hosted-Blog-card">
    <div class="image-wrap">
      <img class="card-img-top" :src="blog.image" alt="Card image cap" />
    </div>
    <div v-html="status"></div>
    <div class="course-stat d-flex justify-content-between"></div>
    <div class="card-body d-flex justify-content-between">
      <div>
        <h6 class="card-title">{{ blog.title }}</h6>
      </div>
      <div class="card-action-div dropleft dropdown">
        <button
          class="btn cta-clear micro-cta"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src="@/assets/dots-icon.svg" alt="" />
        </button>
        <div class="dropdown-menu">
          <router-link
            v-if="blog.status > 1"
            class="dropdown-item"
            :to="{ name: 'createBlog', params: { id: blog.slug } }"
          >
            Edit
          </router-link>
          <router-link
            class="dropdown-item"
            :to="{ name: 'blog', params: { slug: blog.slug } }"
          >
            Preview
          </router-link>
          <!-- <a class="dropdown-item" href="#">Delete</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HostedBlogCard",
  props: ["blog"],
  computed: {
    status() {
      if (this.blog.status == 1)
        return "<span class='status-badge green'>Active</span>";
      else if (this.blog.status == 2)
        return "<span class='status-badge yellow'>Blocked</span>";
      else if (this.blog.status == 3)
        return "<span class='status-badge green'>Pending</span>";
      else return "<span class='status-badge red'>Blocked</span>";
    }
  }
};
</script>
