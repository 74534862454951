<template>
  <div class="blog-single">
    <div class="container-fluid blog-head-wrapper">
      <div class="container">
        <div class="blog-head row align-items-end justify-content-between">
          <div class="col-md-8">
            <h3 class="Blog-title">
              {{ blog.title }}
            </h3>
            <p>
              {{ blog.created_date }} by
              <span v-if="blog.author != null">{{ blog.author }} </span>
              <span v-else>{{ blog.name }} {{ blog.last_name }}</span>
            </p>
          </div>
          <div class="col-md-3 share-div text-right">
            <!-- <p>Share using</p>
            <ul class="list-unstyled d-flex justify-content-end">
              <li>
                <a href="#"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-linkedin-in"></i></a>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container blog-content-wrapper">
      <div class="row justify-content-between">
        <div class="col-md-8 blog-content">
          <p v-html="blog.blog"></p>
        </div>
        <div class="col-md-4 action-div">
          <div class="recent-blogs">
            <h4>Recent blogs</h4>
            <div class="recent-blogs" v-if="loader == true">
              <loader></loader>
            </div>
            <ul class="list-unstyled" v-else>
              <li
                v-for="(recent, recentIndex) in recentBlogs"
                :key="recentIndex"
              >
                <div class="image-box">
                  <img :src="recent.image" alt="" />
                </div>
                <div class="title">
                  <router-link
                    :to="{ name: 'blog', params: { slug: recent.slug } }"
                    >{{ recent.title }}</router-link
                  >
                  <span>{{ recent.created_date }}</span>
                  <span class="recent-author-name" v-if="recent.author != null">
                    by {{ recent.author }}
                  </span>
                  <span class="recent-author-name" v-else>
                    by {{ recent.name }} {{ recent.last_name }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="thread-keywords keyword-wrapper">
            <h4 v-if="keywords.length > 0">Keywords</h4>
            <ul class="list-unstyled">
              <li v-for="(keyword, index) in keywords" :key="index">
                <button class="btn keywordTag">{{ keyword }}</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row blog-comment-wrapper">
            <div class="col-md-8">
              <div
                class="head-title d-flex justify-content-between align-items-center"
              >
                <h5 class="color-1" v-if="comments.length > 0">Comments</h5>
              </div>
              <div class="discussion-wrap">
                <div
                  class="start-discussion-wrap"
                  v-if="$store.getters['auth/isLoggedIn'] == true"
                >
                  <div class="image-wrap">
                    <img class="img-fluid" :src="avatar" alt="Card image cap" />
                  </div>
                  <comment-form :blogId="blog.id" :commentId="null">
                  </comment-form>
                </div>
                <ul class="list-unstyled">
                  <li
                    v-for="(comment, commentIndex) in comments"
                    :key="commentIndex"
                  >
                    <CommentCard
                      :discussion="comment"
                      :reply="comment.children.length"
                    ></CommentCard>
                    <div class="comment-reply">
                      <CommentCard
                        v-for="(reply, replyIndex) in comment.children"
                        :key="replyIndex"
                        :discussion="reply"
                        :reply="0"
                      >
                      </CommentCard>
                      <form v-if="$store.getters['auth/isLoggedIn'] == true">
                        <div class="reply-box">
                          <div class="image-wrap">
                            <img
                              class="img-fluid"
                              :src="avatar"
                              alt="Card image cap"
                            />
                          </div>
                          <comment-form
                            :blogId="blog.id"
                            :commentId="comment.id"
                          >
                          </comment-form>
                        </div>
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopularCourses></PopularCourses>
  </div>
</template>

<script>
import PopularCourses from "@/components/PopularCourses.vue";
import BlogService from "@/services/BlogService";
import CommentForm from "../components/CommentForm.vue";
import CommentCard from "../components/CommentCard.vue";
import Loader from "../components/Loader.vue";
export default {
  name: "Blog",
  components: { PopularCourses, CommentForm, CommentCard, Loader },
  data: function() {
    return {
      blog: [],
      error: [],
      keywords: [],
      comments: [],
      avatar: "",
      recentBlogs: [],
      loader: true
    };
  },
  watch: {
    "$route.params.slug": function() {
      this.loader = true;
      this.getBlog();
    }
  },
  created: function() {
    this.getBlog();
    this.getRecentBlogs();
    if (this.$store.getters["auth/isLoggedIn"] == true) {
      let user = JSON.parse(this.$store.state.auth.user);
      this.avatar = user.image;
    }
  },
  methods: {
    async getBlog() {
      await BlogService.getBlog({ slug: this.$route.params.slug })
        .then(result => {
          if (result.data.status == "success") {
            this.blog = result.data.blog;
            this.keywords = this.blog.keywords.split(",");
            this.getComments(this.blog.id);
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getComments(id) {
      await BlogService.getComments({ blog_id: id })
        .then(result => {
          this.comments = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getRecentBlogs() {
      await BlogService.recentBlogs({ blog_id: this.blog.id })
        .then(result => {
          this.loader = false;
          this.recentBlogs = result.data.data;
        })
        .catch(error => {
          this.loader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>

<style scoped>
.image-wrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  overflow: hidden;
}
.blog-single .blog-content-wrapper p {
  overflow: hidden;
}
.blog-single .blog-content-wrapper img {
  -o-object-fit: cover;

  object-fit: cover;

  height: unset !important;

  width: 100% !important;
}
</style>
