<template>
  <div class="blog-single">
    <div class="container-fluid blog-head-wrapper">
      <div class="container">
        <div class="blog-head row align-items-end justify-content-between">
          <div class="col-md-8">
            <h3 class="Blog-title">
              Oops! Page not found
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container blog-content-wrapper">
      <div class="row justify-content-between">
        <div class="col-md-12 blog-content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
};
</script>
