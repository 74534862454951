<template>
  <div class="wallet-section">
    <h5 class="hero-head">Total Income</h5>
    <div class="row revenue-row justify-content-between">
      <div class="col-md-4">
        <h4 class="Price"><span>₹ </span> {{ userDetails.totalIncome }}</h4>
        <span class="stat-badge green"
          >{{ userDetails.membership_duration }}+ Days
        </span>
      </div>
    </div>
    <div class="row action-card-row">
      <div class="col-md-6">
        <div class="__action-card">
          <div class="title-div">
            <div class="__thumb">
              <span>₹</span>
            </div>
            <p>withdrawable</p>
          </div>
          <h4><span>₹</span>{{ userDetails.wallet }}</h4>
          <div class="__action text-right">
            <button
              class="btn cta-clear"
              data-toggle="modal"
              data-target="#redeemModal"
            >
              Redeem <img src="../../assets/navigate-icon.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="__action-card">
          <div class="title-div">
            <div class="__thumb">
              <span>₹</span>
            </div>
            <p>Redeemed</p>
          </div>
          <h4><span>₹</span>{{ userDetails.redeemed }}</h4>
          <div class="__action text-right">
            <button class="btn cta-clear">
              History <img src="../../assets/navigate-icon.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <h5 class="hero-head">Top performed courses</h5>

    <table class="table">
      <tbody>
        <tr v-for="(course, courseIndex) in topCourses" :key="courseIndex">
          <td colspan="2">
            <div class="course-info">
              <div class="img-wrap">
                <img :src="course.image" alt="" />
              </div>
              <div class="title-wrap">
                <h5>{{ course.title }}</h5>
                <p>{{ course.created_on }}</p>
              </div>
            </div>
          </td>
          <td>
            <h5 class="sale">{{ course.count }}<span>Sales</span></h5>
          </td>
          <td>
            <h5 class="price" v-if="course.revenue != null">
              <span>₹</span> {{ course.revenue.toFixed(2) }}
            </h5>
            <h5 class="price" v-else><span>₹</span> 0</h5>
          </td>
        </tr>
        <tr v-if="topCourses.length == 0">
          <td class="align-middle" colspan="4">
            <div class="not-found-wrapper">
              <img src="@/assets/not-found.svg" alt="not-found" />
              <h4>No course hosted</h4>
              <router-link
                :to="{ name: 'createCourse' }"
                class="btn cta-primary"
              >
                Host a course
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      class="modal fade add-review-wrap"
      id="redeemModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="add-reviewModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title color-1" id="add-reviewModalLabel">
              Redeem Request
            </h5>
          </div>
          <form @submit.prevent="redeemRequest">
            <div class="modal-body">
              <div class="form-row">
                <div class="col-md-12 form-group">
                  <label for="reviewHead">Amount </label>
                  <input
                    type="number"
                    placeholder="Enter Amount"
                    class="form-control"
                    v-model="requestAmount"
                  />
                  <div class="error-message" v-if="errors != ''">
                    {{ errors }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                ref="close"
                class="btn cta-clear"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                :disabled="disabledButton == true"
                class="btn cta-primary"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseService from "@/services/CourseService";
import PaymentService from "@/services/PaymentService";
import $ from "jquery";
export default {
  name: "WalletSection",
  props: ["userDetails"],
  data: function() {
    return {
      value: "",
      options: [],
      topCourses: [],
      loader: true,
      requestAmount: 0,
      errors: "",
      disabledButton: false
    };
  },
  created() {
    this.getTopCourses();
  },
  methods: {
    async getTopCourses() {
      this.loader = true;
      await CourseService.getRevenues()
        .then(result => {
          this.topCourses = result.data;
          this.loader = false;
        })
        .catch(error => {
          this.loader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    redeemRequest: function() {
      if (this.userDetails.wallet < this.requestAmount) {
        this.errors = "Requested amount is greater than wallet amount";
      } else {
        this.errors = "";
        PaymentService.sendRedeemRequest({ request_amount: this.requestAmount })
          .then(result => {
            $("#redeemModal").modal("hide");
            if (result.data.status == "success") {
              this.$toastr.s(result.data.message);
            } else {
              this.$toastr.e(result.data.message);
            }
          })
          .catch(error => {
            this.loader = false;
            this.error = error;
          });
      }
    }
  }
};
</script>

<style scoped></style>
