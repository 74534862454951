<template>
  <div>
    <transition name="appear">
      <div
        class="card course-card"
        style=""
        @click.self="courseDetails(course.slug)"
      >
        <div class="image-wrap">
          <img
            @click.self="courseDetails(course.slug)"
            class="card-img-top"
            :src="course.image"
            alt="Card image cap"
          />
          <div class="mount-tag" v-if="course.price == 0">
            <h5>Free</h5>
          </div>
        </div>
        <div class="card-body">
          <h6 class="card-title" @click.self="courseDetails(course.slug)">
            {{ course.title }}
          </h6>
          <div class="author-info">
            <p class="author-name">
              Created By
              <router-link
                :to="{
                  name: 'publicProfile',
                  params: { name: course.name, id: course.user_id }
                }"
                >{{ course.name }}</router-link
              >
            </p>
            <div class="rating" v-if="getRating > 0">
              <b class="rating-number">{{ getRating }}</b>
              <div class="d-flex">
                <div>
                  <i
                    class="fa fa-star fill"
                    v-for="filledIndex in getFloorRating"
                    :key="filledIndex"
                  ></i>
                </div>
                <div>
                  <i
                    class="fa fa-star plain"
                    v-for="emptyIndex in getRemainingRating"
                    :key="emptyIndex"
                  ></i>
                </div>
              </div>
            </div>
            <div class="rating" v-else>
              <b class="rating-number">0</b>
              <div class="d-flex">
                <div>
                  <i class="fa fa-star plain"></i>
                </div>
              </div>
            </div>
          </div>
          <a
            href="#"
            class="btn play-btn"
            @click.prevent="courseDetails(course.slug)"
          >
            <img src="../assets/play-icon.svg" class="svg" alt="" />
          </a>
          <div
            class="d-flex justify-content-between price-box align-items-center"
          >
            <h5 class="price-tag" v-if="course.price > 0">
              <span class="rupee">₹</span
              >{{
                Math.round(course.price - course.price * (course.offer / 100))
              }}
              <span v-if="course.offer > 0">({{ course.offer }} % off)</span>
              <del v-if="course.offer > 0">
                <span class="rupee">₹</span>{{ course.price }}
              </del>
            </h5>
            <h5 class="price-tag" v-else>
              Free
            </h5>
            <i
              class="fas fa-heart wishlist"
              @click="addWishList(course.id)"
              v-if="wishlistStatus == 1"
            ></i>
            <i class="far fa-heart" v-else @click="addWishList(course.id)"></i>
            <div
              class="card-action-div dropleft dropdown"
              v-if="course.purchase_id > 0"
            >
              <button
                class="btn cta-clear micro-cta"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src="../assets/dots-icon.svg" alt="" />
              </button>
              <div class="dropdown-menu">
                <div
                  class="dropdown-item"
                  @click="$emit('create-project', course.id)"
                >
                  Add Project
                </div>
                <div
                  class="dropdown-item"
                  @click="$emit('create-review', course.id)"
                >
                  Add Review
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import CourseService from "@/services/CourseService";
export default {
  name: "CourseCard",
  props: ["course"],
  data: function() {
    return {
      mutableCourse: this.course,
      wishlistStatus: this.course.wishlist_status
    };
  },
  computed: {
    getFloorRating: function() {
      return Math.floor(this.course.rating);
    },
    getRemainingRating: function() {
      let currentRating = Math.floor(this.course.rating);
      let remainingRating = 5 - currentRating;
      return remainingRating;
    },
    getRating: function() {
      return parseFloat(this.course.rating, 2).toFixed(1);
    }
  },
  methods: {
    courseDetails: function(slug) {
      this.$router
        .push({ name: "courseDetails", params: { slug: slug } })
        .catch(() => {});
    },
    addWishList: function(id) {
      if (this.$store.getters["auth/isLoggedIn"] != true) {
        this.$store.dispatch("auth/updateNextUrl", "courses");
        this.$router.push({ name: "Login" });
      }
      if (this.wishlistStatus == 1) {
        this.wishlistStatus = 0;
      } else {
        this.wishlistStatus = 1;
      }
      CourseService.addWishList({
        course_id: id
      })
        .then(result => {
          if (result.data.status == "success") {
            this.$toastr.s(result.data.message);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
.rating-number {
  font-size: 16px;
}
i.fas.fa-heart.wishlist {
  color: #644fa1;
}
</style>
