<template>
  <div>
    <section>
      <div class="container-fluid gallery">
        <div class="d-flex bd-highlight">
          <div class="custom-head flex-grow-1">
            <h2>Gallery</h2>
          </div>
          <div class="back-btn">
            <router-link :to="{ name: 'webinars' }" class="btn cta-primary"
              >Back to Events</router-link
            >
          </div>
        </div>
        <CoolLightBox
          :items="items"
          :index="index"
          :effect="'fade'"
          :fullScreen="true"
          @close="index = null"
        >
        </CoolLightBox>
        <div v-if="loader == true">
          <loader></loader>
        </div>
        <div v-else class="images-wrapper">
          <div class=" image-wrapper">
            <div
              class="image"
              v-for="(image, imageIndex) in items"
              :key="imageIndex"
              @click="index = imageIndex"
              :style="{ backgroundImage: 'url(' + image.src + ')' }"
            ></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import WebinarService from "@/services/WebinarService";
import Loader from "../components/Loader.vue";

export default {
  name: "EventGalleries",
  components: {
    CoolLightBox,
    Loader
  },
  data() {
    return {
      loader: true,
      index: null,
      items: []
    };
  },
  created() {
    this.getEventGalleries();
  },
  methods: {
    async getEventGalleries() {
      await WebinarService.getEventGalleries().then(output => {
        this.loader = false;
        if (output.data.status == "success") {
          this.items = output.data.galleries;
        }
      });
    }
  }
};
</script>
