import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import About from "../views/About.vue";
import Story from "../views/Story.vue";
import Form from "../views/Form.vue";
import auth from "../middleware/auth";
import unauthenticated from "../middleware/unauthenticated";
import instructor from "../middleware/instructor";
import PublicProfile from "../views/PublicProfile.vue";
import HostedCourseListing from "../views/HostedCourseListing.vue";
import CreateCourse from "../views/CreateCourse.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Questions from "../views/Questions.vue";
import CourseList from "../views/CourseList.vue";
import CourseDetails from "../views/CourseDetails.vue";
import Checkout from "../views/Checkout.vue";
import RecruiterRegistration from "../views/RecruiterRegistration.vue";
import Article from "../views/Article.vue";
import Membership from "../views/Membership.vue";
import CreateBlog from "../views/CreateBlog.vue";
import PaymentStatus from "../views/PaymentStatus.vue";
import BlogListing from "../views/BlogListing.vue";
import CoursePreview from "../views/CoursePreview.vue";
import Blog from "../views/Blog.vue";
import RecruiterWall from "../views/RecruiterWall.vue";
import ProfilePage from "../views/ProfilePage.vue";
import EmailVerification from "../views/EmailVerification.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Cms from "../views/Cms.vue";
import WebinarsList from "../views/WebinarsList.vue";
import Webinar from "../views/Webinar.vue";
import Gallery from "../views/Gallery.vue";
import Router from "vue-router";
import WebinarCheckout from "../views/WebinarCheckout.vue";
import EventBookingSuccess from "../views/EventBookingSuccess.vue";
import EventGalleries from "../views/EventGalleries.vue";
import Calendar from "../views/Calendar.vue";
import OfflineCourses from "../views/OfflineCourseList.vue";
import OfflineCourseDetails from "../views/OfflineCourseDetails.vue";
import OfflineCourseCheckout from "../views/OfflineCourseCheckout.vue";
import TheFinishingSchool from "../views/TheFinishingSchool.vue";
import EventAlbum from "../views/EventAlbum.vue";
import EventAlbumDetails from "../views/EventAlbumDetails.vue";

Vue.use(VueRouter);

const router = new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/about-us",
      name: "About",
      component: About,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/event/galleries",
      name: "EventAlbums",
      component: EventAlbum,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/event/gallery/:id",
      name: "EventAlbumDetails",
      component: EventAlbumDetails,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/enquiry",
      name: "Form",
      component: Form,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/our-story",
      name: "Story",
      component: Story,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/event-galleries",
      name: "EventGalleries",
      component: EventGalleries,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        middleware: [unauthenticated],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/password/forgot",
      name: "forgotPassword",
      component: ForgotPassword,
      meta: {
        middleware: [unauthenticated],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/password/reset",
      name: "resetPassword",
      component: ResetPassword,
      meta: {
        middleware: [unauthenticated],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/recruiter/register",
      name: "recruiterRegistration",
      component: RecruiterRegistration,
      meta: {
        middleware: [unauthenticated],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        middleware: [unauthenticated],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/profile/:name/:id",
      name: "publicProfile",
      component: PublicProfile,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/my-course",
      name: "hostedCourse",
      component: HostedCourseListing,
      meta: {
        middleware: [auth, instructor],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/course/create/:id?",
      name: "createCourse",
      component: CreateCourse,
      meta: {
        middleware: [auth, instructor],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/exams/:moduleId",
      name: "questions",
      component: Questions,
      meta: {
        middleware: [auth, instructor],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/courses",
      name: "courseList",
      component: CourseList,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/course/watch/:slug",
      name: "courseDetails",
      component: CourseDetails,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/course/preview/:slug",
      name: "coursePreview",
      component: CoursePreview,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/course/checkout/:slug",
      name: "checkout",
      component: Checkout,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/payment/success/:id",
      name: "paymentSuccess",
      component: PaymentStatus,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/event/checkout/:id",
      name: "WebinarCheckout",
      component: WebinarCheckout,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/event/booking/completed",
      name: "EventBookingSuccess",
      component: EventBookingSuccess,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/article/create/:articleId?",
      name: "createArticle",
      component: Article,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/membership",
      name: "Membership",
      component: Membership,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/blog/create/:id?",
      name: "createBlog",
      component: CreateBlog,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/blog/:slug",
      name: "blog",
      component: Blog,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/blogs",
      name: "BlogListing",
      component: BlogListing,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/RecruiterWall",
      name: "RecruiterWall",
      component: RecruiterWall,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/profile",
      name: "profilePage",
      component: ProfilePage,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/email/verification",
      name: "emailVerification",
      component: EmailVerification,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/verify/email",
      name: "verifyEmail",
      component: VerifyEmail,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/offline/courses",
      name: "offlineCourses",
      component: OfflineCourses,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/offline/course/:id",
      name: "OfflineCourseDetails",
      component: OfflineCourseDetails,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/offline/course/checkout/:id",
      name: "OfflineCourseCheckout",
      component: OfflineCourseCheckout,
      meta: {
        middleware: [auth],
        progress: {
          func: []
        }
      }
    },
    {
      path: "/events",
      name: "webinars",
      component: WebinarsList,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/webinar/:slug",
      name: "webinarDetails",
      component: Webinar,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/gallery",
      name: "gallery",
      component: Gallery,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/the-finishing-school",
      name: "theFinishingSchool",
      component: TheFinishingSchool,
      meta: {
        progress: {
          func: []
        }
      }
    },
    {
      path: "/:slug",
      name: "cms",
      component: Cms,
      meta: {
        progress: {
          func: []
        }
      }
    },

    { path: "*", component: PageNotFound, name: "PageNotFound" }
  ]
});
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = {
      from,
      next,
      router,
      to
    };
    const nextMiddleware = nextFactory(context, middleware, 1, to);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});
function nextFactory(context, middleware, index, to) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware, to });
  };
}

export default router;
