<template>
  <div class="nav-carrier">
    <div id="nav" class="continer-fluid nav-wrapper">
      <div class="container carrier_container">
        <nav class="navbar navbar-expand-sm navbar-light navbar-jw">
          <button
            ref="collapse_button"
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#appnav"
            aria-controls="appnav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <router-link class="navbar-brand" to="/" href="#">
            <img
              src="../assets/Ologylogo-color.png"
              @click="$refs.collapse_button.click()"
              alt="logo"
            />
          </router-link>
          <div class="collapse navbar-collapse" id="appnav">
            <ul class="nav navbar-nav ml-auto">
              <li @click="$refs.collapse_button.click()">
                <router-link
                  class="nav-item nav-link"
                  active-class="active"
                  :to="{ name: 'About' }"
                  >About us</router-link
                >
              </li>
              <li @click="$refs.collapse_button.click()">
                <router-link
                  class="nav-item nav-link"
                  active-class="active"
                  :to="{ name: 'Story' }"
                  >Our story</router-link
                >
              </li>
              <!-- <li @click="$refs.collapse_button.click()">
                <router-link
                  class="nav-item nav-link"
                  active-class="active"
                  :to="{ name: 'courseList' }"
                  >Courses</router-link
                >
              </li> -->
              <li @click="$refs.collapse_button.click()">
                <router-link
                  class="nav-item nav-link"
                  active-class="active"
                  :to="{ name: 'offlineCourses' }"
                  >Courses</router-link
                >
              </li>
              <li @click="$refs.collapse_button.click()">
                <router-link
                  class="nav-item nav-link"
                  active-class="active"
                  :to="{ name: 'webinars' }"
                  >Events</router-link
                >
              </li>
              <li @click="$refs.collapse_button.click()">
                <router-link
                  class="nav-item nav-link"
                  active-class="active"
                  :to="{ name: 'EventAlbums' }"
                >
                  Gallery</router-link
                >
              </li>
              <!-- <li @click="$refs.collapse_button.click()">
                <router-link
                  class="nav-item nav-link"
                  active-class="active"
                  :to="{ name: 'EventGalleries' }"
                  >Gallery</router-link
                >
              </li> -->
              <li @click="$refs.collapse_button.click()">
                <router-link
                  class="nav-item nav-link"
                  active-class="active"
                  :to="{ name: 'BlogListing' }"
                  >Blogs</router-link
                >
              </li>
              <li @click="$refs.collapse_button.click()">
                <router-link
                  class="btn cta-clear"
                  active-class="active"
                  :to="{ name: 'Form' }"
                  >Contact us</router-link
                >
              </li>
            </ul>
            <div
              v-if="this.$store.getters['auth/isLoggedIn'] != true"
              @click="$refs.collapse_button.click()"
            >
              <router-link class="btn cta-primary" :to="{ name: 'Login' }">
                Login
              </router-link>
            </div>
            <div class="menu-avatar-item" @click="popup" v-else>
              <router-link class="btn popover-action" to="">
                <div class="popover-user-imgwrap">
                  <img :src="image" alt="" />
                </div>
              </router-link>
              <div class="popover-content" id="hidden-element">
                <ul class="list-unstyled">
                  <li>
                    <router-link
                      class="action-link"
                      :to="{ name: 'profilePage' }"
                    >
                      {{ user.name | capitalize }}
                    </router-link>
                  </li>
                  <li v-if="isInstructor == 1">
                    <router-link
                      class="action-link"
                      :to="{ name: 'hostedCourse' }"
                    >
                      Host course
                    </router-link>
                  </li>
                  <li>
                    <a href="#" class="action-link" @click="logout">
                      Sign out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import CategoryService from "@/services/CategoryService";
export default {
  name: "Navbar",

  data: function() {
    return {
      image: "",
      name: "",
      isInstructor: "",
      categories: []
    };
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  computed: {
    user() {
      let user = JSON.parse(this.$store.state.auth.user);
      return user;
    }
  },
  created: function() {
    if (this.$store.getters["auth/isLoggedIn"] == true) {
      let user = JSON.parse(this.$store.state.auth.user);
      this.image = user.image;
      this.name = user.name;
      this.isInstructor = user.is_instructor;
    }
    this.getCategories();
  },
  updated: function() {
    if (this.$store.getters["auth/isLoggedIn"] == true) {
      let user = JSON.parse(this.$store.state.auth.user);
      this.image = user.image;
      this.name = user.name;
      this.isInstructor = user.is_instructor;
    }
  },
  methods: {
    logout: function() {
      AuthService.logout()
        .then(result => {
          this.$store.dispatch("auth/logout");
          this.$toastr.s(result.data.message);
          this.$router.push({ name: "Home" });
        })
        .catch(error => {
          this.error = error;
          this.$store.dispatch("auth/logout");
          this.$router.push({ name: "Home" });
        });
    },
    changeKeyword: function(keyword) {
      this.$router
        .push({ name: "courseList", query: { search: keyword } })
        .catch(() => {});
      // this.search = keyword;
      // this.resetPage();
    },
    async getCategories() {
      await CategoryService.getCategories({
        requestFor: "courseAvailableCategory"
      })
        .then(result => {
          this.categories = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    popup: function() {
      var popTrigger = document.getElementById("hidden-element");
      if (popTrigger.style.display == "none") {
        popTrigger.style.display = "block";
      } else {
        popTrigger.style.display = "none";
      }
    }
  }
};
</script>

<style scoped></style>
