<template>
  <div class="hosted-course">
    <div class="container-fluid hosted-course-wrap">
      <div class="container">
        <div
          class="add-new-action d-flex justify-content-between align-items-center"
        >
          <h5 class="color-1">Courses</h5>
          <h5>
            Host new course
            <router-link :to="{ name: 'createCourse' }" class="btn cta-add">
              <img src="../assets/plus-light.svg" width="15px" alt="" />
            </router-link>
          </h5>
        </div>

        <div class="row hosted-course__search-row justify-content-between">
          <div class="col-md-4">
            <div class="search-cta">
              <form class="form-inline">
                <input
                  class="form-control"
                  type="search"
                  v-model="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button class="btn cta-mini" type="submit">
                  <img class="svg" src="../assets/serach-icon.svg" alt="" />
                </button>
              </form>
            </div>
          </div>

          <div class="col-md-4">
            <div class="row listing-filter">
              <div class="col-md-6 col-xs-6  form-group  d-flex">
                <multiselect
                  v-model="statusValue"
                  :options="statusOptions"
                  :searchable="true"
                  :close-on-select="true"
                  label="text"
                  placeholder="Filter by"
                  track-by="text"
                  :show-labels="false"
                  @select="onSelectStatus"
                  @remove="onRemoveStatus"
                >
                </multiselect>
                <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
              </div>
              <div class="col-md-6 col-xs-6 form-group  d-flex">
                <multiselect
                  v-model="sortValue"
                  :options="sortOptions"
                  :searchable="true"
                  :close-on-select="true"
                  label="text"
                  track-by="text"
                  :show-labels="false"
                  placeholder="Sort by"
                  @select="onSelectSort"
                  @remove="onRemoveSort"
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="hosted-course-list__wrap">
          <div
            class="row"
            v-if="this.$store.state.course.isLoadingHostedCourse == true"
          >
            <Loader></Loader>
          </div>
          <div class="row">
            <div
              class="col-md-4"
              v-for="(course, index) in hostedCourse"
              :key="index"
            >
              <HostedCourseCard :course="course"></HostedCourseCard>
            </div>
          </div>
          <div
            class="row"
            v-if="
              hostedCourse.length == 0 &&
                this.$store.state.course.isLoadingHostedCourse == false
            "
          >
            <p class="no-course-found">No course found!</p>
          </div>
          <div
            class="d-flex flex-row-reverse bd-highlight"
            v-if="
              this.$store.state.course.isLoadingHostedCourse == false &&
                totalPages > 1
            "
          >
            <SlidingPagination
              :current="currentPage"
              :total="totalPages"
              @page-change="pageChangeHandler"
            >
            </SlidingPagination>
          </div>
        </div>
      </div>
    </div>
    <!-- short-dashboard-section -->
    <div class="container-fluid short-dashboard-wrap">
      <div class="container">
        <div class="user-info-head">
          <div class="user-img-wrap">
            <img
              src="../assets/testimonialimg-1.jpg"
              class="img-fluid"
              alt=""
            />
          </div>
          <p class="userinfo-title">Dashboard</p>
        </div>
        <div class="row user-stats-row">
          <div class="col-md-4">
            <div class="user-stat__wrap">
              <p>Total revenue <span class="stat-badge green">+30%</span></p>
              <h2>₹ 21,000</h2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="user-stat__wrap">
              <p>
                Course subscriptions <span class="stat-badge red">-10%</span>
              </p>
              <h2>2,000</h2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="user-stat__wrap">
              <p>Membership left</p>
              <h2>4 months</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopularCourses></PopularCourses>
  </div>
</template>

<script>
import HostedCourseCard from "@/components/HostedCourseCard.vue";
import CourseService from "@/services/CourseService";
import PopularCourses from "@/components/PopularCourses";
import SlidingPagination from "vue-sliding-pagination";
import Loader from "@/components/Loader.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "HostedCourseListing",
  components: {
    HostedCourseCard,
    SlidingPagination,
    Loader,
    PopularCourses,
    Multiselect
  },
  data: function() {
    return {
      hostedCourse: [],
      currentPage: 0,
      totalPages: 0,
      filter: {},
      search: "",
      statusValue: null,
      statusOptions: [
        { value: "", text: "Select Status" },
        { value: "1", text: "Active" },
        { value: "2", text: "Draft" },
        { value: "3", text: "Published" },
        { value: "4", text: "Approved" },
        { value: "5", text: "Rejected" },
        { value: "6", text: "Blocked" }
      ],
      sortValue: null,
      sortOptions: [
        { value: "", text: "Select sort field" },
        { value: "courses.created_at", text: "Latest Course", sort: "desc" },
        { value: "courses.created_at", text: "Old Course", sort: "asc" },
        { value: "courses.title", text: "Name A-Z", sort: "asc" },
        { value: "courses.title", text: "Name Z-A", sort: "desc" }
      ]
    };
  },
  created() {
    this.$store.dispatch("updateHostedCourseLoader", true);
    this.getHostedCourse(this.currentPage);
  },
  watch: {
    search(value) {
      this.filter["search"] = value;
      this.currentPage = 1;
      this.getHostedCourse();
    }
  },
  methods: {
    async getHostedCourse() {
      let page = this.currentPage;
      let filter = this.filter;
      await CourseService.getHostedCourse(page, filter)
        .then(result => {
          this.hostedCourse = result.data.data;
          this.$store.dispatch("updateHostedCourseLoader", false);
          this.totalPages = result.data.last_page;
          this.currentPage = result.data.current_page;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      this.getHostedCourse();
    },
    onSelectStatus: function(option) {
      this.filter["status"] = option.value;
      this.currentPage = 1;
      this.getHostedCourse();
    },
    onRemoveStatus: function() {
      this.filter["status"] = "";
      this.currentPage = 1;
      this.getHostedCourse();
    },
    onSelectSort: function(option) {
      this.filter["column"] = option.value;
      this.filter["sort"] = option.sort;
      this.currentPage = 1;
      this.getHostedCourse();
    },
    onRemoveSort: function() {
      this.filter["column"] = "";
      this.filter["sort"] = "";
      this.currentPage = 1;
      this.getHostedCourse();
    }
  }
};
</script>
<style scoped>
p.no-course-found {
  text-align: center;
  width: 100%;
  font-size: 20px;
  color: #212529;
}
</style>
