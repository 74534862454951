<template>
  <div class="about-section">
    <div class="form-outer">
      <form @submit.prevent="updateProfileDetails">
        <div class="form-row align-items-center">
          <div class="col-md-6 form-group">
            <label for="">First name</label>
            <input
              v-model="firstName"
              type="text"
              class="form-control"
              id="FirstName"
              placeholder=""
            />
            <div v-if="errors && errors.name" class="error-message">
              {{ errors.name[0] }}
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label for="">Last name</label>
            <input
              v-model="lastName"
              type="text"
              class="form-control"
              id="Lastname"
              placeholder=""
            />
            <div v-if="errors && errors.last_name" class="error-message">
              {{ errors.last_name[0] }}
            </div>
          </div>
        </div>
        <div class="form-row align-items-center">
          <div class="col-md-12 form-group">
            <label for="">Email</label>
            <input
              type="text"
              class="form-control"
              v-model="email"
              disabled
              id="email"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row align-items-center">
          <div class="col-md-12 form-group">
            <label for="">Phone</label>
            <input
              type="text"
              class="form-control"
              v-model="phone"
              disabled
              id="phon"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-row align-items-center">
          <div class="col-md-12 form-group">
            <label for=""> About you</label>
            <textarea
              v-model="description"
              type="text"
              class="form-control"
              id="address"
              placeholder=""
            />
            <div v-if="errors && errors.description" class="error-message">
              {{ errors.description[0] }}
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="fileUpload">Image</label>
              <div class="upload-hijack">
                <div class="d-flex">
                  <div>
                    <button for="" class="btn Upload-btn">
                      Upload
                      <img src="../../assets/upload-cloud.svg" alt="" />
                      <input
                        type="file"
                        ref="thumbnail"
                        id="fileUpload"
                        @change="uploadThumbnail"
                      />
                    </button>
                  </div>
                  <div class="preview-image-wrapper" v-if="preview != ''">
                    <img
                      :src="preview"
                      width="50"
                      class="preview-image img-fluid"
                    />
                    <a
                      for=""
                      class=" btn delete-btn"
                      title="remove image"
                      @click="removeImage"
                    >
                      <img src="@/assets/delete-icon.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right mb-4">
          <button
            type="button"
            class="btn cta-clear"
            data-toggle="modal"
            data-target="#changePasswordModal"
          >
            Change password
          </button>
        </div>
        <div class="cta-row text-right">
          <button
            type="submit"
            :disabled="disabledButton == true"
            class="btn cta-primary"
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
    <!-- change-password-modal -->
    <div
      class="modal fade"
      id="changePasswordModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="changePasswordModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="changePasswordModalLabel">
              Change password
            </h5>
          </div>
          <form @submit.prevent="updatePassword">
            <div class="modal-body">
              <div class="form-row align-items-center">
                <div class="col-md-6 form-group">
                  <label for="">Current password</label>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    id="currentPassword"
                    placeholder=""
                  />
                  <div v-if="errors && errors.password" class="error-message">
                    {{ errors.password[0] }}
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label for="">New password</label>
                  <input
                    v-model="newPassword"
                    type="password"
                    class="form-control"
                    id="newPassword"
                    placeholder=""
                  />
                  <div
                    v-if="errors && errors.new_password"
                    class="error-message"
                  >
                    {{ errors.new_password[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn cta-clear" data-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn cta-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import $ from "jquery";
export default {
  name: "AboutSection",
  data: function() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      description: "",
      thumbnail: "",
      preview: "",
      disabledButton: false,
      password: "",
      newPassword: "",
      errors: {}
    };
  },
  created() {
    this.getUserDetails();
  },
  methods: {
    async getUserDetails() {
      await AuthService.getUser()
        .then(result => {
          let userData = result.data.user;
          this.firstName = userData.name;
          this.lastName = userData.last_name;
          this.email = userData.email;
          this.phone = userData.phone;
          this.description = userData.description;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    uploadThumbnail() {
      this.thumbnail = this.$refs.thumbnail.files[0];
      this.createImage(this.thumbnail);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.preview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function() {
      document.querySelector("#fileUpload").value = "";
      this.preview = "";
      this.thumbnail = "";
    },
    updateProfileDetails: function() {
      this.disabledButton = true;
      const formData = new FormData();
      formData.append("name", this.firstName);
      formData.append("last_name", this.lastName);
      formData.append("description", this.description);
      if (this.thumbnail != "") {
        formData.append("image", this.thumbnail);
      }
      AuthService.updateUser(formData)
        .then(result => {
          if (result.data.status == "success") {
            this.$toastr.s("Details updated successfully");
            this.$parent.getUserDetails();
            this.disabledButton = false;
            this.errors = {};
          } else if (result.data.status == "validation_error") {
            this.errors = result.data.message || {};
            this.disabledButton = false;
          }
        })
        .catch(function() {
          this.disabledButton = false;
          // this.$toastr.e("Something went wrong");
        });
    },
    updatePassword: function() {
      const data = {
        password: this.password,
        new_password: this.newPassword
      };
      AuthService.updateUserPassword(data)
        .then(result => {
          if (result.data.status == "success") {
            this.$toastr.s("Password updated successfully");
            this.password = "";
            this.newPassword = "";
            $("#changePasswordModal").modal("hide");
          } else if (result.data.status == "error") {
            this.$toastr.e(result.data.message);
          } else if (result.data.status == "validation_error") {
            this.errors = result.data.message || {};
          }
        })
        .catch(error => {
          this.errors = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>

<style scoped></style>
