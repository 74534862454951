<template>
  <div>
    <div class="container-fluid banner-wrapper">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../assets/cover-1.jpg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="../assets/cover-2.jpg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="../assets/cover-3.jpg" class="d-block w-100" alt="..." />
          </div>
        </div>
      </div>
      <div class="top-content">
        <div class="container">
          <div class="banner-contents">
            <h3>Fempower <br />together.</h3>
            <!-- <CourseSearch></CourseSearch> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import CourseSearch from "@/components/CourseSearch.vue";

export default {
  name: "Header"
  //   components: {
  //     CourseSearch
  //   }
};
</script>

<style scoped></style>
