<template>
  <div class="module-upload">
    <div class="container-fluid">
      <div class="hostcourse-tab-wrapper container">
        <div
          class="add-new-action d-flex justify-content-between align-items-center"
        >
          <h5 class="color-1">Courses</h5>
          <button
            class="btn cta-primary ml-4 mb-2"
            v-if="modules.length > 0 && isPublished == false"
            @click="publishCourse"
          >
            Publish
          </button>
        </div>
        <div class="row upload-tab-row">
          <div class="col-md-3 ">
            <div class="tab-button__wrap">
              <h5 class="head">Contents</h5>
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="pills-detail-tab"
                    data-toggle="pill"
                    href="#pills-detail"
                    role="tab"
                    aria-controls="pills-detail"
                    aria-selected="true"
                    >Course details</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-module-tab"
                    data-toggle="pill"
                    href="#pills-module"
                    role="tab"
                    aria-controls="pills-module"
                    aria-selected="false"
                    ref="moduleBtn"
                    >Modules</a
                  >
                </li>
              </ul>
            </div>

            <div class="tab-resource__wrap">
              <h5 class="head">Resources</h5>
              <ul class="list-unstyled">
                <li v-if="courseId != ''">
                  <a class="resource-link" href="#" @click="showModal"
                    >Upload Resources</a
                  >
                </li>
                <li>
                  <router-link class="resource-link" to="#"
                    >Earning Revenue</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-9 tab-body__wrap">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-detail"
                role="tabpanel"
                aria-labelledby="pills-detail-tab"
              >
                <div class="tab-content__header">
                  <h5 class="color-1">Course details</h5>
                </div>
                <div class="module-info-form">
                  <div v-if="this.isCourseNotFound == true">
                    <p class="course-not-found">Course not found</p>
                  </div>
                  <div v-else-if="isCourseLoader == false">
                    <form
                      @submit.prevent="createCourse"
                      enctype="multipart/form-data"
                    >
                      <div class="form-row">
                        <div class="col-md-8 form-group">
                          <label for="courseName"
                            >Course name
                            <span class="input-tooltip"
                              ><i>i</i>Your course name should clearly map to
                              your class outline
                            </span>
                          </label>
                          <input
                            v-model="courseName"
                            type="text"
                            class="form-control"
                            id="courseName"
                            placeholder="ex: Photography beginner"
                          />
                          <div class="error-message">
                            {{ validation.firstError("courseName") }}
                          </div>
                          <div
                            v-if="errors && errors.title"
                            class="error-message"
                          >
                            {{ errors.title[0] }}
                          </div>
                        </div>
                        <div class="col-md-4 form-group">
                          <label for="categorySelect">Select category</label>
                          <multiselect
                            v-model="categoryValue"
                            :options="categoryOptions"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            placeholder="Select Category"
                            track-by="title"
                            group-values="subcategories"
                            group-label="title"
                            label="title"
                          ></multiselect>
                          <div class="error-message">
                            {{ validation.firstError("categoryValue") }}
                          </div>
                          <div
                            v-if="errors && errors.category_id"
                            class="error-message"
                          >
                            {{ errors.category_id[0] }}
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label for="courseDescription"
                            >Course Description</label
                          >
                          <ckeditor
                            :editor="editor"
                            v-model="description"
                            :config="editorConfig"
                          ></ckeditor>
                          <div class="error-message">
                            {{ validation.firstError("description") }}
                          </div>
                          <div
                            v-if="errors && errors.description"
                            class="error-message"
                          >
                            {{ errors.description[0] }}
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-12 form-group">
                          <label for="shortDescription"
                            >Short Description</label
                          >
                          <textarea
                            v-model="shortDescription"
                            class="form-control"
                            id="shortDescription"
                            placeholder="maximum 100 characters"
                          ></textarea>
                          <div class="error-message">
                            {{ validation.firstError("shortDescription") }}
                          </div>
                          <div
                            v-if="errors && errors.short_description"
                            class="error-message"
                          >
                            {{ errors.short_description[0] }}
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-8">
                          <label for="language">Keywords</label>
                          <multiselect
                            v-model="keywords"
                            :options="keywordOptions"
                            tag-placeholder="Add this as new keyword"
                            placeholder="Add keywords"
                            :multiple="true"
                            :taggable="true"
                            @tag="addKeyword"
                          >
                            <span slot="noOptions">Type key word here</span>
                          </multiselect>
                          <div class="error-message">
                            {{ validation.firstError("keywords") }}
                          </div>
                          <div
                            v-if="errors && errors.keywords"
                            class="error-message"
                          >
                            {{ errors.keywords[0] }}
                          </div>
                        </div>
                        <div class="col-md-4 form-group">
                          <label for="price">Duration</label>
                          <input
                            v-model="duration"
                            type="text"
                            class="form-control"
                            id="duration"
                            placeholder="ex: 1 hour 44 minutes"
                          />
                          <div class="error-message">
                            {{ validation.firstError("duration") }}
                          </div>
                          <div
                            v-if="errors && errors.duration"
                            class="error-message"
                          >
                            {{ errors.duration[0] }}
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-8">
                          <label for="language">Select languages</label>
                          <multiselect
                            v-model="languageValue"
                            :options="languageOptions"
                            :searchable="true"
                            :close-on-select="true"
                            label="language"
                            :show-labels="false"
                            :multiple="true"
                            track-by="id"
                            placeholder="Select Languages"
                            @select="onSelect"
                            @remove="onRemove"
                          >
                          </multiselect>
                          <div class="error-message">
                            {{ validation.firstError("languageValue") }}
                          </div>
                          <div
                            v-if="errors && errors.language"
                            class="error-message"
                          >
                            {{ errors.language[0] }}
                          </div>
                        </div>
                        <div class="col-md-4 form-group">
                          <label for="price">Select price</label>
                          <multiselect
                            v-model="priceValue"
                            :options="priceOptions"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            track-by="title"
                            label="title"
                            :custom-label="titleWithPrice"
                            placeholder="Select Price"
                          ></multiselect>
                          <div class="error-message">
                            {{ validation.firstError("priceValue") }}
                          </div>
                          <div
                            v-if="errors && errors.price_id"
                            class="error-message"
                          >
                            {{ errors.price_id[0] }}
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col">
                          <div class="form-group">
                            <label for="fileUpload">Course thumbnail</label>
                            <div class="upload-hijack">
                              <div class="d-flex">
                                <div>
                                  <button for="" class="btn Upload-btn">
                                    Upload
                                    <img
                                      src="../assets/upload-cloud.svg"
                                      alt=""
                                    />
                                    <input
                                      type="file"
                                      ref="thumbnail"
                                      id="fileUpload"
                                      @change="uploadThumbnail"
                                    />
                                  </button>
                                  <div class="error-message">
                                    {{ validation.firstError("thumbnail") }}
                                  </div>
                                  <div
                                    v-if="errors && errors.image"
                                    class="error-message"
                                  >
                                    {{ errors.image[0] }}
                                  </div>
                                </div>
                                <div
                                  class="preview-image-wrapper"
                                  v-if="preview != ''"
                                >
                                  <img
                                    :src="preview"
                                    class="preview-image img-fluid"
                                  />
                                  <a
                                    for=""
                                    class=" btn delete-btn"
                                    title="remove image"
                                    @click="removeImage"
                                  >
                                    <img
                                      src="../assets/delete-icon.svg"
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </div>
                              <div class="error-message">
                                {{ validation.firstError("courseImage") }}
                              </div>
                              <span class="input-tooltip ml-2"
                                ><i>i</i>Image width between 900px to 1000px and
                                height between 450 px to 600 px
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input type="submit" ref="submit" style="display:none" />
                    </form>
                  </div>
                  <div v-else>
                    <Loader></Loader>
                  </div>
                </div>
              </div>
              <!-- //module-section-starts -->
              <div
                class="tab-pane fade show "
                id="pills-module"
                role="tabpanel"
                aria-labelledby="pills-module-tab"
              >
                <div class="tab-content__header">
                  <h5 class="color-1">Modules</h5>
                </div>
                <div class="module-card-wrap">
                  <!-- <div class="d-flex justify-content-end mb-3">
                    <p>
                      Add new module
                      <button
                        to="/ModuleUpload"
                        href="#"
                        class="btn cta-add"
                        data-toggle="modal"
                        data-target="#add-moduleModal"
                      >
                        <img
                          src="../assets/plus-light.svg"
                          width="15px"
                          alt=""
                        />
                      </button>
                    </p>
                  </div> -->
                  <div class="row module-wrap-row">
                    <div class="col-md-12" v-if="modules.length == 0">
                      <div class="placeholder module">
                        <h5>ADD MODULES</h5>
                        <p>Course should be combined as multiple modules</p>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="isLoadingModules == true">
                      <Loader></Loader>
                    </div>
                    <div
                      class="col-md-12"
                      v-for="(module, moduleIndex) in modules"
                      :key="moduleIndex"
                    >
                      <ModuleCard
                        :module="module"
                        :isPublished="isPublished"
                      ></ModuleCard>
                    </div>
                    <div
                      class="col-md-12"
                      v-if="isLoadingModules == false && modules.length == 0"
                    >
                      <p class="np-module">No module found!</p>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-end mb-3"
                    v-if="isPublished == false"
                  >
                    <p>
                      Add new module
                      <button
                        to="/ModuleUpload"
                        href="#"
                        class="btn cta-add"
                        data-toggle="modal"
                        data-target="#add-moduleModal"
                      >
                        <img
                          src="../assets/plus-light.svg"
                          width="15px"
                          alt=""
                        />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--------add Module Modal-start -->
    <div
      class="modal fade add-module-wrap"
      id="add-moduleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="add-moduleModalLabel"
      aria-hidden="true"
    >
      <form @submit.prevent="createModule">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title color-1" id="add-moduleModalLabel">
                Create new module
              </h5>
            </div>
            <div class="modal-body">
              <div class="form-row">
                <div class="col-md-8 form-group">
                  <label for="moduleName">
                    Module name
                  </label>
                  <input
                    v-model="moduleName"
                    type="text"
                    class="form-control"
                    id="moduleName"
                    placeholder="ex: Photography beginner"
                  />
                  <div class="error-message">
                    {{ validation.firstError("moduleName") }}
                  </div>
                </div>
                <div class="col-md-4 form-group align-items-center">
                  <label for="categorySelect" class="mr-3">Module type</label>
                  <multiselect
                    v-model="moduleType"
                    :options="moduleTypeOptions"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    track-by="title"
                    label="title"
                    placeholder="Select type"
                  >
                  </multiselect>
                  <div class="error-message">
                    {{ validation.firstError("moduleType") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn cta-clear"
                ref="close"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                :disabled="disabledButton == true"
                class="btn cta-primary"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="container-fluid module-upload-wrap">
      <div class="container"></div>
    </div>
    <div class="container save-wrapper text-right">
      <button
        class="btn cta-clear"
        :disabled="disabledButton == true"
        @click="submitForm"
        v-if="isPublished == false"
      >
        <span v-if="disabledButton == false">Save as draft</span>
        <span v-else>Please Wait..</span>
      </button>
    </div>
    <!-- <PopularCourses></PopularCourses> -->
    <div
      class="modal fade add-subtitle-wrap"
      id="ResourceModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="subtitleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="subtitleModalLabel">Upload Resource</h5>
          </div>
          <form @submit.prevent="uploadResource">
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Resource Title"
                    v-model="resourceTitle"
                  />
                  <div class="error-message">
                    {{ validation.firstError("resourceTitle") }}
                  </div>
                  <div
                    v-if="resourceErrors && resourceErrors.title"
                    class="error-message"
                  >
                    {{ resourceErrors.title[0] }}
                  </div>
                </div>
                <div class="col-md-12">
                  <input
                    type="file"
                    id="fileUpload"
                    ref="resourceFile"
                    @change="selectResourceFile"
                  />
                  <div class="error-message">
                    {{ validation.firstError("resourceFile") }}
                  </div>
                  <div
                    v-if="resourceErrors && resourceErrors.file"
                    class="error-message"
                  >
                    {{ resourceErrors.file[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                ref="closeUpload"
                class="btn cta-clear"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                :disabled="disableResourceButton == true"
                class="btn cta-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleCard from "@/components/ModuleCard.vue";
import Multiselect from "vue-multiselect";
import CategoryService from "@/services/CategoryService";
import LanguageService from "@/services/LanguageService";
import PriceService from "@/services/PriceService";
import CourseService from "@/services/CourseService";
import ModuleService from "@/services/ModuleService";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SimpleVueValidation from "simple-vue-validator";
import Loader from "@/components/Loader.vue";
const Validator = SimpleVueValidation.Validator;
import UploadAdapter from "@/adaptors/UploadAdapter";
import $ from "jquery";

export default {
  name: "CreateCourse",
  components: {
    ModuleCard,
    Multiselect,
    ckeditor: CKEditor.component,
    Loader
  },
  data() {
    return {
      languageValue: [],
      languageOptions: [],
      priceValue: "",
      priceOptions: [],
      categoryValue: "",
      categoryOptions: [],
      editor: ClassicEditor,
      description: "",
      editorConfig: {
        extraPlugins: [this.uploader]
      },
      options: [],
      value: "",
      shortDescription: "",
      courseName: "",
      thumbnail: "",
      selectedLanguage: [],
      preview: "",
      keywordOptions: [],
      keywords: [],
      duration: "",
      disabledButton: false,
      courseId: "",
      errors: {},
      isCourseLoader: false,
      isCourseNotFound: false,
      modules: [],
      moduleName: "",
      moduleType: "",
      moduleTypeOptions: [],
      isLoadingModules: false,
      isPublished: false,
      resourceErrors: {},
      resourceFile: "",
      resourceTitle: "",
      disableResourceButton: false
    };
  },
  validators: {
    courseName: function(value) {
      return Validator.value(value).required("Please enter course title");
    },
    categoryValue: function(value) {
      return Validator.value(value).required("Please select a category");
    },
    description: function(value) {
      return Validator.value(value).required("Please enter course description");
    },
    languageValue: function(value) {
      return Validator.value(value).required("Please select a language");
    },
    shortDescription: function(value) {
      return Validator.value(value)
        .required("Please enter a short description")
        .minLength(2)
        .maxLength(100);
    },
    priceValue: function(value) {
      return Validator.value(value).required("Please select price");
    },
    "thumbnail, courseId": function(thumbnail, courseId) {
      if (courseId !== "") {
        return;
      } else {
        return Validator.value(thumbnail).required("Select a thumbnail");
      }
    },
    keywords: function(value) {
      return Validator.value(value).required("Please enter keywords");
    },
    duration: function(value) {
      return Validator.value(value).required("Please enter course duration");
    },
    moduleName: function(value) {
      return Validator.value(value).required("Please enter module name");
    },
    moduleType: function(value) {
      return Validator.value(value).required("Please select a type");
    },
    resourceTitle: function(value) {
      return Validator.value(value).required("Please enter resource title");
    },
    resourceFile: function(value) {
      return Validator.value(value).required("Please select a file");
    }
  },
  created: function() {
    this.getCategories();
    this.getLanguages();
    this.getPrice();
    this.getModuleTypes();
    if (typeof this.$route.params.id === "undefined") {
      this.courseId = "";
    } else {
      this.courseId = this.$route.params.id;
      this.getCourse(this.courseId);
      this.getModules();
      this.isCourseLoader = true;
      this.isLoadingModules = true;
    }
  },
  methods: {
    submitForm: function() {
      this.$refs.submit.click();
    },
    onSelect(option) {
      this.selectedLanguage.push(option.id);
    },
    onRemove(option) {
      console.log(this.selectedLanguage);
      const index = this.selectedLanguage.indexOf(option.id);
      if (index > -1) {
        this.selectedLanguage.splice(index, 1);
      }
    },
    async getCategories() {
      await CategoryService.getCategories()
        .then(result => {
          this.categoryOptions = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getLanguages() {
      await LanguageService.getLanguages()
        .then(result => {
          this.languageOptions = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getPrice() {
      await PriceService.getPrice()
        .then(result => {
          this.priceOptions = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getModuleTypes() {
      await ModuleService.getModuleTypes()
        .then(result => {
          this.moduleTypeOptions = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getCourse(id) {
      await CourseService.getCourse({ course_id: id })
        .then(result => {
          if (typeof result.data.id !== "undefined") {
            this.courseName = result.data.title;
            this.description = result.data.description;
            this.shortDescription = result.data.short_description;
            this.duration = result.data.duration;
            this.priceValue = this.priceOptions.find(
              priceOptions => priceOptions.id === result.data.price_id
            );
            for (var i = 0; i < result.data.languages.length; i++) {
              var obj = result.data.languages[i];
              let selectedLanguage = this.languageOptions.find(
                languageOptions => languageOptions.id === obj.language_id
              );
              this.languageValue.push(selectedLanguage);
              this.selectedLanguage.push(obj.language_id);
            }
            let keywords = result.data.keywords;
            let keywordsArray = keywords.split(",");
            for (var j = 0; j < keywordsArray.length; j++) {
              this.addKeyword(keywordsArray[j]);
            }
            for (var index = 0; index < this.categoryOptions.length; index++) {
              let category = this.categoryOptions[index];
              let subcategories = category.subcategories;
              let categoryValue = subcategories.find(
                subcategories => subcategories.id === result.data.category_id
              );
              if (typeof categoryValue !== "undefined") {
                this.categoryValue = categoryValue;
                break;
              }
            }
            if (result.data.status == 3 || result.data.status == 4) {
              this.isPublished = true;
            }
            this.isCourseLoader = false;
            this.isCourseNotFound = false;
          } else {
            this.isCourseLoader = false;
            this.isCourseNotFound = true;
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getModules() {
      await ModuleService.getModules({ course_id: this.courseId })
        .then(result => {
          this.modules = result.data;
          this.isLoadingModules = false;
        })
        .catch(error => {
          this.isLoadingModules = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    titleWithPrice({ title, price }) {
      return `${title} — (₹ ${price})`;
    },
    createCourse: function() {
      const fields = [
        "courseName",
        "categoryValue",
        "description",
        "languageValue",
        "shortDescription",
        "priceValue",
        "thumbnail",
        "keywords",
        "duration"
      ];
      this.$validate(fields).then(success => {
        if (success) {
          this.disabledButton = true;
          const formData = new FormData();
          formData.append("title", this.courseName);
          formData.append("category_id", this.categoryValue.id);
          formData.append("price_id", this.priceValue.id);
          formData.append("description", this.description);
          formData.append("short_description", this.shortDescription);
          formData.append("language", JSON.stringify(this.selectedLanguage));
          formData.append("duration", this.duration);
          formData.append("keywords", this.keywords);
          formData.append("id", this.courseId);
          if (this.thumbnail != "") {
            formData.append("image", this.thumbnail);
          }
          CourseService.saveCourse(formData)
            .then(result => {
              this.disabledButton = false;
              if (result.data.status == "success") {
                let id = result.data.course_id;
                this.courseId = id;
                this.$router
                  .push({ name: "createCourse", params: { id: id } })
                  .catch(() => {});
                this.errors = {};
                this.$toastr.s(result.data.message);
                this.$refs.moduleBtn.click();
              } else if (result.data.status == "validation_error") {
                this.errors = result.data.message || {};
              }
            })
            .catch(function() {
              this.disabledButton = false;
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    async createModule() {
      const fields = ["moduleName", "moduleType"];
      await this.$validate(fields).then(success => {
        if (success) {
          const data = {
            title: this.moduleName,
            section_type_id: this.moduleType.id,
            course_id: this.courseId
          };
          this.disabledButton = true;
          ModuleService.saveModule(data)
            .then(result => {
              if (result.data.status == "validation_error") {
                this.$toastr.e("Please create a course before module");
              } else {
                this.disabledButton = false;
                this.$toastr.s(result.data.message);
                this.getModules();
                this.moduleName = "";
                this.moduleType = "";
                this.$refs.close.click();
              }
            })
            .catch(error => {
              this.disabledButton == false;
              this.error = error;
              this.$toastr.e(error);
            });
        }
      });
    },
    uploadThumbnail() {
      this.thumbnail = this.$refs.thumbnail.files[0];
      this.createImage(this.thumbnail);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.preview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function() {
      document.querySelector("#fileUpload").value = "";
      this.preview = "";
      this.thumbnail = "";
    },
    addKeyword(keyword) {
      this.keywordOptions.push(keyword);
      this.keywords.push(keyword);
    },
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new UploadAdapter(loader);
      };
    },
    publishCourse: function() {
      this.$swal({
        title: "Publish Course",
        text:
          "After publish you can not edit your content.Please verify your content before publishing",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Publish"
      }).then(result => {
        if (result.isConfirmed) {
          CourseService.publishCourse({ course_id: this.courseId })
            .then(result => {
              if (result.data.status == "success") {
                this.$toastr.s("Course published successfully");
                this.$router.push({ name: "hostedCourse" }).catch(() => {});
              }
            })
            .catch(error => {
              this.error = error;
              this.$toastr.e(error);
            });
        }
      });
    },
    uploadResource: function() {
      const fields = ["resourceTitle", "resourceFile"];
      this.$validate(fields).then(success => {
        if (success) {
          this.disableResourceButton = true;
          const formData = new FormData();
          formData.append("title", this.resourceTitle);
          formData.append("file", this.resourceFile);
          formData.append("course_id", this.courseId);
          CourseService.uploadResource(formData)
            .then(result => {
              this.disableResourceButton = false;
              if (result.data.status == "validation_error") {
                this.resourceErrors = result.data.message || {};
              } else if (result.data.status == "success") {
                this.errors = {};
                $("#ResourceModal").modal("hide");
                this.$toastr.s(result.data.message);
                this.resourceTitle = "";
                this.resourceFile = "";
              }
            })
            .catch(function() {
              this.disableResourceButton = false;
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    selectResourceFile: function() {
      this.resourceFile = this.$refs.resourceFile.files[0];
    },
    showModal: function() {
      $("#ResourceModal").modal("show");
    }
  }
};
</script>
<style scoped>
.preview-image-wrapper {
  margin-left: 20px;
}
img.preview-image.img-fluid {
  max-height: 60px;
}
.error-message {
  color: red;
}
</style>
