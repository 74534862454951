<template>
  <div class="form-outer">
    <div class="container-fluid contact-wrapper">
      <div class="container">
        <div class="custom-head">
          <h2>
            Enquiry
          </h2>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-wrapper">
              <form id="contact_form" @submit.prevent="saveEnquiry">
                <div class="form-group row">
                  <div class="col-md-6">
                    <label for="contact_name">Name<span>*</span></label>
                    <input
                      type="name"
                      class="form-control"
                      v-model="name"
                      id="contact_name"
                      placeholder="Enter Full name"
                    />
                    <div class="error-message">
                      {{ validation.firstError("name") }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="email_id">Email address<span>*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="email"
                      id="email_id"
                      aria-describedby="contactEmail"
                      placeholder="Enter email"
                    />
                    <div class="error-message">
                      {{ validation.firstError("email") }}
                    </div>
                    <!-- <small id="contactEmail" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <label for="contact_phone">Phone<span>*</span></label>
                    <input
                      type="name"
                      class="form-control"
                      v-model="phone"
                      id="contact_phone"
                      placeholder="Phone Number"
                    />
                    <div class="error-message">
                      {{ validation.firstError("phone") }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="enquiry_for">Enquiry For<span>*</span></label>

                    <select
                      class="form-control"
                      v-model="enquiryFor"
                      id="enquiry_for"
                    >
                      <option value="">Select Enquiry</option>
                      <option value="course">Courses</option>
                      <option value="events">Events</option>
                      <option value="others">Others</option>
                    </select>
                    <div class="error-message">
                      {{ validation.firstError("enquiryFor") }}
                    </div>
                  </div>
                </div>

                <div class="form-group row"></div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="message">Message</label>
                    <textarea
                      class="form-control"
                      v-model="message"
                      id="message"
                      rows="2"
                      placeholder="Your message"
                    ></textarea>
                    <div class="error-message">
                      {{ validation.firstError("message") }}
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn cta-primary contact-us-form">
                  <span
                    id="contact_loader"
                    class="load load-active loader"
                  ></span>
                  Submit
                </button>
              </form>

              <p id="contact_alert" class="alert-message"></p>
            </div>
          </div>

          <div class="col-md-12">
            <div class="action-wrapper">
              <div class="action-wrapper-card">
                <i class="fas fa-phone-alt"></i>
                <a href="tel:+919677147777">+91 9176468468</a>
              </div>
              <div class="action-wrapper-card">
                <i class="fas fa-envelope"></i>
                <a href="mailto:info@vblive.co">hello@ologywomen.com</a>
              </div>
              <!-- <p>
                Contact number: <a href="tel:+919677147777">+91 9176468468</a>
              </p>
              <p>
                e-mail: <a href="mailto:info@vblive.co">hello@ologywomen.com</a>
              </p> -->
              <!-- <div class="address-box">
                <p>
                  Velammal Bodhi Live <br />
                  Floor – IV, 995C, H Block,<br />
                  Second Avenue, Anna Nagar West <br />
                  Chennai-40.
                </p>
              </div> -->
              <!-- <div class="social-wrap d-flex">
                <a href="#"><i class="ti-facebook"></i></a>
                <a href="#"><i class="ti-twitter-alt"></i></a>
                <a href="#"><i class="ti-linkedin"></i></a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import EnquiryService from "@/services/EnquiryService";
const Validator = SimpleVueValidation.Validator;
export default {
  name: "Form",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      enquiryFor: "",
      message: ""
    };
  },
  validators: {
    name: function(value) {
      return Validator.value(value).required("Please enter your first name");
    },
    email: function(value) {
      return Validator.value(value)
        .required("Please enter your email")
        .email("Please enter a valid email");
    },
    phone: function(value) {
      return Validator.value(value)
        .required("Please enter your phone")
        .digit("Please enter a valid number")
        .minLength(10)
        .maxLength(15);
    },
    enquiryFor: function(value) {
      return Validator.value(value).required("Please select enquiry type");
    },
    message: function(value) {
      return Validator.value(value).required("Please enter your message");
    }
  },
  methods: {
    saveEnquiry: function() {
      this.$validate().then(success => {
        if (success) {
          const enquiry = {
            name: this.name,
            email: this.email,
            phone: this.phone,
            enquiry_for: this.enquiryFor,
            description: this.message
          };
          EnquiryService.saveEnquiry(enquiry)
            .then(result => {
              this.$toastr.s(result.data.message);
            })
            .catch();
        }
      });
    }
  }
};
</script>
