import { render, staticRenderFns } from "./WalletSection.vue?vue&type=template&id=76bf1ff0&scoped=true&"
import script from "./WalletSection.vue?vue&type=script&lang=js&"
export * from "./WalletSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76bf1ff0",
  null
  
)

export default component.exports