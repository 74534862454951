<template>
  <div class="comment-card">
    <div class="comment-card__head d-flex justify-content-between">
      <div class="profile-info-wrap">
        <span class="thumb-img">
          <img class="img-fluid" :src="discussion.image" alt="Card image cap" />
        </span>
        <a href="#">{{ discussion.name }}</a>
      </div>
      <p>
        Posted on: <span>{{ discussion.posted_on }}</span>
      </p>
    </div>

    <div class="comment__content">
      <p>
        {{ discussion.discussion }}
      </p>
      <div class="card-footer">
        <ul class="list-unstyled">
          <li>
            <button class="btn "><i class="fas fa-comment-alt"></i></button>
            {{ reply }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentCard",
  props: ["discussion", "reply"]
};
</script>

<style scoped></style>
