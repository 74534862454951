import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import VueProgressBar from "vue-progressbar";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "./style/style.scss";
import VueToastr from "vue-toastr";
import SimpleVueValidation from "simple-vue-validator";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Loading from "vuejs-loading-screen";

Vue.use(CoolLightBox);

Vue.use(VueToastr, {
  defaultProgressBar: true,
  defaultCloseOnHover: true,
  defaultPreventDuplicates: true,
  defaultPosition: "toast-bottom-right"
});

Vue.use(Loading, {
  bg: "#0000001c",
  icon: "fas fa-spinner",
  size: 4,
  icon_color: "#00babd"
});

Vue.use(SimpleVueValidation);
Vue.use(VueSweetalert2);
const progressBarOption = {
  color: "#644fa1",
  failedColor: "#874b4b",
  thickness: "2px",
  transition: {
    speed: "1s",
    opacity: "2s",
    termination: 1000
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

Vue.use(VueProgressBar, progressBarOption);

Vue.config.productionTip = false;
new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount("#app");
