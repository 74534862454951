import Http from "@/services/Http";
export default {
  getWebinars(data, page) {
    return Http().post("webinars?page=" + page, data);
  },
  getWebinar(data) {
    return Http().post("webinar", data);
  },
  getLatestEvent(data) {
    return Http().post("webinar/latest", data);
  },
  getEventGalleries() {
    return Http().post("get/event/galleries");
  },
  getEventDates() {
    return Http().post("get/event/dates");
  }
};
