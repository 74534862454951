<template>
  <div class="home">
    <Header></Header>
    <!-- <CourseCategories></CourseCategories> -->
    <!-- about-section -->
    <div class="wedo-wrapper container-fluid p-0">
      <div class="row no-gutters">
        <div class="col-md-6 _left-col">
          <div class="_content">
            <div class="custom-head">
              <h1>About Us</h1>
            </div>
            <h4>
              We understand that in uplifting others we uplift ourselves. <br />
              <b>Fempower Together.</b>
            </h4>
            <p>
              Across the globe, in most societies, women have for long been
              bound by ideas and conditioning rooted in patriarchy. But every
              now and then there comes a woman—or a girl— who refuses to be held
              back.
            </p>
            <router-link :to="{ name: 'About' }">Read more</router-link>
          </div>
        </div>
        <div class="col-md-6 _right-col">
          <div class="_content">
            <div class="custom-head">
              <h2>
                Notice Board
              </h2>
            </div>
            <router-link
              v-if="latestCourse.title != undefined"
              class="block-card"
              :to="{
                name: 'courseDetails',
                params: { slug: latestCourse.slug }
              }"
            >
              <div class="img-wrap" v-if="latestCourse.title != undefined">
                <img :src="latestCourse.image" alt="" />
                <div class="body">
                  <p>{{ latestCourse.title }}</p>
                </div>
              </div>
            </router-link>
            <div class="img-wrap" v-else>
              <div v-if="loader == true">
                <loader></loader>
              </div>
              <div
                v-else
                id="carouselExampleCaptions"
                class="carousel slide carousel-fade"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    v-for="(notice, noticeIndex) in notices"
                    :key="noticeIndex"
                    data-target="#carouselExampleCaptions"
                    :data-slide-to="noticeIndex"
                    :class="noticeIndex === 0 ? 'active' : ''"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div
                    v-for="(notice, noticeIndex) in notices"
                    :key="noticeIndex"
                    class="carousel-item"
                    :class="noticeIndex === 0 ? 'active' : ''"
                  >
                    <img
                      :src="notice.image"
                      class="d-block w-100"
                      alt="Notice-img"
                    />
                    <div class="carousel-caption d-none d-md-block">
                      <h5>{{ notice.title }}</h5>
                      <p>
                        {{ notice.short_description }}
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  v-show="notices.length > 1"
                  class="carousel-control-prev"
                  type="button"
                  data-target="#carouselExampleCaptions"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-target="#carouselExampleCaptions"
                  data-slide="next"
                  v-show="notices.length > 1"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </button>
              </div>
              <div v-if="loader == false && notices.length == 0">
                <div class="img-wrap">
                  <h4>Coming Soon</h4>
                </div>
              </div>
            </div>
            <router-link
              v-if="latestCourse.title != undefined"
              :to="{ name: 'courseList' }"
              class="btn"
              >VIEW ALL</router-link
            >
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-md-6 _story-col">
          <div class="_content">
            <div class="custom-head">
              <h1>Our Story</h1>
            </div>
            <h4>
              Meet <br />
              Mrs.Geethanjali Sasikumar,
            </h4>
            <h5>Our story begin with her</h5>
            <p>
              Perception is not reality - it’s a lesson learnt early on. <br />
              <br />

              Born into an affluent family, it was assumed that she always had
              an easy life—devoid of turbulent times. ’While money can make life
              comfortable, it doesn’t guarantee happiness, or the freedom to
              live life on one’s own terms,’ she states.
            </p>
            <router-link :to="{ name: 'Story' }">Read more</router-link>
          </div>
        </div>
        <div class="col-md-6 _story-img">
          <div class="_content">
            <div class="img-wrap">
              <img src="../assets/founder-full.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- //popular-courses -->
    <PopularCourses></PopularCourses>

    <div
      class="row"
      v-if="this.$store.state.blog.isLoadingFeaturedBlog == true"
    >
      <Loader></Loader>
    </div>

    <div
      class="container-fluid blogs-wrapper"
      v-if="
        this.$store.state.blog.isLoadingFeaturedBlog == false &&
          blogs.length > 0
      "
    >
      <div class="container">
        <div class="custom-head"><h1>Blogs</h1></div>
        <div class="row">
          <BlogCard
            v-for="(blog, blogIndex) in blogs"
            :key="blogIndex"
            :blog="blog"
          ></BlogCard>
        </div>
        <div class="text-right py-3">
          <router-link :to="{ name: 'BlogListing' }" class=""
            >View more</router-link
          >
        </div>
      </div>
    </div>

    <!-- testimonial-section -->
    <div class="container-fluid testimonial-wrap">
      <div class="testimonial-content">
        <div class="row no-gutters align-items-center">
          <div class="col-md-6 _left-col">
            <div
              id="TestimonialCarousel"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  v-for="(testimonial, testimonialIndex) in testimonials"
                  :key="testimonialIndex"
                  data-target="#TestimonialCarousel"
                  :data-slide-to="testimonialIndex"
                  :class="testimonialIndex == 0 ? 'active' : ''"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  v-for="(testimonial, testimonialIndex) in testimonials"
                  :key="testimonialIndex"
                  class="carousel-item"
                  :class="testimonialIndex == 0 ? 'active' : ''"
                >
                  <div class="testimonial-card">
                    <p>
                      {{ testimonial.testimonial }}
                    </p>
                    <div class="credit-line">
                      <p>{{ testimonial.name }} {{ testimonial.last_name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 _right-col">
            <img src="../assets/testmonial-sillt.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container quick-cta-wrapper">
      <div class="quick-cta-contents">
        <h2>Start learning</h2>
        <router-link
          class="btn cta-primary"
          active-class="active"
          :to="{ name: 'Form' }"
          >Contact us</router-link
        >
      </div>
    </div> -->
    <div class="join-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="_content">
              <div class="custom-head">
                <h2>
                  Join Us
                </h2>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="_content">
              <p>
                In joining us here, besides connecting with like-minded women
                and girls in this vibrant community, you stand to gain other
                perks. Like access to some of our premium events at concessional
                rates, the opportunity to showcase your skills or work, expand
                your social and professional network, and the privilege to
                mentor others if you are expert in your niche among others.
              </p>
              <h3>
                Be seen, be heard, be connected.
              </h3>
              <router-link :to="{ name: 'Register' }" class="btn"
                >Sign Up</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import CourseService from "@/services/CourseService";
import PopularCourses from "@/components/PopularCourses.vue";
import "hooper/dist/hooper.css";
import BlogCard from "@/components/BlogCard.vue";
import CategoryService from "@/services/CategoryService";
import TestimonialService from "@/services/TestimonialService";
import BlogService from "@/services/BlogService";
import Loader from "@/components/Loader.vue";
export default {
  name: "Home",
  components: {
    Header,
    PopularCourses,
    BlogCard,
    Loader
  },
  data: function() {
    return {
      loader: true,
      error: "",
      categories: [],
      testimonials: [],
      blogs: [],
      categoryId: "",
      latestCourse: "",
      notices: []
    };
  },
  created: function() {
    this.getTestimonials();
    this.getFeaturedBlogs();
    this.getLatestCourse();
    this.getNotices();
  },
  methods: {
    async getFeaturedCategory() {
      await CategoryService.getFeaturedCategory()
        .then(result => {
          this.categories = result.data.data;
          if (this.categories.length > 0) {
            const data = {
              category_id: this.categories[0].id
            };
            this.categoryId = this.categories[0].id;
            var child = this.$refs.categoryComponent;
            child.getFeaturedCategory(data);
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getTestimonials() {
      await TestimonialService.getTestimonials()
        .then(result => {
          this.testimonials = result.data;
          this.$store.dispatch("testimonial/updateTestimonialLoader", false);
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
          this.$store.dispatch("testimonial/updateTestimonialLoader", false);
        });
    },
    async getFeaturedBlogs() {
      await BlogService.getFeaturedBlogs()
        .then(result => {
          this.blogs = result.data.data;
          this.$store.dispatch("blog/updateFeatureBlogsLoader", false);
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
          this.$store.dispatch("blog/updateFeatureBlogsLoader", false);
        });
    },
    getLatestCourse() {
      CourseService.getLatestCourse()
        .then(result => {
          this.latestCourse = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getNotices() {
      await TestimonialService.getNotices()
        .then(output => {
          this.loader = false;
          if (output.data.status == "success") {
            this.notices = output.data.notices;
          }
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>
