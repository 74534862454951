<template>
  <div class="col-md-4">
    <router-link
      style="text-decoration:none;"
      :to="{ name: 'OfflineCourseDetails', params: { id: course.id } }"
    >
      <div class="webinar-card">
        <div class="webinar-image-wrapper">
          <img :src="course.image" class="img-fluid" alt="" />
        </div>
        <div class="webinar-body">
          <h5>{{ course.title }}</h5>
          <!-- <p>{{ webinar.meta_description }}</p> -->
          <p class="info">
            <i class="fas fa-calendar-week"></i> {{ course.date }}
          </p>
          <p class="info">
            <i class="fas fa-clock"></i>
            {{ course.from }} - {{ course.to }}
          </p>
          <p class="info" v-if="course.location != null">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            {{ course.location }}
          </p>
          <p class="info" v-else>
            <i class="fa fa-laptop" aria-hidden="true"></i>
            Online Course
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "OfflineCourseCard",
  props: ["course"]
};
</script>

<style scoped></style>
