<template>
  <div class="checkout">
    <div class="container-fluid checkout-header">
      <div class="container">
        <div class="__headline">
          <h5 class="color-1">Courses</h5>
        </div>
        <div class="__checkout-card">
          <div class="row __checkout-card-head justify-content-between">
            <div class="col-md-10">
              <p>Course</p>
            </div>
            <div class="col-md-2">
              <p class="price-item">Price</p>
            </div>
          </div>
          <div class="__checkout-card-body" v-if="isLoading == false">
            <div class="row __checkout-card-item">
              <div class="col-md-10 content-col">
                <div class="image-wrap">
                  <img
                    class="card-image img-fluid"
                    :src="course.image"
                    alt=""
                  />
                </div>
                <div class="content-wrap">
                  <h5 class="card-title">{{ course.title }}</h5>
                  <p>{{ course.duration }}</p>
                  <p class="author-name">{{ course.name }}</p>
                  <vue-stars
                    name="displayRating"
                    :value="parseInt(course.rating)"
                    :max="5"
                    :readonly="true"
                  >
                    <span slot="activeLabel">
                      <i class="fa fa-star fill"></i>
                    </span>
                    <span slot="inactiveLabel">
                      <i class="fa fa-star plain"></i>
                    </span>
                  </vue-stars>
                  <span class="rating-value">{{
                    parseFloat(course.rating).toFixed(1)
                  }}</span>
                </div>
              </div>
              <div class="col-md-2">
                <h5 class="price-item"><span>₹</span> {{ course.price }}.00</h5>
              </div>
            </div>
            <div class="row __checkout-card-item">
              <div class="content-wrap">
                <p>
                  Languages:
                  <span
                    v-for="(language, languageIndex) in course.languages"
                    :key="languageIndex"
                  >
                    {{ language.language }}
                    <span
                      v-if="
                        languageIndex !=
                          Object.keys(course.languages).length - 1
                      "
                      >,
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div class="__checkout-offer">
              <label for="offerCode">Have an promo code !</label>
              <form>
                <div class="form-row align-items-center">
                  <div class="col-md-6 form-group d-flex">
                    <input
                      v-model="promoCode"
                      type="text"
                      class="form-control"
                      id="offerCode"
                      placeholder="Offer code"
                    />
                    <button
                      class="btn cta-primary"
                      @click.prevent="applyPromoCode"
                    >
                      Apply
                    </button>
                  </div>
                  <div
                    class="col-md-6 form-group d-flex"
                    v-if="promoOffer != 0"
                  >
                    <span class="input-tooltip"
                      ><i class="fas fa-check"></i>1 Offer applied</span
                    >
                  </div>
                </div>
                <div class="error-message" v-if="promoCodeValidation != ''">
                  {{ promoCodeValidation }}
                </div>
              </form>
              <div class="col-md-6"></div>
            </div>
          </div>
          <div class="__checkout-card-body" v-else>
            <loader></loader>
          </div>
          <div class="__checkout-card-footer" v-if="isLoading == false">
            <div class="row justify-content-end">
              <div class="col-md-2">
                <p>Total</p>
              </div>
              <div class="col-md-2">
                <h5 class="price-item"><span>₹</span> {{ course.price }}.00</h5>
              </div>
            </div>
            <div class="row justify-content-end" v-if="course.offer > 0">
              <div class="col-md-2">
                <p>Offer</p>
              </div>
              <div class="col-md-2">
                <h5 class="price-item">
                  <span>₹</span> - {{ calculateOfferAmount }}
                </h5>
              </div>
            </div>
            <div class="row justify-content-end" v-if="membershipReduction > 0">
              <div class="col-md-2">
                <p>Membership Reduction</p>
              </div>
              <div class="col-md-2">
                <h5 class="price-item">
                  <span>₹</span> - {{ membershipReductionAmount }}
                </h5>
              </div>
            </div>
            <div class="row justify-content-end" v-if="promoOffer > 0">
              <div class="col-md-2">
                <p>Promo Code</p>
              </div>
              <div class="col-md-2">
                <h5 class="price-item">
                  <span>₹</span> - {{ calculatePromoOffer }}
                </h5>
              </div>
            </div>
            <div class="row justify-content-end" v-if="taxAmount > 0">
              <div class="col-md-2">
                <p>Tax({{ taxPercentage }}%)</p>
              </div>
              <div class="col-md-2">
                <h5 class="price-item"><span>₹</span> {{ taxAmount }}</h5>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-md-2">
                <p>Grand Total</p>
              </div>
              <div class="col-md-2">
                <h4 class="price-item">
                  <span>₹</span> {{ calculateTotal }}.00
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container checkout-form-outer" v-if="isPurchased == false">
        <div class="checkout-form">
          <div class="__headline">
            <h5 class="color-1">Billing details</h5>
          </div>
          <div class="form-outer">
            <form @submit.prevent="checkout">
              <div class="form-row align-items-center">
                <div class="col-md-6 form-group">
                  <input
                    v-model="firstName"
                    type="text"
                    class="form-control"
                    id="FirstName"
                    placeholder=""
                  />
                  <label for="">First name</label>
                  <div class="error-message">
                    {{ validation.firstError("firstName") }}
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <input
                    v-model="lastName"
                    type="text"
                    class="form-control"
                    id="Lastname"
                    placeholder=""
                  />
                  <label for="">Last name</label>
                  <div class="error-message">
                    {{ validation.firstError("lastName") }}
                  </div>
                </div>
              </div>
              <div class="form-row align-items-center">
                <div class="col-md-6 form-group">
                  <input
                    v-model="email"
                    type="text"
                    class="form-control"
                    id="email"
                    placeholder=""
                  />
                  <label for="">Email</label>
                  <div class="error-message">
                    {{ validation.firstError("email") }}
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <input
                    v-model="gst"
                    type="text"
                    class="form-control"
                    id="gst"
                    placeholder=""
                    autocomplete="off"
                  />
                  <label for="">GST (optional)</label>
                </div>
              </div>
              <div class="form-row align-items-center">
                <div class="col-md-12 form-group">
                  <textarea
                    v-model="address"
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder=""
                  />
                  <label for=""> Address</label>
                  <div class="error-message">
                    {{ validation.firstError("address") }}
                  </div>
                </div>
              </div>
              <div class="form-row align-items-center">
                <div class="col-md-6 form-group">
                  <input
                    v-model="country"
                    type="text"
                    class="form-control"
                    id="country"
                    placeholder=""
                  />
                  <label for="">Country</label>
                  <div class="error-message">
                    {{ validation.firstError("country") }}
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <input
                    v-model="state"
                    type="text"
                    class="form-control"
                    id="state"
                    placeholder=""
                  />
                  <label for="">State</label>
                  <div class="error-message">
                    {{ validation.firstError("state") }}
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <input
                    v-model="zipCode"
                    type="text"
                    class="form-control"
                    id="zip-code"
                    placeholder=""
                  />
                  <label for="">Zip code</label>
                  <div class="error-message">
                    {{ validation.firstError("zipCode") }}
                  </div>
                </div>
              </div>
              <p class="terms-cta">
                By completing your purchase you agree to these
                <a href="#" data-toggle="modal" data-target="#termsAndCondition"
                  >Terms of Service.</a
                >
              </p>
              <div class="cta-row">
                <button
                  type="button"
                  @click="$router.go(-1)"
                  class="btn cta-clear mr-2"
                >
                  Back to courses
                </button>
                <button
                  type="submit"
                  :disabled="isPaymentProgressing == true"
                  class="btn cta-primary"
                >
                  Pay now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="container checkout-form-outer" v-else>
        <div class="checkout-form">
          <div class="__headline">
            <h5 class="color-1">Already purchased this course</h5>
          </div>
        </div>
      </div>
    </div>
    <!-- //popular-courses -->
    <PopularCourses></PopularCourses>
    <div
      class="modal fade add-review-wrap"
      id="termsAndCondition"
      tabindex="-1"
      role="dialog"
      aria-labelledby="terms-and-condition"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title color-1" id="termsAndCondition-label">
              Terms and conditions
            </h5>
          </div>
          <div class="modal-body">
            <p v-html="termsAndConditions.content"></p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              ref="close"
              class="btn cta-clear"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopularCourses from "@/components/PopularCourses.vue";
import CourseService from "@/services/CourseService";
import VueStars from "../components/VueStars.vue";
import Loader from "../components/Loader.vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
import BlogService from "@/services/BlogService";
export default {
  name: "Checkout",
  components: {
    PopularCourses,
    VueStars,
    Loader
  },
  data: function() {
    return {
      slug: this.$route.params.slug,
      isLoading: true,
      course: [],
      promoCode: "",
      promoCodeValidation: "",
      promoOffer: 0,
      membershipReduction: 0,
      user: JSON.parse(this.$store.state.auth.user),
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      country: "",
      state: "",
      gst: "",
      zipCode: "",
      billingAddressId: "",
      isPaymentProgressing: false,
      isPurchased: false,
      taxPercentage: 0,
      termsAndConditions: ""
    };
  },
  validators: {
    firstName: function(value) {
      return Validator.value(value).required("Please enter your first name");
    },
    lastName: function(value) {
      return Validator.value(value).required("Please enter your last name");
    },
    email: function(value) {
      return Validator.value(value)
        .required("Please enter your email")
        .email("Please enter a valid email");
    },
    address: function(value) {
      return Validator.value(value).required("Please enter your address");
    },
    country: function(value) {
      return Validator.value(value).required("Please enter your country");
    },
    state: function(value) {
      return Validator.value(value).required("Please enter your state");
    },
    zipCode: function(value) {
      return Validator.value(value).required("Please enter your zipcode");
    }
  },
  computed: {
    calculateOfferAmount: function() {
      return Math.round(this.course.price * (this.course.offer / 100)).toFixed(
        2
      );
    },
    calculatePromoOffer: function() {
      return Math.round(this.course.price * (this.promoOffer / 100)).toFixed(2);
    },
    membershipReductionAmount: function() {
      return Math.round(
        this.course.price * (this.membershipReduction / 100)
      ).toFixed(2);
    },
    taxAmount: function() {
      let totalAmount =
        this.course.price -
        (parseFloat(this.calculateOfferAmount) +
          parseFloat(this.membershipReductionAmount) +
          parseFloat(this.calculatePromoOffer));
      return Math.round(totalAmount * (this.taxPercentage / 100)).toFixed(2);
    },
    calculateTotal: function() {
      return Math.round(
        parseFloat(this.course.price) -
          (parseFloat(this.calculatePromoOffer) +
            parseFloat(this.calculateOfferAmount) +
            parseFloat(this.membershipReductionAmount)) +
          parseFloat(this.taxAmount)
      );
    }
  },
  created: function() {
    this.getCourse();
    this.getBillingAddress();
    this.getCheckoutSettings();
    this.getCms();
  },
  methods: {
    async getCourse() {
      this.isLoading = true;
      await CourseService.watchCourse({ slug: this.slug })
        .then(result => {
          if (result.data.course == null) {
            this.$router.push({ name: "PageNotFound" });
          }
          this.course = result.data.course;
          this.isPurchased = result.data.isPurchased;
          this.isLoading = false;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async applyPromoCode() {
      if (this.promoCode == "") {
        this.promoCodeValidation = "Please enter a promo code";
      } else {
        await CourseService.promoCode({ promo_code: this.promoCode })
          .then(result => {
            if (result.data.status == "success") {
              this.promoOffer = result.data.offer;
              this.promoCodeValidation = "";
            } else if (result.data.status == "error") {
              this.promoCodeValidation = result.data.message;
              this.promoOffer = 0;
            } else {
              this.promoCodeValidation = "Invalid promo code";
              this.promoOffer = 0;
            }
          })
          .catch(error => {
            this.promoOffer = 0;
            this.error = error;
            // this.$toastr.e("Something went wrong");
          });
      }
    },
    async getCheckoutSettings() {
      await CourseService.getCheckoutSettings({
        slug: this.slug
      }).then(result => {
        let user = JSON.parse(this.$store.state.auth.user);
        if (user.user_type == 3) {
          this.membershipReduction = result.data.member_price_reduction;
        }
        this.taxPercentage = result.data.tax;
      });
    },
    async getBillingAddress() {
      await CourseService.getBillingAddress().then(result => {
        let billingAddress = result.data;
        if (billingAddress.length == 0) {
          this.firstName = this.user.name;
          this.email = this.user.email;
        } else {
          this.firstName = billingAddress.first_name;
          this.lastName = billingAddress.last_name;
          this.email = billingAddress.email;
          this.address = billingAddress.address;
          this.country = billingAddress.country;
          this.state = billingAddress.state;
          this.zipCode = billingAddress.zip_code;
          this.billingAddressId = billingAddress.id;
        }
      });
    },
    checkout: function() {
      this.$validate().then(success => {
        if (success) {
          this.isPaymentProgressing = true;
          const data = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            address: this.address,
            country: this.country,
            state: this.state,
            zip_code: this.zipCode,
            id: this.billingAddressId
          };
          CourseService.saveBillingAddress(data)
            .then(result => {
              if (result.data.status == "success") {
                console.log(result.data);
                this.payment();
              }
            })
            .catch(error => {
              this.isPaymentProgressing = false;
              this.error = error;
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    payment() {
      let total = (parseFloat(this.calculateTotal) * 100).toFixed(2);
      this.isPaymentProgressing = false;
      var rzp1 = new window.Razorpay({
        key: process.env.VUE_APP_RAZOR_PAY_KEY,
        amount: total,
        name: "Ology Women",
        currency: "INR",
        description: "test description",
        image: "../assets/Ologylogo-color.png",
        handler: response => {
          this.isPaymentProgressing = true;
          this.saveOrder(response);
        },
        prefill: {
          name: this.firstName,
          email: this.email,
          contact: this.user.phone
        },
        notes: {
          address: ""
        },
        theme: {
          color: "#00ffff"
        }
      });
      rzp1.open();
      rzp1.on("payment.failed", function(response) {
        alert(response.error.description);
      });
    },
    async saveOrder(response) {
      await CourseService.saveOrder({
        response: response.razorpay_payment_id,
        course_id: this.course.id,
        promo_code_offer: this.promoOffer,
        member_reduction: this.membershipReductionAmount,
        tax: this.taxAmount,
        tax_percentage: this.taxPercentage,
        total: this.calculateTotal
      })
        .then(result => {
          this.isPaymentProgressing = false;
          if (result.data.status == "success") {
            this.$router
              .push({
                name: "paymentSuccess",
                params: { id: result.data.id },
                query: { source: "course" }
              })
              .catch(() => {});
          }
        })
        .catch(error => {
          this.isPaymentProgressing = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getCms() {
      await BlogService.getCms("terms-and-conditions-for-payments")
        .then(result => {
          if (result.data.status == "success") {
            this.termsAndConditions = result.data.content;
          } else {
            this.termsAndConditions = "";
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>

<style scoped>
span.rating-value {
  font-size: 16px;
  font-weight: 600;
  padding-left: 9px;
  /* margin-top: 37px; */
}
h4.price-item {
  float: right;
}
h5.price-item {
  float: right;
}
</style>
