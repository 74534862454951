<template>
  <div>
    <section>
      <div class="container-fluid gallery">
        <div class="custom-head"><h2>Gallery</h2></div>
        <CoolLightBox
          :items="items"
          :index="index"
          :effect="'fade'"
          :fullScreen="true"
          @close="index = null"
        >
        </CoolLightBox>

        <div class="images-wrapper">
          <div class=" image-wrapper">
            <div
              class="image"
              v-for="(image, imageIndex) in items"
              :key="imageIndex"
              @click="index = imageIndex"
              :style="{ backgroundImage: 'url(' + image.src + ')' }"
            ></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "Gallery",
  components: {
    CoolLightBox
  },
  data: function() {
    return {
      items: [
        {
          title: "In nature, nothing is perfect and everything is perfect",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero aspernatur reprehenderit repellat illum dolor tempora delectus molestiae officiis veritatis error. Molestias consectetur eveniet nulla unde totam deserunt quasi quis assumenda?. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero aspernatur reprehenderit repellat illum dolor tempora delectus molestiae officiis veritatis error. Molestias consectetur eveniet nulla unde totam deserunt quasi quis assumenda?",
          src:
            "https://images.unsplash.com/photo-1670968982569-81f78c8e841d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1192&q=80"
        },
        {
          title: "In nature, nothing is perfect and everything is perfect",
          description: "Photo by Lucas",
          src:
            "https://images.unsplash.com/photo-1666961897919-a094f7a16c6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80"
        },
        {
          title: "In nature, nothing is perfect and everything is perfect",
          description: "Photo by Lucas",
          src:
            "https://images.unsplash.com/photo-1664095683176-100a06e2aff1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
        },
        {
          title: "In nature, nothing is perfect and everything is perfect",
          description: "Photo by Lucas",
          src:
            "https://images.unsplash.com/photo-1670968982569-722c875727dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
        },
        {
          title: "In nature, nothing is perfect and everything is perfect",
          description: "Photo by Lucas",
          src:
            "https://images.unsplash.com/photo-1663066202073-e06833b98cd4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
        },
        {
          title: "In nature, nothing is perfect and everything is perfect",
          description: "Photo by Lucas",
          src:
            "https://images.unsplash.com/photo-1670968981833-e028025124a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=391&q=80"
        },
        {
          title: "In nature, nothing is perfect and everything is perfect",
          description: "Photo by Lucas",
          src:
            "https://images.unsplash.com/photo-1670352690010-d39ed2fe465c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        },
        {
          title: "In nature, nothing is perfect and everything is perfect",
          description: "Photo by Lucas",
          src:
            "https://images.unsplash.com/photo-1670506687527-42b2a691e032?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80"
        }
      ],
      index: null
    };
  }
};
</script>
