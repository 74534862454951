<template>
  <div class="webinar-single">
    <div class="container-fluid webinar-head-wrapper">
      <div class="container h-100">
        <div class="blog-head row align-items-end justify-content-between">
          <div class="col-md-8">
            <h3 class="webinar-title">
              {{ course.title }}
            </h3>
          </div>
          <div class="col-md-3 share-div text-right">
          </div>
        </div>
      </div>
    </div>
    <div class="container webinar-content-wrapper">
      <div class="row">
        <div class="col-md-8">
          <div class="webinar-content">
            <img :src="course.image" />
            <p v-html="course.description"></p>
          </div>
        </div>
        <div class="col-md-4">
          <ul class="webinar-info list-unstyled">
            <li>
              <p><i class="far fa-calendar-check"></i> {{ course.date }}</p>
            </li>
            <li>
              <p>
                <i class="fas fa-clock"></i> {{ course.from }} to
                {{ course.to }}
              </p>
            </li>
            <li v-if="course.medium != null">
              <p>
                <i class="fas fa-video"></i> {{ course.medium }}
                <span> Id: {{ course.meeting_id }}</span>
              </p>
            </li>
            <li v-if="course.meeting_url != null">
              <p>
                <i class="fas fa-link"></i>
                <a :href="course.meeting_url" target="_blank">{{
                  course.meeting_url
                }}</a>
              </p>
            </li>
            <li v-if="course.location != null">
              <p>
                <i class="fa fa-map-marker"></i>
                <span> Location: {{ course.location }}</span>
              </p>
            </li>
            <li v-if="course.location_address != null">
              <p>
                <i class="fa fa-map-marker"></i>
                <span> Address: {{ course.location_address }}</span>
              </p>
            </li>
            <li v-if="course.price != null">
              <h3 class="price-tag" v-if="course.price > 0">
                <span class="rupee">₹</span>{{ course.price }}
              </h3>
            </li>
            <li v-if="course.price != null">
              <h3 class="price-tag" v-if="course.price > 0">
                <!-- <span class="rupee">₹</span>{{ webinar.price }} -->
                <router-link
                  v-if="isExpired == false"
                  :to="{ name: 'OfflineCourseCheckout', params: { id: course.id } }"
                >
                  <a class="btn cta-primary">book now</a>
                </router-link>
              </h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <PopularCourses></PopularCourses>
  </div>
</template>

<script>
import PopularCourses from "@/components/PopularCourses.vue";
import OfflineCourseService from "@/services/OfflineCourseService";
export default {
  name: "OfflineCourseDetails",
  components: { PopularCourses },
  data: function() {
    return {
      course: [],
      error: [],
      loader: true,
      isExpired: false
    };
  },
  watch: {
    "$route.params.id": function() {
      this.loader = true;
      this.getOfflineCourse();
    }
  },
  created(){
    this.getOfflineCourse();
  },
  methods: {
    async getOfflineCourse() {
      await OfflineCourseService.getOfflineCourse({ id: this.$route.params.id })
        .then(result => {
          if (result.data.status == "success") {
            this.course = result.data.course;
            this.isExpired = result.data.isExpired;
          } else {
            this.$router.push({ name: "PageNotFound" });
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>

<style scoped>
.image-wrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  overflow: hidden;
}
.blog-single .blog-content-wrapper p {
  overflow: hidden;
}
.blog-single .blog-content-wrapper img {
  -o-object-fit: cover;

  object-fit: cover;

  height: unset !important;

  width: 100% !important;
}
</style>
