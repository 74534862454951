<template>
  <div class="blog-single">
    <div class="container-fluid blog-head-wrapper">
      <div class="container">
        <div class="blog-head row align-items-end justify-content-between">
          <div class="col-md-8">
            <h3 class="Blog-title">
              {{ cms.title }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container blog-content-wrapper">
      <div class="row justify-content-between">
        <div class="col-md-12 blog-content">
          <p v-html="cms.content"></p>
        </div>
      </div>
    </div>
    <PopularCourses></PopularCourses>
  </div>
</template>

<script>
import PopularCourses from "@/components/PopularCourses.vue";
import BlogService from "@/services/BlogService";
export default {
  name: "Cms",
  components: { PopularCourses },
  data: function() {
    return {
      cms: []
    };
  },
  watch: {
    "$route.params.slug": function() {
      this.loader = true;
      this.getCms();
    }
  },
  created: function() {
    this.getCms();
  },
  methods: {
    async getCms() {
      await BlogService.getCms(this.$route.params.slug)
        .then(result => {
          if (result.data.status == "success") {
            this.cms = result.data.content;
          } else {
            this.$router.push({ name: "PageNotFound" });
          }
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>

<style scoped>
.image-wrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  overflow: hidden;
}
.blog-single {
  padding-top: 80px;
}
</style>
