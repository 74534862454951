const course = {
  state: {
    isLoadingFeaturedCourse: false,
    isLoadingPopularCourse: true,
    isLoadingHostedCourse: true
  },
  mutations: {
    UPDATE_FEATURE_COURSE_LOADING: (state, loading) => {
      state.isLoadingFeaturedCourse = loading;
    },
    UPDATE_POPULAR_COURSE_LOADING: (state, loading) => {
      state.isLoadingPopularCourse = loading;
    },
    UPDATE_HOSTED_COURSE_LOADING: (state, loading) => {
      state.isLoadingHostedCourse = loading;
    }
  },
  actions: {
    updateFeatureCourseLoader: ({ commit }, status) => {
      commit("UPDATE_FEATURE_COURSE_LOADING", status);
    },
    updatePopularCourseLoader: ({ commit }, status) => {
      commit("UPDATE_POPULAR_COURSE_LOADING", status);
    },
    updateHostedCourseLoader: ({ commit }, status) => {
      commit("UPDATE_HOSTED_COURSE_LOADING", status);
    }
  }
};
export default course;
