<template>
  <div>
    <section>
      <div class="container-fluid gallery">
        <div class="d-flex bd-highlight">
          <div class="custom-head flex-grow-1">
            <h2>Gallery</h2>
          </div>
          <div class="back-btn">
            <router-link :to="{ name: 'EventAlbums' }" class="btn cta-primary"
              >Back</router-link
            >
          </div>
        </div>
        <CoolLightBox
          :items="items"
          :index="index"
          :effect="'fade'"
          :fullScreen="true"
          @close="index = null"
        >
        </CoolLightBox>
        <div v-if="loader == true">
          <loader></loader>
        </div>
        <div v-else class="images-wrapper">
          <div class=" image-wrapper">
            <div
              class="image"
              v-for="(image, imageIndex) in items"
              :key="imageIndex"
              @click="index = imageIndex"
              :style="{ backgroundImage: 'url(' + image.src + ')' }"
            ></div>
          </div>
        </div>
        <div
          class="not-found-wrapper"
          v-if="items.length == 0 && loader == false"
        >
          <img src="@/assets/not-found.svg" alt="not-found" />
          <h4>No Gallery Found !</h4>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import Loader from "../components/Loader.vue";
  import CoolLightBox from "vue-cool-lightbox";
  import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
  import EventAlbumService from "@/services/EventAlbumService";
  export default {
    name: "EventAlbumDetails",
    components: {
      CoolLightBox,
      Loader
    },
    data() {
      return {
        loader: true,
        index: null,
        items: []
      };
    },
    created() {
      this.getGalleries();
    },
    methods: {
      async getGalleries() {
        let params = {
          'event_id' : this.$route.params.id
        };
        await EventAlbumService.getGalleries(params)
          .then((output) => {
            this.loader = false;
            if (output.data.status == 'success') {
              this.items = output.data.galleries;
            } else {
              console.log(1);
            }
          });
      },
    },
  };
</script>
