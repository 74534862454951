<template>
  <div class="col-md-3">
    <router-link :to="{ name: 'blog', params: { slug: blog.slug } }">
      <div class="blog-card">
        <div class="blog-image-wrapper">
          <img :src="blog.image" class="img-fluid" alt="" />
        </div>
        <div class="blog-body">
          <h5>{{ blog.title }}</h5>
          <!-- <p>{{ blog.meta_description }}</p> -->
          <div class="info-row">
            <p to="#" v-if="blog.author != null">by {{ blog.author }}</p>
            <p
              v-else
              :to="{
                name: 'publicProfile',
                params: { name: blog.name, id: blog.user_id }
              }"
            >
              by {{ blog.name }} {{ blog.last_name }}
            </p>
            <!-- <span>{{ blog.created_date }}</span> -->
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BlogCard",
  props: ["blog"]
};
</script>

<style scoped></style>
