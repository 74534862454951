var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"module-card"},[_c('div',{staticClass:"module-card-header d-flex justify-content-between"},[_c('h5',{staticClass:"module-number"},[_vm._v(_vm._s(_vm.module.title))]),_c('h6',[_c('span',[_vm._v("Type : ")]),_vm._v(" "+_vm._s(_vm.module.type))])]),_c('div',{staticClass:"module-card-body"},[(
          _vm.module.section_type_id == 1 && _vm.availableVideoLanguages.length > 0
        )?_c('div',{staticClass:"placeholder video"},[_c('h6',[_vm._v("UPLOAD VIDEOS")]),_c('p',[_vm._v(" You can upload "+_vm._s(_vm.availableVideoLanguages.length)+" more videos in "+_vm._s(_vm.availableVideoLanguages.length)+" languages ")])]):_vm._e(),(_vm.module.section_type_id == 1)?_c('div',_vm._l((_vm.module.videos),function(video,videoIndex){return _c('VideoCard',{key:videoIndex,attrs:{"video":video,"progress":_vm.progress,"videoId":_vm.videoId,"isPublished":_vm.isPublished},on:{"reloadModules":_vm.reloadModules}})}),1):_vm._e(),(_vm.module.section_type_id == 2)?_c('div',_vm._l((_vm.module.exams),function(exam,examIndex){return _c('ExamCard',{key:examIndex,attrs:{"exam":exam,"isPublished":_vm.isPublished}})}),1):_vm._e(),(_vm.module.section_type_id == 3)?_c('div',[_vm._l((_vm.module.articles),function(article,articleIndex){return _c('ArticleCard',{key:articleIndex,attrs:{"article":article,"isPublished":_vm.isPublished},on:{"reloadModules":_vm.reloadModules}})}),(_vm.module.articles == 0)?_c('p',[_vm._v(" Select a language and click Create Article Button ")]):_vm._e()],2):_vm._e()]),(_vm.isPublished == false)?_c('div',{staticClass:"module-card-footer row no-gutters justify-content-between align-items-center"},[_c('div',{staticClass:"col-md-6"},[(
            _vm.module.section_type_id == 1 && _vm.availableVideoLanguages.length > 0
          )?_c('div',{staticClass:"upload-hijack"},[_c('button',{staticClass:"btn Upload-btn",attrs:{"for":""}},[_vm._v(" Choose File "),_c('img',{attrs:{"src":require("../assets/upload-cloud.svg"),"alt":""}}),_c('input',{ref:"videoFile",attrs:{"type":"file","id":"fileUpload","disabled":this.$store.state.upload.isUploading == true},on:{"change":_vm.selectVideoFile}})])]):_vm._e(),(_vm.errors.video && _vm.errors.video)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errors.video)+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"language-select d-flex align-items-center"},[(
              _vm.module.section_type_id == 1 &&
                _vm.availableVideoLanguages.length > 0
            )?_c('multiselect',{attrs:{"options":_vm.availableVideoLanguages,"searchable":true,"close-on-select":true,"show-labels":false,"track-by":"id","id":_vm.module.id,"label":"language","placeholder":"language"},on:{"open":_vm.loadAvailableVideoLanguage},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Loading ...")])]):_vm._e(),(
              _vm.module.section_type_id == 2 &&
                _vm.availableExamLanguages.length > _vm.module.exams.length
            )?_c('multiselect',{attrs:{"options":_vm.availableExamLanguages,"searchable":true,"close-on-select":true,"show-labels":false,"track-by":"id","id":_vm.module.id,"label":"language","placeholder":"language"},on:{"open":_vm.loadAvailableExamLanguage},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Loading ...")])]):_vm._e(),(
              _vm.module.section_type_id == 3 &&
                _vm.availableArticleLanguages.length > 0
            )?_c('multiselect',{attrs:{"options":_vm.availableArticleLanguages,"searchable":true,"close-on-select":true,"show-labels":false,"track-by":"id","id":_vm.module.id,"label":"language","placeholder":"language"},on:{"open":function($event){return _vm.getAvailableArticleLanguages(_vm.module.id)}},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}},[_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Loading ...")])]):_vm._e()],1),(_vm.errors.language && _vm.errors.language)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.errors.language)+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-3 text-right"},[_c('div',{staticClass:"upload-hijack"},[(
              _vm.module.section_type_id == 1 &&
                _vm.availableVideoLanguages.length > 0
            )?_c('button',{staticClass:"btn cta-primary",attrs:{"disabled":this.$store.state.upload.isUploading == true},on:{"click":_vm.startUpload}},[_vm._v(" Upload ")]):_vm._e(),(
              _vm.module.section_type_id == 2 &&
                _vm.availableExamLanguages.length > _vm.module.exams.length
            )?_c('button',{staticClass:"btn cta-primary",attrs:{"disabled":this.$store.state.upload.isUploading == true},on:{"click":_vm.createExam}},[_vm._v(" Create exam ")]):_vm._e(),(
              _vm.module.section_type_id == 3 &&
                _vm.availableArticleLanguages.length > 0
            )?_c('button',{staticClass:"btn cta-primary",attrs:{"disabled":this.$store.state.upload.isUploading == true},on:{"click":_vm.createArticle}},[_vm._v(" Create Article ")]):_vm._e()])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }