<template>
  <div class="module-upload">
    <div class="container-fluid">
      <div class="hostcourse-tab-wrapper container">
        <div
          class="add-new-action d-flex justify-content-between align-items-center"
        >
          <h5 class="color-1">Create Blog</h5>
        </div>
        <div class="row upload-tab-row">
          <div class="col-md-12 tab-body__wrap">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-detail"
                role="tabpanel"
                aria-labelledby="pills-detail-tab"
              >
                <div class="tab-content__header">
                  <h5 class="color-1">Create Blog</h5>
                </div>
                <div class="module-info-form">
                  <div>
                    <form
                      @submit.prevent="saveBlog"
                      enctype="multipart/form-data"
                    >
                      <div class="form-row">
                        <div class="col-md-12 form-group">
                          <label for="blogTitle">
                            Blog Title
                          </label>
                          <input
                            v-model="title"
                            type="text"
                            class="form-control"
                            id="blogTitle"
                            @keyup="getSlug"
                          />
                          <div class="error-message">
                            {{ validation.firstError("title") }}
                          </div>
                          <div
                            v-if="errors && errors.title"
                            class="error-message"
                          >
                            {{ errors.title[0] }}
                          </div>
                        </div>
                        <div class="col-md-12 form-group">
                          <label for="blogSlug">
                            Slug
                          </label>
                          <input
                            v-model="slug"
                            type="text"
                            class="form-control"
                            id="blogSlug"
                          />
                          <div class="error-message">
                            {{ validation.firstError("slug") }}
                          </div>
                          <div
                            v-if="errors && errors.slug"
                            class="error-message"
                          >
                            {{ errors.slug[0] }}
                          </div>
                        </div>
                        <div class="col-md-12 form-group">
                          <label for="keywords">
                            Keywords
                          </label>
                          <multiselect
                            v-model="keywords"
                            :options="keywordOptions"
                            tag-placeholder="Add this as new keyword"
                            placeholder="Add keywords"
                            :multiple="true"
                            :taggable="true"
                            :close-on-select="false"
                            @tag="addKeyword"
                          >
                            <span slot="noOptions">Type key word here</span>
                          </multiselect>
                          <div class="error-message">
                            {{ validation.firstError("keywords") }}
                          </div>
                          <div
                            v-if="errors && errors.keywords"
                            class="error-message"
                          >
                            {{ errors.keywords[0] }}
                          </div>
                        </div>
                        <div class="col-md-12 form-group">
                          <label for="shortDescription">
                            Short Description
                          </label>
                          <textarea
                            v-model="shortDescription"
                            type="text"
                            class="form-control"
                            id="shortDescription"
                          ></textarea>
                          <div class="error-message">
                            {{ validation.firstError("shortDescription") }}
                          </div>
                          <div
                            v-if="errors && errors.short_description"
                            class="error-message"
                          >
                            {{ errors.short_description[0] }}
                          </div>
                        </div>
                        <div class="col-md-12 form-group">
                          <label for="courseName">
                            Blog
                          </label>
                          <ckeditor
                            :editor="editor"
                            v-model="blog"
                            :config="editorConfig"
                          ></ckeditor>
                          <div class="error-message">
                            {{ validation.firstError("blog") }}
                          </div>
                          <div
                            v-if="errors && errors.blog"
                            class="error-message"
                          >
                            {{ errors.blog[0] }}
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col">
                            <div class="form-group">
                              <label for="fileUpload">Course thumbnail</label>
                              <div class="upload-hijack">
                                <div class="d-flex">
                                  <div>
                                    <button for="" class="btn Upload-btn">
                                      Upload
                                      <img
                                        src="../assets/upload-cloud.svg"
                                        alt=""
                                      />
                                      <input
                                        type="file"
                                        ref="thumbnail"
                                        id="fileUpload"
                                        @change="uploadThumbnail"
                                      />
                                    </button>
                                    <div class="error-message">
                                      {{ validation.firstError("thumbnail") }}
                                    </div>
                                    <div
                                      v-if="errors && errors.image"
                                      class="error-message"
                                    >
                                      {{ errors.image[0] }}
                                    </div>
                                  </div>
                                  <div
                                    class="preview-image-wrapper"
                                    v-if="preview != ''"
                                  >
                                    <img
                                      :src="preview"
                                      class="preview-image img-fluid"
                                      width="100"
                                    />
                                    <a
                                      for=""
                                      class=" btn delete-btn"
                                      title="remove image"
                                      @click="removeImage"
                                    >
                                      <img
                                        src="../assets/delete-icon.svg"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div class="error-message">
                                  {{ validation.firstError("courseImage") }}
                                </div>
                                <span class="input-tooltip ml-2"
                                  ><i>i</i>Only choose images you have the right
                                  to use
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="submit" class="btn cta-clear">
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid module-upload-wrap">
      <div class="container"></div>
    </div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
import BlogService from "@/services/BlogService";
import UploadAdapter from "@/adaptors/UploadAdapter";
export default {
  name: "CreateBlog",
  components: {
    ckeditor: CKEditor.component,
    Multiselect
  },
  validators: {
    title: function(value) {
      return Validator.value(value).required("Please enter blog title");
    },
    slug: function(value) {
      return Validator.value(value).required("Please enter slug url");
    },
    keywords: function(value) {
      return Validator.value(value).required("Please enter keywords");
    },
    shortDescription: function(value) {
      return Validator.value(value)
        .required("Please enter short description")
        .minLength(2)
        .maxLength(100);
    },
    blog: function(value) {
      return Validator.value(value).required("Please enter blog");
    },
    "thumbnail, updateId": function(thumbnail, updateId) {
      if (updateId !== "") {
        return;
      } else {
        return Validator.value(thumbnail).required("Select a thumbnail");
      }
    }
  },
  created: function() {
    if (typeof this.$route.params.id === "undefined") {
      this.blogId = "";
    } else {
      this.blogId = this.$route.params.id;
      this.getBlog(this.blogId);
    }
  },
  data: function() {
    return {
      title: "",
      slug: "",
      shortDescription: "",
      blog: "",
      editor: ClassicEditor,
      description: "",
      editorConfig: {
        extraPlugins: [this.uploader]
      },
      preview: "",
      thumbnail: "",
      keywordOptions: [],
      keywords: [],
      blogId: "",
      errors: [],
      disabledButton: false,
      updateId: ""
    };
  },
  methods: {
    saveBlog: function() {
      this.$validate().then(success => {
        if (success) {
          const formData = new FormData();
          formData.append("title", this.title);
          formData.append("blog", this.blog);
          formData.append("slug", this.slug);
          formData.append("keywords", this.keywords);
          formData.append("meta_description", this.shortDescription);
          formData.append("id", this.updateId);
          if (this.thumbnail != "") {
            formData.append("image", this.thumbnail);
          }
          console.log(formData);
          BlogService.saveBlog(formData)
            .then(result => {
              this.disabledButton = false;
              if (result.data.status == "success") {
                this.$router.push({ name: "profilePage" }).catch(() => {});
                this.errors = {};
                this.$toastr.s(result.data.message);
              } else if (result.data.status == "validation_error") {
                this.errors = result.data.message || {};
              }
            })
            .catch(function() {
              this.disabledButton = false;
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    async getBlog(blogId) {
      await BlogService.getBlog({ slug: blogId })
        .then(result => {
          if (result.data.status == "success") {
            this.title = result.data.blog.title;
            this.slug = result.data.blog.slug;
            this.shortDescription = result.data.blog.meta_description;
            this.blog = result.data.blog.blog;
            this.keywords = result.data.blog.keywords.split(",");
            this.updateId = result.data.blog.id;
          }
        })
        .catch(error => {
          this.error = error;
          this.$toastr.e(error);
        });
    },
    uploadThumbnail() {
      this.thumbnail = this.$refs.thumbnail.files[0];
      this.createImage(this.thumbnail);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.preview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function() {
      document.querySelector("#fileUpload").value = "";
      this.preview = "";
      this.thumbnail = "";
    },
    addKeyword(keyword) {
      this.keywordOptions.push(keyword);
      this.keywords.push(keyword);
    },
    getSlug: function() {
      this.slug = this.title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new UploadAdapter(loader);
      };
    }
  }
};
</script>
