<template>
  <div class="login">
    <div class="container-fluid register-wrap">
      <div class="container">
        <div
          class="row register-row justify-content-between align-items-center"
        >
          <div class="col-md-5" v-if="showSuccessMessage == false">
            <h2>Reset Password</h2>
            <p></p>
            <div class="register-form-wrap">
              <form @submit.prevent="resetPassword">
                <div class="form-group">
                  <label for="InputPassword">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="password"
                    id="InputPassword"
                    placeholder="Password"
                  />
                  <div class="error-message">
                    {{ validation.firstError("password") }}
                  </div>
                  <div v-if="errors && errors.password" class="error-message">
                    {{ errors.password[0] }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="InputPassword">Confirm Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="password_confirmation"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                  />
                  <div class="error-message">
                    {{ validation.firstError("password_confirmation") }}
                  </div>
                  <div
                    v-if="errors && errors.password_confirmation"
                    class="error-message"
                  >
                    {{ errors.password_confirmation[0] }}
                  </div>
                </div>
                <div class="action-wrap">
                  <button
                    type="submit"
                    class="btn cta-primary"
                    :disabled="isLoading == true"
                  >
                    <span v-if="isLoading == false">
                      Submit
                    </span>
                    <span v-else>
                      <ButtonLoader></ButtonLoader>
                    </span>
                  </button>
                  <p>
                    New to Ology Women?
                    <router-link class="btn-clear" to="/register">
                      Sign Up
                    </router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-5" v-else>
            <h2>Password Updated</h2>
            <p>Login your account with new password</p>
            <div class="register-form-wrap">
              <router-link class="btn cta-primary" :to="{ name: 'Login' }">
                Login
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import SimpleVueValidation from "simple-vue-validator";
import ButtonLoader from "@/components/ButtonLoader.vue";
const Validator = SimpleVueValidation.Validator;
export default {
  name: "ForgotPassword",
  components: {
    ButtonLoader
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      isLoading: false,
      showSuccessMessage: false,
      id: this.$route.query.id,
      expired_at: this.$route.query.e
    };
  },
  validators: {
    password: function(value) {
      return Validator.value(value)
        .required("Please enter a password")
        .minLength(5);
    },
    "password_confirmation, password": function(
      password_confirmation,
      password
    ) {
      if (
        this.submitted ||
        this.validation.isTouched("password_confirmation")
      ) {
        return Validator.value(password_confirmation)
          .required()
          .match(password, "Password mismatch");
      }
    }
  },
  methods: {
    resetPassword: function() {
      this.$validate().then(success => {
        if (success) {
          this.isLoading = true;
          const data = {
            password: this.password,
            id: this.id,
            expired_at: this.expired_at
          };
          AuthService.updatePassword(data)
            .then(result => {
              this.isLoading = false;
              if (result.data.status == "success") {
                this.$toastr.s(result.data.message);
                this.showSuccessMessage = true;
              }
              if (result.data.status == "error") {
                this.$toastr.e(result.data.message);
              }
              console.log(result);
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
            });
        }
      });
    }
  }
};
</script>
