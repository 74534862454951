var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"brand-story"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-6"},[_vm._m(4),_c('div',{staticClass:"_green-block"},[_c('h4',[_vm._v(" OlogyGirls, our community for young adults between 15 and 25 years of age, adopts a holistic approach in equipping and moulding these young minds with the necessary skills so that they may blossom into confident young adults, well equipped to explore life ahead. "),_c('br'),_c('router-link',{staticClass:"btn cta-primary mt-2",attrs:{"to":{ name: 'theFinishingSchool' }}},[_vm._v("See More")])],1)]),_vm._m(5)]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-banner container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/abt-hero.png"),"alt":""}})]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"text-content"},[_c('h2',[_vm._v(" We understand that in uplifting others we uplift ourselves. ")]),_c('h3',[_vm._v("Fempower Together")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"solid-block"},[_c('p',[_vm._v(" Across the globe, in most societies, women have for long been bound by ideas and conditioning rooted in patriarchy. But every now and then there comes a woman—or a girl— who refuses to be held back. She changes the status quo and shows how it’s done. She shows the world that anyone is capable of being an inspiration— despite their background, despite their circumstances, despite how the world sees them. She dares to dream and pursues it passionately. ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"clear-block"},[_c('p',[_vm._v(" OlogyWomen (and OlogyGirls) is for all these women and girls - the inspiring and the inspired. A safe community for women where we celebrate the small wins and the big victories, where the acknowledgement of one’s presence is as important as the recognition of another’s accomplishments, where we engage and learn from one another. ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wedo-wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"_content"},[_c('div',{staticClass:"custom-head"},[_c('h2',[_vm._v(" What we do ")])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"_content"},[_c('h5',[_vm._v(" The dictionary defines Ology as a subject of study, a branch of knowledge. ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"_content"},[_c('p',{staticClass:"m-0"},[_vm._v(" While it’s true that schools and colleges can offer an education, learning happens through life. Being self-aware and regularly updating and upskilling oneself is not just empowering, but important to navigate a constantly evolving world. "),_c('br'),_c('br'),_vm._v(" At OlogyWomen, we seek to enable insightful learning and foster meaningful connections and growth by engaging the community through various interactive engagements on diverse and relevant subjects. This happens through dialogues, discussions and customised programs such as workshops, webinars, lecture demonstrations, and interviews to name a few. ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"_content"},[_c('p',[_vm._v(" And while at it, we actively seek out and include the younger of our tribe so that they may have a better head start in adulthood. OlogyGirls, for young adults between 15 and 25 years, is as exciting, multifaceted and vibrant a community as OlogyWomen. "),_c('br'),_c('br'),_vm._v(" Opportunities to learn abound everywhere, but learning needs to be fun, engaging and holistic in approach. The programs and engagements—workshops, webinars, online courses, dialogues et al— are tailored accordingly, to the specific needs and requirements of OlogyWomen and OlogyGirls. ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mission-wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"custom-head"},[_c('h2',[_vm._v(" Our Mission ")])])]),_c('div',{staticClass:"col-md-6"},[_c('h4',[_vm._v(" OlogyWomen strives to create a community of empowered women helping them find purpose and a profound sense of belonging. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"_logo-wrap"},[_c('img',{attrs:{"src":require("../assets/ology-girls-logo.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"_clear-block"},[_c('p',[_vm._v(" Often, it is only as we mature we understand the meaning, power and value in being one’s own person. We may wish we did things differently even though we may be wiser for the experiences. ")]),_c('p',[_vm._v(" As wives, mothers and professionals (and myriad other roles we take on), we, OlogyWomen have seen a little more of the world and its ways. That is why we are keen on nurturing the younger ones of our tribe—so that they may have a better head start at adulthood. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"_img-block"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("../assets/abt-girls.jpg"),"alt":""}})])]),_c('div',{staticClass:"_yellow-block"},[_c('p',[_vm._v(" Programs for OlogyGirls viz., webinars, presentations, interviews, and online courses among others are designed to be engaging, informative and structured. And span a wide range of subjects. These are all facilitated by experts in their domains, and hosted regularly. ")])])])
}]

export { render, staticRenderFns }