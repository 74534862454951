import Http from "@/services/Http";
export default {
  getModules(data) {
    return Http().post("sections", data);
  },
  getAvailableVideoLanguages(data) {
    return Http().post("video/language/available", data);
  },
  getModuleTypes() {
    return Http().post("section/types");
  },
  saveModule(data) {
    return Http().post("section/add", data);
  },
  saveVideo(data) {
    return Http().post("video/save", data);
  },
  deleteVideo(data) {
    return Http().post("video/delete", data);
  },
  getSubtitleLanguage() {
    return Http().post("subtitle/languages");
  },
  getAvailableExamLanguages(data) {
    return Http().post("exam/language/available", data);
  },
  createExam(data) {
    return Http().post("exam/add", data);
  },
  getExams(data) {
    return Http().post("exams", data);
  },
  saveQuestion(data) {
    return Http().post("question/add", data);
  },
  deleteQuestion(data) {
    return Http().post("question/delete", data);
  },
  getQuestion(data) {
    return Http().post("question/get", data);
  },
  getActiveModules(data) {
    return Http().post("course/watch/modules", data);
  },
  getOtherLanguagesVideos(data) {
    return Http().post("videos/other-languages", data);
  },
  getQuestions(data) {
    return Http().post("exam/questions", data);
  },
  getOtherLanguagesExams(data) {
    return Http().post("exams/other-languages", data);
  },
  submitExam(data) {
    return Http().post("exam/submit", data);
  },
  saveSubtitle(data) {
    return Http().post("subtitle/save", data);
  },
  getAvailableArticleLanguages(data) {
    return Http().post("article/language/available", data);
  },
  createArticle(data) {
    return Http().post("save/article", data);
  },
  getArticle(data) {
    return Http().post("article/get", data);
  },
  upload(file, articleId, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("article_id", articleId);

    return Http().post("article/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  },
  deleteArticle(data) {
    return Http().post("article/delete", data);
  },
  getOtherLanguagesArticles(data) {
    return Http().post("article/other-languages", data);
  },
  getArticleFiles(data) {
    return Http().post("article/files", data);
  },
  downloadFile(file, folder) {
    return Http().get("file/download?file=" + file + "&folder=" + folder, {
      responseType: "arraybuffer"
    });
  },
  completeSection(data) {
    return Http().post("section/complete", data);
  },
  checkPreviousModulesWatched(data) {
    return Http().post("section/complete/check", data);
  },
  getVideoSubtitles(data) {
    return Http().post("video/subtitles", data);
  }
};
