<template>
  <div class="questionnaire">
    <div class="container text-right mb-5">
      <a @click="$router.go(-1)" class="btn cta-clear">
        Back to modules
      </a>
    </div>
    <div class="container questionnaire-wrap">
      <div class="questionnaire__header">
        <h5 class="color-1">Questionnaire</h5>
      </div>
      <div class="questionnaire__form">
        <form @submit.prevent="saveQuestion">
          <div class="form-row">
            <div class="col-md-4 form-group">
              <label for="categorySelect">Select Language</label>
              <multiselect
                v-model="language"
                :options="languageOptions"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                track-by="id"
                label="language"
                placeholder="Available language"
              ></multiselect>
              <div class="error-message">
                {{ validation.firstError("language") }}
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 form-group">
              <label for="question">Add question</label>
              <input
                v-model="question"
                type="text"
                class="form-control"
                id="question"
                placeholder="Type your question"
              />
              <div class="error-message">
                {{ validation.firstError("question") }}
              </div>
            </div>
          </div>
          <div class="answer-wrap">
            <div class="form-row">
              <div class="col-md-10 form-group">
                <label>Add answers</label>
              </div>
              <div class="col-md-2 form-group">
                <label>Correct answers</label>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-10 form-group">
                <span>A</span>
                <input
                  v-model="answers[0]"
                  type="text"
                  class="form-control"
                  id="answer_one"
                  placeholder="answer"
                />
                <div class="error-message">
                  {{ validation.firstError("answers") }}
                </div>
              </div>
              <div class="col-md-2 form-group">
                <input
                  type="radio"
                  checked="checked"
                  name="correct-answer"
                  v-model="correctAnswer"
                  value="0"
                  class="correct-answer"
                />
              </div>
              <div class="col-md-10 form-group">
                <span>B</span>
                <input
                  v-model="answers[1]"
                  type="text"
                  class="form-control"
                  id="answer_two"
                  placeholder="answer"
                />
              </div>
              <div class="col-md-2 form-group">
                <input
                  type="radio"
                  name="correct-answer"
                  v-model="correctAnswer"
                  class="correct-answer"
                  value="1"
                />
              </div>
              <div class="col-md-10 form-group">
                <span>C</span>
                <input
                  v-model="answers[2]"
                  type="text"
                  class="form-control"
                  id="answer_three"
                  placeholder="answer"
                />
              </div>
              <div class="col-md-2 form-group">
                <input
                  type="radio"
                  name="correct-answer"
                  v-model="correctAnswer"
                  class="correct-answer"
                  value="2"
                />
              </div>
              <div class="col-md-10 form-group">
                <span>D</span>
                <input
                  v-model="answers[3]"
                  type="text"
                  class="form-control"
                  id="answer_four"
                  placeholder="answer"
                />
              </div>
              <div class="col-md-2  form-group">
                <input
                  type="radio"
                  name="correct-answer"
                  v-model="correctAnswer"
                  class="correct-answer"
                  value="3"
                />
              </div>
            </div>
          </div>
          <div class="text-right mb-4">
            <button
              type="submit"
              :disabled="this.isDisabledButton == true"
              class="btn cta-primary"
            >
              Add
            </button>
          </div>
        </form>

        <div class="question-group">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li
              class="nav-item"
              v-for="(exam, examIndex) in exams"
              :key="examIndex"
            >
              <a
                class="nav-link"
                :class="{ active: examIndex === 0 }"
                :id="'language' + exam.id + 'tab'"
                data-toggle="tab"
                :href="'#tab' + exam.id"
                role="tab"
                aria-selected="true"
                >{{ exam.language }}
              </a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade"
              :class="{ show: examIndex === 0, active: examIndex === 0 }"
              v-for="(exam, examIndex) in exams"
              :key="examIndex"
              :id="'tab' + exam.id"
              role="tabpanel"
            >
              <div
                class="questionnaire-block row"
                v-for="(question, questionIndex) in exam.questions"
                :key="questionIndex"
              >
                <div
                  class="col-md-11 d-flex"
                  :data-id="question.id"
                  v-on:click.self="editQuestion"
                >
                  <span class="block-count">{{ questionIndex + 1 }}</span>
                  <p>{{ question.question }}</p>
                </div>
                <div
                  class="col-md-1 justify-content-end align-items-center d-flex card-block__icon-col "
                >
                  <button
                    :data-id="question.id"
                    class=" btn delete-btn"
                    @click="deleteQuestion"
                  >
                    <img
                      src="../assets/delete-icon.svg"
                      :data-id="question.id"
                      @click="deleteQuestion"
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div
                class="questionnaire-block row"
                v-if="exam.questions.length == 0"
              >
                <div class="col-md-11 d-flex">
                  <span class="block-count"></span>
                  <p>Please add questions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ModuleService from "@/services/ModuleService";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
export default {
  name: "Questions",
  components: {
    Multiselect
  },
  data() {
    return {
      moduleId: "",
      exams: [],
      questions: [],
      languageOptions: [],
      language: "",
      answers: [],
      question: "",
      correctAnswer: 0,
      correctAnswerArray: [],
      isDisabledButton: false,
      questionId: ""
    };
  },
  validators: {
    language: function(value) {
      return Validator.value(value).required("Please select a language");
    },
    question: function(value) {
      return Validator.value(value).required("Please enter a question");
    },
    answers: function(value) {
      return Validator.value(value).required("Please enter answers");
    }
  },
  created: function() {
    this.moduleId = this.$route.params.moduleId;
    this.getExams();
    this.getAvailableExamLanguages();
  },
  methods: {
    async getExams() {
      await ModuleService.getExams({ section_id: this.moduleId })
        .then(result => {
          this.exams = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getAvailableExamLanguages() {
      await ModuleService.getAvailableExamLanguages({
        section_id: this.moduleId
      })
        .then(result => {
          this.languageOptions = result.data.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async saveQuestion() {
      await this.$validate().then(success => {
        if (success) {
          this.correctAnswerArray = [];
          this.isDisabledButton = true;
          for (let i = 0; i < 4; i++) {
            if (this.correctAnswer == i) {
              this.correctAnswerArray.push(1);
            } else {
              this.correctAnswerArray.push(0);
            }
          }
          const data = {
            question: this.question,
            answers: this.answers,
            is_correct_answer: this.correctAnswerArray,
            section_id: this.moduleId,
            language_id: this.language.id,
            id: this.questionId
          };
          ModuleService.saveQuestion(data)
            .then(result => {
              this.isDisabledButton = false;
              if (result.data.status == "success") {
                this.$toastr.s(result.data.message);
                this.getExams();
                this.language = "";
                this.question = "";
                this.answers = [];
                this.questionId = "";
              } else {
                this.$toastr.e(result.data.message);
              }
            })
            .catch(error => {
              this.error = error;
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    deleteQuestion: function(event) {
      let questionId = event.target.getAttribute("data-id");
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            question_id: questionId
          };
          ModuleService.deleteQuestion(data)
            .then(result => {
              if (result.data.status == "success") {
                this.getExams();
                this.$toastr.s(result.data.message);
              } else {
                this.$toastr.e(result.data.message);
              }
            })
            .catch(error => {
              this.error = error;
              console.log(error);
              // this.$toastr.e("Something went wrong");
            });
        }
      });
    },
    editQuestion(event) {
      let questionId = event.target.getAttribute("data-id");
      console.log(questionId);
      ModuleService.getQuestion({ question_id: questionId })
        .then(result => {
          this.question = result.data.question;
          this.questionId = result.data.id;
          this.language = this.languageOptions.find(
            languageOptions => languageOptions.id === result.data.language_id
          );
          for (let i = 0; i < result.data.answers.length; i++) {
            this.answers[i] = result.data.answers[i].answer;
            if (result.data.answers[i].is_correct_answer == 1) {
              this.correctAnswer = i;
            }
          }
        })
        .catch(error => {
          this.error = error;
          console.log(error);
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>
