import Http from "@/services/Http";
export default {
  getMembershipPlans() {
    return Http().post("membership/plans");
  },
  saveMembership(data) {
    return Http().post("membership/save", data);
  },
  getMembershipDetails() {
    return Http().post("membership/details");
  }
};
