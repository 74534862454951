<template>
  <div class="blog-listing container search-listing webinar-listing">
    <div class="row result-wrapper">
      <div class="col-md-3">
        <div class="filter-wrap">
          <div class="mobile-nav">
            <button
              class="btn btn-block mobile-nav-button"
              @click="toggleButton"
            >
              Categories
            </button>
          </div>
          <div class="filter-sidebar" :class="{ active: isFilterActive }">
            <ul class="list-unstyled">
              <li><h6>All Categories</h6></li>
              <div
                v-for="(category, categoryIndex) in categories"
                :key="categoryIndex"
              >
                <button
                  class="btn cta-filter"
                  data-toggle="collapse"
                  :href="'#multiCollapseExample' + category.id"
                  role="button"
                  aria-expanded="false"
                  :aria-controls="'multiCollapseExample' + categoryIndex"
                  :class="getMyClasses(category.id)"
                  @click="mainCategoryFilter(category.id)"
                >
                  {{ category.title }}
                </button>
                <li
                  class="collapse multi-collapse sub-list"
                  :class="'multiCollapseExample' + category.id"
                  :id="'multiCollapseExample' + category.id"
                  v-for="(subCategory,
                  subCategoryIndex) in category.subcategories"
                  :key="subCategoryIndex"
                >
                  <div class="container-checkbox">
                    <input
                      type="checkbox"
                      v-model="categoryFilter"
                      :value="subCategory.id"
                      @change="resetPage"
                    />
                    <label>{{ subCategory.title }}</label>
                  </div>
                </li>
              </div>
            </ul>
            <hr class="mr-4" />
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="blog-list-wrap mt-0">
          <div
            class="head-title d-flex justify-content-between align-items-center"
          >
            <h5 class="color-1">Courses</h5>
          </div>

          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="pills-upcoming-tab"
                data-toggle="pill"
                href="#pills-upcoming"
                role="tab"
                aria-controls="pills-upcoming"
                aria-selected="true"
                @click="resetPage"
                >Upcoming Courses</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-Past-tab"
                data-toggle="pill"
                href="#pills-Past"
                role="tab"
                aria-controls="pills-Past"
                aria-selected="false"
                @click="resetPage"
                >Past Courses</a
              >
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-upcoming"
              role="tabpanel"
              aria-labelledby="pills-upcoming-tab"
            >
              <div class="blog-list-row row" v-if="loader == true">
                <loader></loader>
              </div>
              <div class="blog-list-row row" v-else>
                <OfflineCourseCard
                  v-for="(course, courseIndex) in courses"
                  :key="courseIndex"
                  :course="course"
                ></OfflineCourseCard>
              </div>

              <div
                class="not-found-wrapper"
                v-if="courses.length == 0 && loader == false"
              >
                <img src="@/assets/not-found.svg" alt="not-found" />
                <h4>No Upcoming Course Found !</h4>
              </div>

              <div class="pagination-wrap">
                <SlidingPagination
                  :current="currentPage"
                  :total="totalPages"
                  @page-change="pageChangeHandler"
                  v-if="loader == false && totalPages > 1"
                >
                </SlidingPagination>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-Past"
              role="tabpanel"
              aria-labelledby="pills-Past-tab"
            >
              <div class="blog-list-row row" v-if="loader == true">
                <loader></loader>
              </div>
              <div class="blog-list-row row" v-else>
                <OfflineCourseCard
                  v-for="(course, courseIndex) in previousCourses"
                  :key="courseIndex"
                  :course="course"
                ></OfflineCourseCard>
              </div>

              <div
                class="not-found-wrapper"
                v-if="previousCourses.length == 0 && loader == false"
              >
                <img src="@/assets/not-found.svg" alt="not-found" />
                <h4>No Previous Course Found !</h4>
              </div>
              <div class="pagination-wrap">
                <SlidingPagination
                  :current="previousCurrentPage"
                  :total="previousTotalPages"
                  @page-change="PreviousPageChangeHandler"
                  v-if="loader == false && previousTotalPages > 1"
                >
                </SlidingPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfflineCourseCard from "@/components/OfflineCourseCard.vue";
import OfflineCourseService from "@/services/OfflineCourseService";
import SlidingPagination from "vue-sliding-pagination";
import Loader from "../components/Loader.vue";
import CategoryService from "@/services/CategoryService";
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
export default {
  name: "WebinarsList",
  components: {
    OfflineCourseCard,
    SlidingPagination,
    Loader
  },
  data: function() {
    return {
      courses: [],
      totalResult: 0,
      resultFrom: 0,
      resultTo: 0,
      currentPage: 0,
      totalPages: 0,
      loader: true,
      languages: [],
      categories: [],
      prices: [],
      categoryFilter: [],
      languageFilter: [],
      isFilterActive: false,
      mainCategories: [],
      previousCourses: [],
      previousTotalResult: 0,
      previousResultFrom: 0,
      previousResultTo: 0,
      previousCurrentPage: 0,
      previousTotalPages: 0
    };
  },
  created: function() {
    this.getOfflineCourses(0);
    this.getCategories();
  },
  methods: {
    async getOfflineCourses(page) {
      const data = {
        categories: this.categoryFilter,
        languages: this.languageFilter,
        mainCategories: this.mainCategories
      };
      await OfflineCourseService.getOfflineCourses(data, page)
        .then(result => {
          this.loader = false;
          this.courses = result.data.upcoming.data;
          this.totalPages = result.data.upcoming.last_page;
          this.currentPage = result.data.upcoming.current_page;
          this.totalResult = result.data.upcoming.total;
          this.resultFrom = result.data.upcoming.from;
          this.resultTo = result.data.upcoming.to;
          this.previousCourses = result.data.completed.data;
          this.previousTotalPages = result.data.completed.last_page;
          this.previousCurrentPage = result.data.completed.current_page;
          this.previousTotalResult = result.data.completed.total;
          this.previousResultFrom = result.data.completed.from;
          this.previousResultTo = result.data.completed.to;
        })
        .catch(error => {
          this.loader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },

    async getCategories() {
      await CategoryService.getCategories()
        .then(result => {
          this.categories = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    getMyClasses: function(id) {
      let isFind = false;
      for (var i = this.mainCategories.length - 1; i >= 0; i--) {
        if (this.mainCategories[i] == id) {
          isFind = true;
        }
      }
      if (isFind == true) {
        return "active";
      } else {
        return "";
      }
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
      this.getOfflineCourses(this.currentPage);
      window.scrollTo(0, 0);
    },
    PreviousPageChangeHandler(selectedPage) {
      this.previousCurrentPage = selectedPage;
      this.getOfflineCourses(this.previousCurrentPage);
      window.scrollTo(0, 0);
    },
    resetPage: function() {
      this.currentPage = 1;
      this.previousCurrentPage = 1;
      this.loader = true;
      this.getOfflineCourses(this.currentPage);
    },
    mainCategoryFilter(id) {
      let isFind = false;
      for (var i = this.mainCategories.length - 1; i >= 0; i--) {
        if (this.mainCategories[i] == id) {
          this.mainCategories.splice(i, 1);
          isFind = true;
        }
      }
      if (isFind == false) {
        this.mainCategories.push(id);
      }
      this.resetPage();
    },
    toggleButton: function() {
      if (this.isFilterActive == false) {
        this.isFilterActive = true;
      } else {
        this.isFilterActive = false;
      }
    }
  }
};
</script>
