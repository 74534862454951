import VimeoHttp from "@/services/VimeoHttp";
export default {
  getUploadUrl(body) {
    return VimeoHttp().post("me/videos", body);
  },
  deleteVideo(videoId) {
    return VimeoHttp().delete("videos/" + videoId);
  },
  getVideoUrl(videoId) {
    return VimeoHttp().get("videos/" + videoId);
  },
  getSubtitleUploadUrl(body, url) {
    return VimeoHttp().post(url, body);
  },
  getThumbnail(videoId) {
    return VimeoHttp().get("videos/" + videoId + "/pictures");
  }
};
