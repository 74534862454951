<template>
  <div class="recruiter-wall container">
    <div class="row">
      <div class="col-md-3">
        <div class="course-card">
          <h6>Photography</h6>
          <ul class="list-unstyled">
            <li>
              <span>
                <img src="../assets/certify-icon.svg" alt="certify" />
              </span>
              <p>Certified members</p>
            </li>
            <li>
              <span>
                <img src="../assets/certificate-icon.svg" alt="certificate" />
              </span>
              <p>Courses</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecruiterWall"
};
</script>
