<template>
  <div class="about">
    <div class="about-banner container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="w-100" src="../assets/abt-hero.png" alt="" />
        </div>
        <div class="col-md-6">
          <div class="text-content">
            <h2>
              We understand that in uplifting others we uplift ourselves.
            </h2>
            <h3>Fempower Together</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="block-content">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="solid-block">
              <p>
                Across the globe, in most societies, women have for long been
                bound by ideas and conditioning rooted in patriarchy. But every
                now and then there comes a woman—or a girl— who refuses to be
                held back. She changes the status quo and shows how it’s done.
                She shows the world that anyone is capable of being an
                inspiration— despite their background, despite their
                circumstances, despite how the world sees them. She dares to
                dream and pursues it passionately.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="clear-block">
              <!-- <h5>
                OlogyWomen is for all these women and girls- the inspiring and
                the inspired.
              </h5> -->
              <p>
                OlogyWomen (and OlogyGirls) is for all these women and girls -
                the inspiring and the inspired. A safe community for women where
                we celebrate the small wins and the big victories, where the
                acknowledgement of one’s presence is as important as the
                recognition of another’s accomplishments, where we engage and
                learn from one another.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wedo-wrapper">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-md-6">
            <div class="_content">
              <div class="custom-head">
                <h2>
                  What we do
                </h2>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="_content">
              <h5>
                The dictionary defines Ology as a subject of study, a branch of
                knowledge.
              </h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="_content">
              <p class="m-0">
                While it’s true that schools and colleges can offer an
                education, learning happens through life. Being self-aware and
                regularly updating and upskilling oneself is not just
                empowering, but important to navigate a constantly evolving
                world. <br />
                <br />

                At OlogyWomen, we seek to enable insightful learning and foster
                meaningful connections and growth by engaging the community
                through various interactive engagements on diverse and relevant
                subjects. This happens through dialogues, discussions and
                customised programs such as workshops, webinars, lecture
                demonstrations, and interviews to name a few.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="_content">
              <p>
                And while at it, we actively seek out and include the younger of
                our tribe so that they may have a better head start in
                adulthood. OlogyGirls, for young adults between 15 and 25 years,
                is as exciting, multifaceted and vibrant a community as
                OlogyWomen. <br />
                <br />

                Opportunities to learn abound everywhere, but learning needs to
                be fun, engaging and holistic in approach. The programs and
                engagements—workshops, webinars, online courses, dialogues et
                al— are tailored accordingly, to the specific needs and
                requirements of OlogyWomen and OlogyGirls.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mission-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="custom-head">
              <h2>
                Our Mission
              </h2>
            </div>
          </div>
          <div class="col-md-6">
            <h4>
              OlogyWomen strives to create a community of empowered women
              helping them find purpose and a profound sense of belonging.
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="brand-story">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-md-6">
            <div class="_logo-wrap">
              <img src="../assets/ology-girls-logo.jpg" alt="" />
            </div>
            <div class="_green-block">
              <h4>
                OlogyGirls, our community for young adults between 15 and 25
                years of age, adopts a holistic approach in equipping and
                moulding these young minds with the necessary skills so that
                they may blossom into confident young adults, well equipped to
                explore life ahead.
                <br />
                <router-link
                  :to="{ name: 'theFinishingSchool' }"
                  class="btn cta-primary mt-2"
                  >See More</router-link
                >
              </h4>
            </div>
            <div class="_clear-block">
              <p>
                Often, it is only as we mature we understand the meaning, power
                and value in being one’s own person. We may wish we did things
                differently even though we may be wiser for the experiences.
              </p>
              <p>
                As wives, mothers and professionals (and myriad other roles we
                take on), we, OlogyWomen have seen a little more of the world
                and its ways. That is why we are keen on nurturing the younger
                ones of our tribe—so that they may have a better head start at
                adulthood.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="_img-block">
              <div class="img-wrapper">
                <img src="../assets/abt-girls.jpg" alt="" />
              </div>
            </div>
            <div class="_yellow-block">
              <p>
                Programs for OlogyGirls viz., webinars, presentations,
                interviews, and online courses among others are designed to be
                engaging, informative and structured. And span a wide range of
                subjects. These are all facilitated by experts in their domains,
                and hosted regularly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>
