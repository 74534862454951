var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PaymentStatus"},[(_vm.loader == true)?_c('loader'):_c('div',{staticClass:"container status-wrapper"},[(_vm.status == 'success')?_c('div',{staticClass:"image-wrap success-img"},[_c('img',{attrs:{"src":require("../assets/success-clipimg.svg"),"alt":""}})]):_c('div',{staticClass:"image-wrap failed-img"},[_c('img',{attrs:{"src":require("../assets/failed-clipimg.svg"),"alt":""}})]),(_vm.status == 'success')?_c('div',{staticClass:"message-box text-center py-4"},[_c('h6',[_vm._v("Transaction Successful.")]),(_vm.source == 'course')?_c('p',[_vm._v(" Your order of"),_c('a',{attrs:{"href":"#"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.$router.push({
              name: 'courseDetails',
              params: { slug: _vm.payment.slug }
            })}}},[_vm._v(_vm._s(_vm.payment.course_name))]),_vm._v(" Transaction Id: "),_c('a',{attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm.payment.transaction_id))]),_vm._v(" was successfully processed , A copy of invoice has been sent to registered mail ")]):_vm._e(),(_vm.source == 'membership')?_c('p',[_vm._v(" Your membership request with Transaction Id: "),_c('a',{attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm.payment.transaction_id))]),_vm._v(" was successfully processed , A copy of invoice has been sent to registered mail ")]):_vm._e()]):_c('div',{staticClass:"message-box text-center py-4"},[_c('h6',[_vm._v("Page Not found")])]),(_vm.status == 'success')?_c('div',{staticClass:"text-center"},[(_vm.source == 'course')?_c('button',{staticClass:"btn cta-clear",on:{"click":function($event){return _vm.$router.push({
            name: 'courseDetails',
            params: { slug: _vm.payment.slug }
          })}}},[_vm._v(" View course ")]):_vm._e(),_c('button',{staticClass:"btn cta-primary"},[_vm._v("Invoice")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }