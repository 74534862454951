<template>
  <div class="course-details">
    <div class="container-fluid course-video-wrap">
      <div class="container" v-if="isCourseLoading == false">
        <div class="row course-video__head no-gutters">
          <div class="col-md-8 d-flex justify-content-between">
            <div class="course-video__head-title">
              <h3 class="card-title">{{ course.title }}</h3>
              <div class="author-info">
                <span>
                  Created by
                  <router-link
                    class="author-name"
                    :to="{
                      name: 'publicProfile',
                      params: { name: course.name, id: course.user_id }
                    }"
                    >{{ course.name }}</router-link
                  >
                </span>
                <div class="rating">
                  <div class="d-flex align-items-center">
                    <span class="pr-1">{{ course.rating }}</span>
                    <div>
                      <i
                        class="fa fa-star fill"
                        v-for="filledIndex in getFloorRating"
                        :key="filledIndex"
                      ></i>
                    </div>
                    <div>
                      <i
                        class="fa fa-star plain"
                        v-for="emptyIndex in getRemainingRating"
                        :key="emptyIndex"
                      ></i>
                    </div>
                    <p class="review-count">({{ reviews.length }} Reviews)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-right" v-if="isPurchased == false">
            <div class="course-video__head-price">
              <h3 class="price-tag" v-if="course.price > 0">
                <span class="rupee">₹</span
                >{{
                  Math.round(course.price - course.price * (course.offer / 100))
                }}
                <span v-if="course.offer > 0">({{ course.offer }}% Off)</span>
                <del v-if="course.offer > 0"
                  ><span class="rupee">₹</span>{{ course.price }}</del
                >
              </h3>
              <h3 class="price-tag" v-else>
                Free
              </h3>
            </div>
            <button
              v-if="course.price > 0"
              class="btn cta-primary"
              @click="
                $router.push({
                  name: 'checkout',
                  params: { slug: course.slug }
                })
              "
            >
              Buy now
            </button>
            <button
              v-else
              class="btn cta-primary"
              :disabled="disabledButton == true"
              @click="freeCoursePurchase"
            >
              View Now
            </button>
          </div>
        </div>
        <div class="row course-video__content no-gutters">
          <div class="col-md-8" v-if="showFinalPage == true">
            <div class="video-wrap">
              <div class="video_complete-block">
                <div class="video-complete_content">
                  <img class="congrats-img" src="../assets/badge.svg" alt="" />
                  <h5>Congratulations</h5>
                  <p>
                    You have successfully completed this course. The certificate
                    has been sent to your email id.
                  </p>
                  <button
                    data-toggle="modal"
                    data-target="#add-reviewModal"
                    class="btn cta-primary"
                  >
                    Add Review
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8" v-else-if="isContentLoader == false">
            <div class="video-wrap" v-if="isPurchased == false">
              <img :src="course.image" width="100%" />
              <div class="video_lock-block">
                <div class="lock-block_content">
                  <h5 v-if="course.price > 0">
                    Purchase the course to continue watching
                  </h5>
                  <h5 v-else>
                    Click View now to continue watching
                  </h5>
                  <p v-if="course.price > 0">
                    Get courses offline to watch in your convenience, also get
                    access to downloadable resources
                  </p>
                  <button
                    v-if="course.price > 0"
                    class="btn cta-primary "
                    @click="
                      $router.push({
                        name: 'checkout',
                        params: { slug: course.slug }
                      })
                    "
                  >
                    Buy now
                  </button>
                  <button
                    v-else
                    class="btn cta-primary"
                    :disabled="disabledButton == true"
                    @click="freeCoursePurchase"
                  >
                    View Now
                  </button>
                </div>
              </div>
            </div>
            <div
              class="video-wrap"
              v-if="moduleType == 1 && isPurchased == true"
            >
              <vueVimeoPlayer
                ref="player"
                :video-id="videoId"
                @ready="onReady"
                @timeupdate="timeUpdate"
                :player-height="height"
                :options="options"
                @ended="completeVideo"
              ></vueVimeoPlayer>
            </div>
            <div
              class="questionnaire-wrap"
              v-if="moduleType == 2 && isPurchased == true"
            >
              <p class="info-label">
                Please answer the following questions correctly
              </p>

              <div class="video-wrap" v-if="isAlreadySubmitted == true">
                <div class="lock-block_content">
                  <h5>You are already attended this exam</h5>
                  <p>{{ securedScore }}</p>
                </div>
              </div>

              <div class="question-block" v-else-if="isQuestionLoader == false">
                <h4>{{ question.question }}</h4>
                <div class="input-group">
                  <ul class="list-unstyled">
                    <li
                      v-for="(answer, answerIndex) in question.answers"
                      :key="answerIndex"
                    >
                      <input
                        type="radio"
                        :name="'answer' + answerIndex"
                        :value="answer.id"
                        aria-label="Radio button"
                        v-model="selectedAnswer"
                      />
                      <p>{{ answer.answer }}</p>
                    </li>
                    <li class="error-message" v-if="answerError != ''">
                      {{ answerError }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="question-block" v-else>
                <loader></loader>
              </div>
              <div
                class="action-div"
                v-if="isQuestionLoader == false && isAlreadySubmitted == false"
              >
                <button
                  v-if="questionIndex > 0"
                  class="btn cta-clear left"
                  @click.prevent="previousQuestion(question.id)"
                >
                  Previous
                </button>
                <button
                  v-if="questionIndex < questions.length - 1"
                  class="btn cta-clear right"
                  @click.prevent="nextQuestion(question.id)"
                >
                  Next
                </button>
                <button
                  v-if="questionIndex >= questions.length - 1"
                  class="btn cta-clear right"
                  :disabled="isSubmittingExam == true"
                  @click.prevent="submitExam(question.id)"
                >
                  Submit
                </button>
              </div>
            </div>
            <div
              class="questionnaire-wrap"
              v-if="moduleType == 3 && isPurchased == true"
            >
              <div class="question-block">
                <div class="input-group">
                  <p v-html="articleContent"></p>
                </div>
              </div>
              <div class="file">
                <p class="thread-label">
                  Related files
                </p>
                <div class="row">
                  <div
                    class="col-md-3"
                    v-for="(articlePdfFile, pdfIndex) in articleFiles"
                    :key="pdfIndex"
                  >
                    <a
                      href="#"
                      @click.prevent="
                        downloadFile(
                          articlePdfFile.title,
                          articlePdfFile.original_name,
                          'article'
                        )
                      "
                    >
                      <div class="thumbnail">
                        <img
                          class="img-fluid"
                          src="../assets/question-thumb.png"
                          alt=""
                        />
                      </div>
                      {{ articlePdfFile.original_name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8" v-else-if="isPreviousModuleWatched == false">
            <div class="video-wrap">
              <div class="video_lock-block">
                <div class="lock-block_content">
                  <h5>Please watch previous module</h5>
                  <p>
                    You can unlock this module by complete all previous module
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8" v-else>
            <loader></loader>
          </div>
          <div class="col-md-4">
            <div class="module-list-wrap">
              <div class="module-list__head">
                <h5>Modules</h5>
                <ul
                  class="list-unstyled module-listing"
                  v-if="isModuleLoader == false"
                >
                  <li
                    v-for="(module, moduleIndex) in modules"
                    :key="moduleIndex"
                    :class="{
                      locked: isPurchased === false
                    }"
                    @click.prevent="changeModuleContent"
                    :data-id="module.id"
                    :data-index="moduleIndex"
                  >
                    <img
                      src="../assets/Play-bg.svg"
                      class="svg"
                      alt=""
                      :data-id="module.id"
                      :data-index="moduleIndex"
                    />
                    <a href="#" :data-id="module.id" :data-index="moduleIndex">
                      {{ module.title }}
                    </a>
                  </li>
                  <li>
                    <button
                      class="btn cta-primary"
                      @click="getCertificate"
                      v-if="showCertificateButton == true"
                      :disabled="certificateLoader == true"
                    >
                      Get Certificate
                    </button>
                  </li>
                </ul>
                <ul v-else>
                  <loader></loader>
                </ul>
              </div>
              <div class="additional-info">
                <p class="watch_stat">
                  <i class="far fa-eye"></i>
                  {{ totalPurchases }} views
                </p>
                <p class="time_stat">
                  <i class="far fa-clock"></i>
                  {{ course.duration }}
                </p>
                <p class="time_stat" v-if="subtitles != ''">
                  <span>Subtitles: </span>
                  {{ subtitles }}
                </p>
                <div class="thread-keywords keyword-wrapper">
                  <p class="thread-label"></p>
                  <ul class="list-unstyled">
                    <li>
                      <span class="thread-label">Available Languages </span>
                    </li>
                    <li
                      v-for="(language, languageIndex) in course.languages"
                      :key="languageIndex"
                    >
                      <button class="btn keywordTag">
                        {{ language.language }}
                      </button>
                    </li>
                  </ul>
                  <ul class="list-unstyled" v-if="moduleType == 1">
                    <li v-if="otherLanguageVideos.length > 0">
                      <span class="thread-label"
                        >This video also available in:</span
                      >
                    </li>
                    <li
                      v-for="(otherLanguage,
                      otherLanguageIndex) in otherLanguageVideos"
                      :key="otherLanguageIndex"
                    >
                      <button
                        class="btn keywordTag"
                        @click.prevent="
                          changeVideoLanguage(
                            otherLanguage.video,
                            otherLanguage.id
                          )
                        "
                      >
                        {{ otherLanguage.language }}
                      </button>
                    </li>
                  </ul>
                  <ul class="list-unstyled" v-if="moduleType == 2">
                    <li v-if="otherLanguageExams.length > 0">
                      <span class="thread-label"
                        >This exam also available in:</span
                      >
                    </li>
                    <li
                      v-for="(otherLanguage,
                      otherLanguageIndex) in otherLanguageExams"
                      :key="otherLanguageIndex"
                    >
                      <button
                        class="btn keywordTag"
                        @click.prevent="changeExamLanguage(otherLanguage.id)"
                      >
                        {{ otherLanguage.language }}
                      </button>
                    </li>
                  </ul>
                  <ul class="list-unstyled" v-if="moduleType == 3">
                    <li v-if="otherLanguageArticles.length > 0">
                      <span class="thread-label"
                        >This article also available in:</span
                      >
                    </li>
                    <li
                      v-for="(otherLanguage,
                      otherLanguageIndex) in otherLanguageArticles"
                      :key="otherLanguageIndex"
                    >
                      <button
                        class="btn keywordTag"
                        @click.prevent="changeArticleLanguage(otherLanguage)"
                      >
                        {{ otherLanguage.language }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row course-video__threads" v-if="moduleType == 1">
          <div
            class="col-md-8 video__threads-items"
            v-if="otherLanguageVideos.length > 0"
          >
            <p class="thread-label">
              Available in {{ otherLanguageVideos.length }} other language
            </p>
            <a
              href="#"
              v-for="(video, videoIndex) in otherLanguageVideos"
              :key="videoIndex"
              @click.prevent="changeVideoLanguage"
              :data-video="video.video"
              :data-id="video.id"
            >
              <div class="thumbnail" :data-id="video.id">
                <img
                  class="img-fluid"
                  src="../assets/video-thumb.jpg"
                  alt=""
                  :data-video="video.video"
                  :data-id="video.id"
                />
              </div>
              {{ video.language }}
            </a>
          </div>
          <div class="col-md-8 video__threads-items" v-else>
            <p class="thread-label" v-if="showFinalPage == false">
              No other languages are available
            </p>
          </div>
          <div class="col-md-4 thread-keywords keyword-wrapper">
            <p class="thread-label">keyword</p>
            <ul class="list-unstyled">
              <li
                v-for="(keyword, keywordIndex) in keywords"
                :key="keywordIndex"
              >
                <button class="btn keywordTag">{{ keyword }}</button>
              </li>
            </ul>
            <p class="thread-label">Available Language</p>
            <ul class="list-unstyled">
              <li
                v-for="(language, languageIndex) in course.languages"
                :key="languageIndex"
              >
                <button class="btn keywordTag">{{ language.language }}</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row course-video__threads" v-if="moduleType == 2">
          <div
            class="col-md-8 video__threads-items"
            v-if="otherLanguageExams.length > 0"
          >
            <p class="thread-label">
              Available in {{ otherLanguageExams.length }} other language
            </p>
            <a
              href="#"
              v-for="(exam, examIndex) in otherLanguageExams"
              :key="examIndex"
              @click.prevent="changeExamLanguage"
              :data-id="exam.id"
            >
              <div class="thumbnail" :data-id="exam.id">
                <img
                  class="img-fluid"
                  src="../assets/question-thumb.png"
                  alt=""
                  :data-id="exam.id"
                />
              </div>
              {{ exam.language }}
            </a>
          </div>
          <div class="col-md-8 video__threads-items" v-else>
            <p class="thread-label" v-if="showFinalPage == false">
              No other languages are available
            </p>
          </div>
          <div class="col-md-4 thread-keywords keyword-wrapper">
            <p class="thread-label">keyword</p>
            <ul class="list-unstyled">
              <li
                v-for="(keyword, keywordIndex) in keywords"
                :key="keywordIndex"
              >
                <button class="btn keywordTag">{{ keyword }}</button>
              </li>
            </ul>
            <p class="thread-label">Available Language</p>
            <ul class="list-unstyled">
              <li
                v-for="(language, languageIndex) in course.languages"
                :key="languageIndex"
              >
                <button class="btn keywordTag">{{ language.language }}</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row course-video__threads" v-if="moduleType == 3">
          <div
            class="col-md-8 video__threads-items"
            v-if="otherLanguageArticles.length > 0"
          >
            <p class="thread-label">
              Available in {{ otherLanguageArticles.length }} other language
            </p>
            <a
              href="#"
              v-for="(article, articleIndex) in otherLanguageArticles"
              :key="articleIndex"
              @click.prevent="changeArticleLanguage(article)"
            >
              <div class="thumbnail">
                <img
                  class="img-fluid"
                  src="../assets/question-thumb.png"
                  alt=""
                />
              </div>
              {{ article.language }}
            </a>
            <div class="file">
              <p class="thread-label">
                Related files
              </p>
              <a
                href="#"
                v-for="(articlePdfFile, pdfIndex) in articleFiles"
                :key="pdfIndex"
                @click.prevent="
                  downloadFile(
                    '/storage/article/' + articlePdfFile.title,
                    articlePdfFile.original_name
                  )
                "
              >
                <div class="thumbnail">
                  <img
                    class="img-fluid"
                    src="../assets/question-thumb.png"
                    alt=""
                  />
                </div>
                {{ articlePdfFile.original_name }}
              </a>
            </div>
          </div>
          <div class="col-md-8 video__threads-items" v-else>
            <p class="thread-label" v-if="showFinalPage == false">
              No other languages are available
            </p>
          </div>
          <div class="col-md-4 thread-keywords keyword-wrapper">
            <p class="thread-label">keyword</p>
            <ul class="list-unstyled">
              <li
                v-for="(keyword, keywordIndex) in keywords"
                :key="keywordIndex"
              >
                <button class="btn keywordTag">{{ keyword }}</button>
              </li>
            </ul>
            <p class="thread-label">Available Language</p>
            <ul class="list-unstyled">
              <li
                v-for="(language, languageIndex) in course.languages"
                :key="languageIndex"
              >
                <button class="btn keywordTag">{{ language.language }}</button>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
      <div class="container" v-else>
        <Loader></Loader>
      </div>
    </div>
    <div class="container-fluid course-subcontent-wrap">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active show active"
                  id="pills-about-tab"
                  data-toggle="pill"
                  href="#pills-about"
                  role="tab"
                  aria-controls="pills-about"
                  aria-selected="true"
                  >About</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-review-tab"
                  data-toggle="pill"
                  href="#pills-review"
                  role="tab"
                  aria-controls="pills-review"
                  aria-selected="false"
                  >Review</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-discussion-tab"
                  data-toggle="pill"
                  href="#pills-discussion"
                  role="tab"
                  aria-controls="pills-discussion"
                  aria-selected="false"
                  >Discussion</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-project-tab"
                  data-toggle="pill"
                  href="#pills-project"
                  role="tab"
                  aria-controls="pills-project"
                  aria-selected="false"
                  >Projects</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-resource-tab"
                  data-toggle="pill"
                  href="#pills-resource"
                  role="tab"
                  aria-controls="pills-resource"
                  aria-selected="false"
                  >Resources</a
                >
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade active show"
                id="pills-about"
                role="tabpanel"
                aria-labelledby="pills-about-tab"
              >
                <div class="row about-wrap">
                  <div class="col-md-12">
                    <div v-html="course.description"></div>
                    <ul class="list-unstyled data-list">
                      <li>
                        Released date : <span>{{ course.released_on }}</span>
                      </li>
                      <li>
                        Course duration : <span>{{ course.duration }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4"></div>
                </div>
              </div>
              <div
                class="tab-pane fade "
                id="pills-review"
                role="tabpanel"
                aria-labelledby="pills-review-tab"
              >
                <div class="review-wrap">
                  <div class="text-right  review__addcta">
                    <p v-if="isPurchased == true">
                      Add review
                      <button
                        class="btn cta-add"
                        data-toggle="modal"
                        data-target="#add-reviewModal"
                      >
                        <img
                          src="../assets/plus-light.svg"
                          width="15px"
                          alt=""
                        />
                      </button>
                    </p>
                  </div>
                  <div class="reviews-list">
                    <ul class="list-unstyled">
                      <li
                        v-for="(review, reviewIndex) in reviews"
                        :key="reviewIndex"
                      >
                        <div class="row">
                          <div class="col-md-4">
                            <div class="profile-info-wrap">
                              <span class="thumb-img">
                                <img
                                  class="img-fluid"
                                  :src="review.image"
                                  alt=""
                                />
                              </span>
                              <a href="#">
                                {{ review.name }} {{ review.last_name }}
                              </a>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <div class="review-head">
                              <div class="rating">
                                <vue-stars
                                  :name="'displayRating' + reviewIndex"
                                  :value="review.rating"
                                  :max="5"
                                  :active-color="'#ffdd00'"
                                  :inactive-color="'#999999'"
                                  :shadow-color="'#ffff00'"
                                  :hover-color="'#dddd00'"
                                  :readonly="true"
                                >
                                  <span slot="activeLabel">
                                    <i class="fa fa-star fill"></i>
                                  </span>
                                  <span slot="inactiveLabel">
                                    <i class="fa fa-star plain"></i>
                                  </span>
                                </vue-stars>
                              </div>
                              <p>
                                Posted on: <span>{{ review.posted_on }}</span>
                              </p>
                            </div>
                            <p>
                              {{ review.review }}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-discussion"
                role="tabpanel"
                aria-labelledby="pills-discussion-tab"
              >
                <div class="discussion-wrap">
                  <div class="start-discussion-wrap" v-if="isLoggedIn == true">
                    <div class="image-wrap">
                      <img
                        class="img-fluid"
                        :src="avatar"
                        alt="Card image cap"
                      />
                    </div>
                    <discussion-form
                      :courseId="courseId"
                      :discussionId="null"
                    ></discussion-form>
                  </div>
                  <ul class="list-unstyled">
                    <li
                      v-for="(discussion, discussionIndex) in discussions"
                      :key="discussionIndex"
                    >
                      <CommentCard
                        :discussion="discussion"
                        :reply="discussion.children.length"
                      ></CommentCard>
                      <div class="comment-reply">
                        <CommentCard
                          v-for="(reply, replyIndex) in discussion.children"
                          :key="replyIndex"
                          :discussion="reply"
                          :reply="0"
                        >
                        </CommentCard>
                        <form>
                          <div class="reply-box" v-if="isLoggedIn == true">
                            <div class="image-wrap">
                              <img
                                class="img-fluid"
                                :src="avatar"
                                alt="Card image cap"
                              />
                            </div>
                            <discussion-form
                              :courseId="courseId"
                              :discussionId="discussion.id"
                            ></discussion-form>
                          </div>
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="tab-pane fade "
                id="pills-project"
                role="tabpanel"
                aria-labelledby="pills-review-tab"
              >
                <div class="text-right  review__addcta">
                  <p v-if="isPurchased == true">
                    Add Project
                    <button
                      class="btn cta-add"
                      data-toggle="modal"
                      data-target="#projectModal"
                    >
                      <img src="../assets/plus-light.svg" width="15px" alt="" />
                    </button>
                  </p>
                </div>
                <div class="review-wrap">
                  <div class="reviews-list">
                    <ul class="list-unstyled">
                      <li
                        v-for="(project, projectIndex) in projects"
                        :key="projectIndex"
                      >
                        <div class="row">
                          <div class="col-md-3">
                            <div class="profile-info-wrap">
                              <span class="thumb-img">
                                <img
                                  class="img-fluid"
                                  :src="project.image"
                                  alt=""
                                />
                              </span>
                              <a href="#">
                                {{ project.name }} {{ project.last_name }}
                              </a>
                            </div>
                          </div>
                          <div class="col-md-9">
                            <div class="review-head">
                              <div class="rating">
                                <p>{{ project.title }}</p>
                              </div>
                              <p>
                                Posted on: <span>{{ project.posted_on }}</span>
                              </p>
                            </div>
                            <p>
                              {{ project.description }}
                            </p>
                            <div class="pdf" v-if="project.file_type == 'pdf'">
                              <a
                                href="#"
                                @click.prevent="
                                  downloadFile(project.file, 'file', 'projects')
                                "
                              >
                                <div class="thumbnail">
                                  <img
                                    class="img-fluid"
                                    src="../assets/question-thumb.png"
                                    alt=""
                                  />
                                </div>
                                Download
                              </a>
                            </div>
                            <div class="pdf" v-else>
                              <img :src="project.file" />
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade "
                id="pills-resource"
                role="tabpanel"
                aria-labelledby="pills-resource-tab"
              >
                <div class="download-card-wrapper">
                  <div
                    class="download-card"
                    v-for="(resource, resourceIndex) in resources"
                    :key="resourceIndex"
                  >
                    <h5>{{ resource.title }}</h5>
                    <p>
                      Click download for get this file
                    </p>
                    <div class="download-tab">
                      <span>Download</span>
                      <button
                        class="btn"
                        @click.prevent="
                          downloadFile(
                            resource.file,
                            resource.title,
                            'resources'
                          )
                        "
                      >
                        <i class="fas fa-file-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="includes-wrap">
              <div class="includes-list__head">
                <h5>This course includes</h5>
              </div>
              <ul class="includes-list list-unstyled">
                <li>
                  <p>
                    <i class="fas fa-video"></i>
                    {{ resourceCount.videos }} Videos
                  </p>
                </li>
                <li v-if="resources.length > 0">
                  <p>
                    <i class="fas fa-file-download"></i>
                    {{ resources.length }} downloadable resources
                  </p>
                </li>
                <li>
                  <p>
                    <i class="fas fa-cloud-download-alt"></i>
                    Full lifetime access
                  </p>
                </li>
                <li>
                  <p>
                    <i class="fas fa-certificate"></i>
                    Certificate of completion
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--------add Review Modal-start -->
    <div
      class="modal fade add-review-wrap"
      id="add-reviewModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="add-reviewModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title color-1" id="add-reviewModalLabel">
              Add your review
            </h5>
          </div>
          <form @submit.prevent="submitReview">
            <div class="modal-body">
              <div class="form-row">
                <div class="col-md-8 form-group">
                  <label for="reviewHead">Rating </label>
                  <div class="rating">
                    <vue-stars
                      name="ratingInput"
                      :value="reviewRating"
                      :max="5"
                      :active-color="'#ffdd00'"
                      :inactive-color="'#999999'"
                      :shadow-color="'#ffff00'"
                      :hover-color="'#dddd00'"
                      @input="updateInputRating"
                    >
                      <span slot="activeLabel">
                        <i class="fa fa-star fill"></i>
                      </span>
                      <span slot="inactiveLabel">
                        <i class="fa fa-star plain"></i>
                      </span>
                    </vue-stars>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <label for="reviewContent">Review here</label>
                  <textarea
                    v-model="reviewDescription"
                    class="form-control"
                    id="review-description"
                    rows="2"
                    placeholder="Review..."
                  ></textarea>
                  <div class="error-message">
                    {{ validation.firstError("reviewDescription") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                ref="close"
                class="btn cta-clear"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn cta-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--------add Review Modal-end -->
    <!-- //popular-courses -->

    <div
      class="modal fade add-subtitle-wrap"
      id="projectModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="subtitleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="subtitleModalLabel">Create Project</h5>
          </div>
          <form @submit.prevent="createProject">
            <div class="modal-body">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Project Title"
                    v-model="projectTitle"
                  />
                  <div class="error-message">
                    {{ validation.firstError("projectTitle") }}
                  </div>
                  <div v-if="errors && errors.title" class="error-message">
                    {{ errors.title[0] }}
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <textarea
                    v-model="projectDescription"
                    class="form-control"
                    id="description"
                    rows="3"
                    placeholder="Project description"
                  >
                  </textarea>
                  <div class="error-message">
                    {{ validation.firstError("projectDescription") }}
                  </div>
                  <div
                    v-if="errors && errors.description"
                    class="error-message"
                  >
                    {{ errors.description[0] }}
                  </div>
                </div>
                <div class="col-md-12">
                  <input
                    type="file"
                    id="fileUpload"
                    ref="projectFile"
                    @change="selectFile"
                  />
                  <div class="error-message">
                    {{ validation.firstError("projectFile") }}
                  </div>
                  <div v-if="errors && errors.file" class="error-message">
                    {{ errors.file[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                ref="close"
                class="btn cta-clear"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                :disabled="disabledButton == true"
                class="btn cta-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <PopularCourses></PopularCourses>
  </div>
</template>

<script>
import PopularCourses from "@/components/PopularCourses.vue";
import CommentCard from "@/components/CommentCard.vue";
import CourseService from "@/services/CourseService";
import ModuleService from "@/services/ModuleService";
import Loader from "../components/Loader.vue";
import { vueVimeoPlayer } from "vue-vimeo-player";
import VueStars from "../components/VueStars.vue";
import SimpleVueValidation from "simple-vue-validator";
import DiscussionForm from "../components/DiscussionForm.vue";
import $ from "jquery";
const Validator = SimpleVueValidation.Validator;
export default {
  name: "CourseDetails",
  components: {
    PopularCourses,
    CommentCard,
    Loader,
    vueVimeoPlayer,
    VueStars,
    DiscussionForm
  },
  watch: {
    "$route.params.slug": function(slug) {
      this.slug = slug;
      this.watchCourse();
    }
  },
  data() {
    return {
      slug: this.$route.params.slug,
      course: [],
      rating: 0,
      isCourseLoading: true,
      keywords: [],
      modules: [],
      courseId: "",
      isPurchased: false,
      moduleType: "",
      videoId: "",
      height: 360,
      options: { controls: true, byline: false },
      playerReady: false,
      otherLanguageVideos: [],
      questions: [],
      question: "",
      answers: [],
      questionIndex: 0,
      otherLanguageExams: [],
      reviewRating: 5,
      reviewDescription: "",
      reviews: [],
      discussions: [],
      isContentLoader: true,
      isQuestionLoader: true,
      isModuleLoader: true,
      avatar: "",
      examId: "",
      selectedAnswer: "",
      selectedAnswerArray: [],
      answerError: "",
      isSubmittingExam: false,
      isAlreadySubmitted: false,
      securedScore: "",
      disabledButton: false,
      isLoggedIn: false,
      resourceCount: [],
      articleId: "",
      articleContent: "",
      otherLanguageArticles: [],
      articleFiles: [],
      moduleId: "",
      isPreviousModuleWatched: true,
      isLastModule: false,
      showFinalPage: false,
      certificateLoader: false,
      showCertificateButton: false,
      subtitles: "",
      totalPurchases: 0,
      projects: [],
      projectTitle: "",
      projectDescription: "",
      projectCourseId: "",
      projectFile: "",
      errors: {},
      resources: [],
      currentIndex: 0
    };
  },
  created: function() {
    this.watchCourse();
    this.isLoggedIn = this.$store.getters["auth/isLoggedIn"];
    if (this.isLoggedIn == true) {
      let user = JSON.parse(this.$store.state.auth.user);
      this.avatar = user.image;
    }
  },
  computed: {
    getFloorRating: function() {
      return Math.floor(this.rating);
    },
    getRemainingRating: function() {
      let currentRating = Math.floor(this.rating);
      let remainingRating = 5 - currentRating;
      return remainingRating;
    },
    getRating: function() {
      return parseFloat(this.rating, 2).toFixed(1);
    }
  },
  validators: {
    reviewDescription: function(value) {
      return Validator.value(value)
        .required("Please enter your review")
        .minLength(2)
        .maxLength(450);
    }
  },
  methods: {
    async watchCourse() {
      this.isCourseLoading = true;
      await CourseService.previewCourse({ slug: this.slug })
        .then(result => {
          if (result.data.course == null) {
            this.$router.push({ name: "PageNotFound" });
          }
          this.course = result.data.course;
          this.rating = this.course.rating;
          this.keywords = this.course.keywords.split(",");
          this.isCourseLoading = false;
          this.courseId = this.course.id;
          this.isPurchased = result.data.isPurchased;
          this.totalPurchases = result.data.totalPurchases;
          this.getModules();
          this.getReviews();
          this.getDiscussions();
          this.getProjects();
          this.getResourceCount();
          this.getResources();
        })
        .catch(error => {
          this.error = error;
          //this.$toastr.e("Something went wrong");
        });
    },
    async getModules() {
      await ModuleService.getActiveModules({ course_id: this.courseId })
        .then(result => {
          this.modules = result.data;
          this.isModuleLoader = false;
          if (this.isPurchased == true) {
            this.getModuleContent(this.modules[0].id, 0);
          } else {
            this.isContentLoader = false;
          }
        })
        .catch(error => {
          this.error = error;
          this.$toastr.e(error);
        });
    },
    changeModuleContent: function(event) {
      let moduleId = event.target.getAttribute("data-id");
      let index = event.target.getAttribute("data-index");
      this.isContentLoader = true;
      this.isQuestionLoader = true;
      this.showFinalPage = false;
      this.subtitles = "";
      this.getModuleContent(moduleId, index);
    },
    getModuleContent: function(moduleId, index) {
      this.currentIndex = index;
      const module = this.modules
        .filter(object => {
          return object.id === parseInt(moduleId);
        })
        .pop();

      this.moduleId = module.id;
      this.moduleType = module.section_type_id;
      this.isPreviousModuleWatched = true;
      if (this.isPurchased == false && this.course.price > 0) {
        this.isContentLoader = false;
        this.$swal(
          "Error!",
          "Please purchase course to unlock modules",
          "Error"
        );
      }
      if (this.isPurchased == false && this.course.price == 0) {
        this.isContentLoader = false;
        this.$swal(
          "Error!",
          "Please click view now button to watch course",
          "Error"
        );
      } else if (index > 0) {
        ModuleService.checkPreviousModulesWatched({
          section_id: this.moduleId,
          course_id: this.courseId
        })
          .then(result => {
            if (result.data.status == true) {
              this.displayModuleContent(module);
              this.isPreviousModuleWatched = true;
              if (index == this.modules.length - 1) {
                this.isLastModule = true;
              }
            } else {
              this.isPreviousModuleWatched = false;
            }
          })
          .catch(error => {
            this.error = error;
            this.$toastr.e(error);
          });
      } else {
        this.displayModuleContent(module);
        if (index == this.modules.length - 1) {
          this.isLastModule = true;
        }
      }
    },
    displayModuleContent: function(module) {
      if (this.moduleType == 1) {
        if (module.videos.length > 0) {
          this.videoId = module.videos[0].video;
          this.getOtherLanguagesVideos(module.videos[0].id);
          this.getVideoSubtitles(module.videos[0].id);
          this.isContentLoader = false;
        } else {
          this.$toastr.e("No videos found");
          this.isContentLoader = false;
        }
      } else if (this.moduleType == 2) {
        this.examId = module.exams[0].id;
        this.selectedAnswerArray = [];
        this.getQuestions();
        this.getOtherLanguagesExams();
        this.isContentLoader = false;
      } else if (this.moduleType == 3) {
        this.articleId = module.articles[0].id;
        this.isContentLoader = false;
        this.articleContent = module.articles[0].article;
        this.getOtherLanguagesArticles();
        this.getArticleFiles();
        this.completeArticle();
      }
    },
    async getQuestions() {
      this.isAlreadySubmitted = false;
      this.isQuestionLoader = true;
      await ModuleService.getQuestions({ exam_id: this.examId })
        .then(result => {
          if (result.data.status == "success") {
            this.questionIndex = 0;
            this.questions = result.data.data;
            this.isQuestionLoader = false;
            this.getQuestion();
          } else {
            this.isAlreadySubmitted = true;
            this.$toastr.s(result.data.message);
            this.securedScore = result.data.message;
            if (this.isLastModule == true) {
              this.showCertificateButton = true;
            }
          }
        })
        .catch(error => {
          this.error = error;
          this.$toastr.e(error);
        });
    },
    getQuestion() {
      if (this.questions.length > 0) {
        this.question = this.questions[this.questionIndex];
        let questionId = this.question.id;
        let selectedAnswer = this.selectedAnswerArray.find(
          selectedAnswer => selectedAnswer.question_id === questionId
        );
        if (selectedAnswer !== undefined) {
          this.selectedAnswer = selectedAnswer.answer_id;
        } else {
          this.selectedAnswer = "";
        }
      } else {
        this.question = "";
      }
    },
    onReady() {
      this.playerReady = true;
      let watchedTime = localStorage.getItem(this.videoId);
      if (typeof watchedTime === undefined) {
        watchedTime = 0;
      }
      this.$refs.player.player.setCurrentTime(watchedTime);
    },
    timeUpdate(event) {
      if (this.isPurchased == false) {
        if (event.seconds > 10) {
          this.$refs.player.player.setCurrentTime(10);
          this.$refs.player.player.pause();
          this.$swal(
            "Error!",
            "Please purchase course to unlock all modules, change this to a pop up modal later",
            "Error"
          );
        }
      }
      localStorage.setItem(this.videoId, event.seconds);
    },
    async getOtherLanguagesVideos(videoId) {
      await ModuleService.getOtherLanguagesVideos({ video_id: videoId })
        .then(result => {
          this.otherLanguageVideos = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getOtherLanguagesExams() {
      await ModuleService.getOtherLanguagesExams({ exam_id: this.examId })
        .then(result => {
          this.otherLanguageExams = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getOtherLanguagesArticles() {
      await ModuleService.getOtherLanguagesArticles({
        article_id: this.articleId
      })
        .then(result => {
          this.otherLanguageArticles = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    changeVideoLanguage: function(video, id) {
      this.videoId = video;
      this.getOtherLanguagesVideos(id);
      this.getVideoSubtitles(id);
    },
    changeExamLanguage: function(id) {
      this.examId = id;
      this.questionIndex = 0;
      this.selectedAnswerArray = [];
      this.getQuestions();
      this.getOtherLanguagesExams();
    },
    changeArticleLanguage: function(article) {
      this.articleId = article.id;
      this.articleContent = article.article;
      this.getOtherLanguagesArticles();
      this.getArticleFiles();
    },
    nextQuestion(questionId) {
      if (this.selectedAnswer != "") {
        this.storeAnswer(questionId);
        this.questionIndex += 1;
        this.getQuestion();
        this.answerError = "";
      } else {
        this.answerError = "Please select one answer";
      }
    },
    previousQuestion(questionId) {
      if (this.selectedAnswer != "") {
        this.storeAnswer(questionId);
        this.questionIndex -= 1;
        this.getQuestion();
        this.answerError = "";
      } else {
        this.answerError = "Please select one answer";
      }
    },
    updateInputRating(event) {
      this.reviewRating = event;
    },
    submitReview: function() {
      this.$validate().then(success => {
        if (success) {
          const data = {
            rating: this.reviewRating,
            review: this.reviewDescription,
            course_id: this.courseId
          };
          CourseService.saveReview(data)
            .then(result => {
              $("#add-reviewModal").modal("hide");
              this.reviewDescription = "";
              this.reviewRating = 5;
              if (result.data.status == "success") {
                this.$toastr.s(result.data.message);
                this.getReviews();
              }
              if (result.data.status == "error") {
                this.$toastr.e(result.data.message);
              }
            })
            .catch(error => {
              this.error = error;
              this.$toastr.e(error);
            });
        }
      });
    },
    async getReviews() {
      await CourseService.getReviews({ course_id: this.courseId })
        .then(result => {
          this.reviews = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getDiscussions() {
      await CourseService.getDiscussions({ course_id: this.courseId })
        .then(result => {
          this.discussions = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getProjects() {
      await CourseService.getProjects({ course_id: this.courseId })
        .then(result => {
          this.projects = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    storeAnswer: function(questionId) {
      const answer = {
        exam_id: this.examId,
        question_id: questionId,
        answer_id: this.selectedAnswer
      };
      for (var i = 0; i < this.selectedAnswerArray.length; i++) {
        var item = this.selectedAnswerArray[i];
        if (item.question_id == questionId) {
          this.selectedAnswerArray.splice(i, 1);
        }
      }
      this.selectedAnswerArray.push(answer);
      this.selectedAnswer = "";
      console.log(this.selectedAnswerArray);
    },
    submitExam: function(questionId) {
      if (this.selectedAnswer != "") {
        this.isSubmittingExam = true;
        this.storeAnswer(questionId);
        this.answerError = "";
        ModuleService.submitExam({
          exam_id: this.examId,
          course_id: this.courseId,
          result: this.selectedAnswerArray
        })
          .then(result => {
            this.isSubmittingExam = false;
            if (result.data.status == "success") {
              this.isAlreadySubmitted = true;
              this.$toastr.s(result.data.message);
              this.securedScore = result.data.message;
            } else {
              this.$toastr.e(result.data.message);
            }
            if (this.isLastModule == true) {
              this.showCertificateButton = true;
            }
          })
          .catch(error => {
            this.isSubmittingExam = false;
            this.error = error;
            // this.$toastr.e("Something went wrong");
          });
      } else {
        this.answerError = "Please select one answer";
      }
    },
    freeCoursePurchase: function() {
      if (this.$store.getters["auth/isLoggedIn"] != true) {
        this.$store.dispatch(
          "auth/updateNextUrl",
          this.$router.history.current.path
        );
        this.$router.push({ name: "Login" });
      } else {
        this.disabledButton = true;
        CourseService.saveFreeOrder({
          course_id: this.course.id
        })
          .then(result => {
            this.disabledButton = false;
            if (result.data.status == "success") {
              this.$toastr.s(result.data.message);
              this.watchCourse();
            } else {
              this.$toastr.e("Failed to purchase please try again");
            }
          })
          .catch(error => {
            console.log(error);
            this.$toastr.e("Failed to purchase please try again");
          });
      }
    },
    async getResourceCount() {
      await CourseService.getResourceCount({ course_id: this.courseId })
        .then(result => {
          this.resourceCount = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getArticleFiles() {
      await ModuleService.getArticleFiles({ article_id: this.articleId })
        .then(result => {
          this.articleFiles = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    downloadFile(file, name, folder) {
      ModuleService.downloadFile(file, folder)
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = name;
          link.click();
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    completeVideo: function() {
      ModuleService.completeSection({ section_id: this.moduleId });
      if (this.isLastModule == true) {
        this.showCertificateButton = true;
      } else {
        let nextIndex = parseInt(this.currentIndex) + 1;
        this.getModuleContent(this.modules[nextIndex].id, nextIndex);
      }
    },
    completeArticle: function() {
      ModuleService.completeSection({ section_id: this.moduleId });
      if (this.currentIndex == this.modules.length - 1) {
        this.isLastModule = true;
      }
      if (this.isLastModule == true) {
        this.showCertificateButton = true;
      }
    },
    getCertificate: function() {
      this.certificateLoader = true;
      CourseService.getCertificate({ course_id: this.courseId })
        .then(response => {
          this.certificateLoader = false;
          if (response.data.status == true) {
            this.showFinalPage = true;
            this.$toastr.s("Certificate generated successfully");
            this.downloadFile(
              response.data.fileName,
              response.data.fileName,
              "certificates"
            );
          } else {
            this.$swal("Error!", response.data.message, "Error");
          }
        })
        .catch(error => {
          this.certificateLoader = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getVideoSubtitles(videoId) {
      await ModuleService.getVideoSubtitles({ video_id: videoId })
        .then(result => {
          let languageArray = [];
          result.data.forEach(function(entry) {
            languageArray.push(entry.language);
          });
          this.subtitles = languageArray.toString();
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    selectFile: function() {
      this.projectFile = this.$refs.projectFile.files[0];
    },
    createProject: function() {
      const formData = new FormData();
      formData.append("title", this.projectTitle);
      formData.append("description", this.projectDescription);
      formData.append("file", this.projectFile);
      formData.append("course_id", this.course.id);
      CourseService.saveProject(formData)
        .then(result => {
          if (result.data.status == "validation_error") {
            this.errors = result.data.message || {};
          } else if (result.data.status == "success") {
            this.errors = {};
            $("#projectModal").modal("hide");
            this.$toastr.s(result.data.message);
            this.projectTitle = "";
            this.projectDescription = "";
            this.projectFile = "";
            this.$refs.projectFile.value = null;
            this.projectCourseId = "";
          }
        })
        .catch(function() {
          this.disabledButton = false;
          // this.$toastr.e("Something went wrong");
        });
    },
    async getResources() {
      await CourseService.getResources({ course_id: this.courseId })
        .then(result => {
          this.resources = result.data;
        })
        .catch(error => {
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    }
  }
};
</script>
